import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Select, Stack, Typography } from '@mui/material'
import { camelCase } from 'lodash'
import React, { memo, useCallback } from 'react'

interface PadSettingsSelectOption {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: number | string
}

export interface PadSettingsSelectProps {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange: (changedValue: any) => void
  options: PadSettingsSelectOption[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: number | string
}

export const PadSettingsSelect = memo<PadSettingsSelectProps>((props) => {
  const { label, onValueChange, options, value } = props

  const handleChange = useCallback(
    (e: any) => {
      const newValue = e.target.value

      if (newValue === value) return

      onValueChange(newValue)
    },
    [onValueChange, value]
  )

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
      <Typography>{label}</Typography>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        inputProps={{
          'data-testid': `PadSettingsSelect-${camelCase(label)}`,
        }}
        native
        onChange={handleChange}
        size="small"
        sx={{
          '& .MuiSelect-select.MuiSelect-outlined': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.background[400]
                : theme.palette.background[400],
            paddingRight: 5,
          },
        }}
        value={value}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Stack>
  )
})
