import _ from 'lodash'
import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { Language } from 'utils'

import { SandboxShowcaseTypes } from './Sandbox/constants'

export function selectExecEnabled(state: DefaultRootState): boolean {
  return state.padSettings.execEnabled
}

export function selectTestCasesEnabled(state: DefaultRootState) {
  return state.question.testCasesEnabled
}

export function selectIsOnline(state: DefaultRootState) {
  return state.userState.isOnline
}

export function selectLocalUsername(state: DefaultRootState) {
  return state.userState.uncommittedUsername
}

export function selectLocalEmail(state: DefaultRootState) {
  return state.userState.uncommittedEmail
}

export function selectUserInfo(state: DefaultRootState) {
  return state.userState.userInfo
}

// WARNING: May return undefined
export function selectMyUserInfo(state: DefaultRootState) {
  return selectUserInfo(state)[selectMyUserId(state)]
}

export function selectMyUserId(state: DefaultRootState) {
  return state.userState.userId
}

export function selectOnlineUsers(state: DefaultRootState) {
  return _.filter(state.userState.userInfo, (u) => u.isOnline && u.name !== 'Unknown')
}

export function selectCallStatus(state: DefaultRootState) {
  return state.call.status
}

export function selectHasRightPane(state: DefaultRootState) {
  return _.some(state.tabs.visibleTabs)
}

export function selectActiveTab(state: DefaultRootState) {
  return state.tabs.activeTab
}

export function selectPadSettings(state: DefaultRootState) {
  return state.padSettings
}

export function selectIsSandboxShowcaseInterviewer(state: DefaultRootState) {
  return selectPadSettings(state).sandboxView === SandboxShowcaseTypes.Interviewer
}

export function selectIsSandboxShowcase(state: DefaultRootState) {
  return !!selectPadSettings(state).sandboxView
}

export function selectEditorSettings(state: DefaultRootState) {
  return state.editorSettings
}

export function selectIsDarkTheme(state: DefaultRootState) {
  return state.editorSettings.darkColorScheme
}

export function selectLastCopy(state: DefaultRootState) {
  return state.lastCopy
}

export function selectTwilioUsers(state: DefaultRootState) {
  return state.call.twilioUsers
}

export function selectZoomUsers(state: DefaultRootState) {
  return state.call.zoomUsers
}

export function selectVideoMuted(state: DefaultRootState) {
  return state.call.videoMuted
}

export function selectAudioMuted(state: DefaultRootState) {
  return state.call.audioMuted
}

export function selectCallMaximized(state: DefaultRootState) {
  return state.call.maximized
}

export function selectCallQuality(state: DefaultRootState) {
  return state.call.networkQualityLevel
}

export function selectQuestionId(state: DefaultRootState) {
  return state.question?.questionId
}

export function selectDrawingModeOpen(state: DefaultRootState) {
  const myUserId = selectMyUserId(state)
  return !!state.userState?.userInfo[myUserId]?.drawingModeOpen
}

export interface ExecEnv {
  customDatabaseId?: string | null
  customDatabaseLanguage?: string | null
  customFiles?: unknown[]
  language?: Language
  projectTemplateSlug?: string
  projectTemplateVersion?: string
  environmentId?: string
  environmentSlug: string
  spreadsheet?: string
}

export function selectExecEnv(state: DefaultRootState): ExecEnv {
  return {
    customDatabaseId: state.padSettings.customDatabaseId,
    customDatabaseLanguage: state.padSettings.customDatabaseLanguage,
    customFiles: state.question.customFiles ? _.map(state.question.customFiles, 'id') : [],
    language: state.padSettings.language,
    projectTemplateSlug: state.padSettings.projectTemplateSlug,
    projectTemplateVersion: state.padSettings.projectTemplateVersion,
    environmentId: state.padSettings.environmentId,
    environmentSlug: state.padSettings.environmentSlug,
    spreadsheet: state.padSettings.spreadsheet,
  }
}

export const selectLanguagesAvailable = createSelector(
  (state: DefaultRootState) => state.question,
  (question) => {
    if (question && question.testCasesEnabled) {
      return _.pickBy(window.CoderPad.LANGUAGES, (lang) => lang.test_case_grading)
    } else {
      return window.CoderPad.LANGUAGES
    }
  }
)

export function selectExampleDatabasePresent(state: DefaultRootState) {
  return !state.padSettings.customDatabaseId && state.padSettings.customDatabaseLanguage
}

export function selectDatabaseEnabled(state: DefaultRootState) {
  const { customDatabaseLanguage, language, projectTemplateSlug } = state.padSettings
  if (customDatabaseLanguage == null) {
    return language === 'mysql' || language === 'postgresql'
  }
  return !!(
    !projectTemplateSlug &&
    window.CoderPad.LANGUAGES[language].database_allowed &&
    !(customDatabaseLanguage === 'mysql' && language === 'postgresql') &&
    !(customDatabaseLanguage === 'postgresql' && language === 'mysql')
  )
}

export function selectMultifileEnabled(state: DefaultRootState) {
  // Reserved keys: _language, 0_global
  return !!(state.files && Object.keys(state.files).length > 2)
}

export function selectPadVisibleTestCasesPassed(state: DefaultRootState) {
  let testsPassed = 0

  if (state.question !== undefined && state.question.visibleTestCaseResults !== undefined) {
    testsPassed = state.question.visibleTestCaseResults.filter((t: any) => t.passed).length
  }

  return testsPassed
}

export function selectIsCodeTested(state: DefaultRootState) {
  return state.question.codeModifiedAt < state.question.codeTestedAt
}

export function selectHasConsentedToTranscription(state: DefaultRootState) {
  return state.call.hasConsentedToTranscription
}
