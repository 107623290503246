import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import React, { useCallback, useRef } from 'react'

import PlaybackSingleExecution from '../single_execution'
import { Execution } from '../types'

export interface IAutoscrollingExecutionList {
  executions: Execution[]
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 53,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 10px 15px',
  },
  header: {
    fontWeight: 'bolder',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    ...theme.palette.programOutput.console,
    ...theme.palette.playback.execution.header,
  },
  scrollPoint: {
    width: 0,
    height: 0,
    display: 'inline-block',
  },
  logPrevious: {
    fontWeight: 'lighter',
    ...theme.palette.playback.execution.log.previous,
  },
  logCurrent: {
    fontWeight: 'bolder',
    ...theme.palette.playback.execution.log.current,
  },
}))

export const AutoscrollingExecutionList: React.FC<
  React.PropsWithChildren<IAutoscrollingExecutionList>
> = ({ executions }) => {
  const scrollRef = useRef<HTMLPreElement>(null)
  const styles = useStyles()

  const scrollToElement = useCallback((element: HTMLElement) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = element.offsetTop
    }
  }, [])

  return (
    <pre ref={scrollRef} id="execution-log" className={styles.container}>
      {executions.map((execution, index) =>
        execution.type === 'execution' ? (
          <React.Fragment key={index}>
            <div className={styles.header}>
              <span>
                [{format(execution.timestamp, "h:mmaaaaa'm'")}] {execution.text}
              </span>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            <div
              className={styles.scrollPoint}
              ref={(element) => {
                if (element && index === executions.length - 1) {
                  scrollToElement(element)
                }
              }}
            />
            <div
              className={index === executions.length - 1 ? styles.logCurrent : styles.logPrevious}
            >
              <PlaybackSingleExecution
                execution={{ ...execution, index }}
                key={`${index}-${execution.index}-${execution.timestamp}-${execution.type}`}
              />
            </div>
          </React.Fragment>
        )
      )}
    </pre>
  )
}
