import { usePadSummary } from 'graphql/hooks/usePadSummary/usePadSummary'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC } from 'react'

import { isErrorState, isInitialState, isLoadingState } from '../../../graphql/queryStates'
import { ScrollView } from '../ScrollView/ScrollView'
import { FailedToGenerateSummary } from './components/FailedToGenerateSummary'
import { SummaryLoading } from './components/SummaryLoading'
import { LivePadSummary } from './LivePadSummary'
import { TakeHomePadSummary } from './TakeHomePadSummary'

interface PadSummaryProps {
  hidden: boolean
}

export const PadSummary: FC<React.PropsWithChildren<PadSummaryProps>> = ({ hidden }) => {
  const { slug, takeHome } = usePadConfigValues('slug', 'takeHome')
  const { padSummary, status } = usePadSummary(slug)

  if (hidden) {
    return null
  }

  return (
    <div style={{ height: '100%', boxSizing: 'border-box' }}>
      <ScrollView>
        {isLoadingState(status) || isInitialState(status) ? (
          <SummaryLoading />
        ) : isErrorState(status) ? (
          <FailedToGenerateSummary />
        ) : takeHome ? (
          <TakeHomePadSummary padSummary={padSummary!} />
        ) : (
          <LivePadSummary padSummary={padSummary!} />
        )}
      </ScrollView>
    </div>
  )
}
