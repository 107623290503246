import { ApolloClient, getApolloContext, InMemoryCache, useQuery } from '@apollo/client'
import { useContext, useMemo } from 'react'

import * as queryStates from '../../../queryStates'
import { User } from '../../../types'
import CURRENT_USER_QUERY from './CurrentUserQuery.gql'

export interface ICurrentUserData {
  user: User
}

export function useCurrentUser() {
  const { client } = useContext(getApolloContext())

  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<ICurrentUserData>(
    CURRENT_USER_QUERY,
    {
      context: {
        source: 'useCurrentUser.ts',
      },

      // allow the hook to be called outside of /dashboard without crashing
      skip: !client,
      client: client || new ApolloClient({ cache: new InMemoryCache() }),
    }
  )

  const currentUser = data?.user

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error != null) {
    status = queryStates.error(
      `An error occurred while fetching your user information${
        error.message ? ': ' + error.message : ''
      }`
    )
  } else {
    status = queryStates.success()
  }

  return useMemo(
    () => ({
      currentUser,
      status,
      refetch,
      startPolling,
      stopPolling,
    }),
    [currentUser, refetch, startPolling, status, stopPolling]
  )
}
