import { Decode } from 'console-feed-optimized'
import { Message } from 'console-feed-optimized/lib/definitions/Component'
import React, { Ref, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useActiveEnvironment } from '../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'

const ProjectBrowserContext = React.createContext<{
  currentUrl: string | null
  hasOriginAccess: boolean
  iframeSrc: string
  iframeRef: Ref<HTMLIFrameElement>
  logs: Message[]
  clearLogs: () => void
  reload: () => void
  navigateBack: () => void
  navigateForward: () => void
  setLocation: (url: string) => void
  resetLocation: () => void
} | null>(null)

export enum ApiIframeMessageType {
  ConsoleMessage = 'consoleMessage',
  LocationChanged = 'locationChanged',
  OriginAccessChanged = 'originAccessChanged',
  IFrameFocused = 'iframeFocused',
  IFrameBlurred = 'iframeBlurred',
}

export type ApiIframeMessage =
  | {
      type: ApiIframeMessageType.ConsoleMessage
      data: Message
    }
  | {
      type: ApiIframeMessageType.LocationChanged
      url: string
      reload: boolean
    }
  | {
      type: ApiIframeMessageType.OriginAccessChanged
      access: boolean
    }
  | {
      type: ApiIframeMessageType.IFrameFocused
    }
  | {
      type: ApiIframeMessageType.IFrameBlurred
    }

enum ApiParentMessageType {
  Reload = 'reload',
  SetLocation = 'setLocation',
  NavigateBack = 'navigateBack',
  NavigateForward = 'navigateForward',
}

type ApiParentMessage =
  | {
      type: ApiParentMessageType.Reload
    }
  | {
      type: ApiParentMessageType.SetLocation
      url: string
    }
  | {
      type: ApiParentMessageType.NavigateBack
    }
  | {
      type: ApiParentMessageType.NavigateForward
    }

interface ProjectIframeAPIProviderProps {
  padSlug: string
}

export const ProjectIframeAPIProvider: React.FC<
  React.PropsWithChildren<ProjectIframeAPIProviderProps>
> = ({ children, padSlug }) => {
  const origin = `https://${padSlug}.${window.CoderPad.PROJECT_IFRAME_URL_SUFFIX}`.toLowerCase()
  const iframeSrc = `${origin}/wrapper/index.html`
  const [currentUrl, setCurrentUrl] = useState<string | null>(origin)
  const [hasOriginAccess, setHasOriginAccess] = useState(true)
  const [logs, setLogs] = useState<Message[]>([])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { environment } = useActiveEnvironment()

  // Listen for iframe messages
  useEffect(() => {
    function onMessage(e: MessageEvent) {
      if (e.origin.toLowerCase() === origin && typeof e.data === 'object') {
        const message: ApiIframeMessage = e.data

        switch (message.type) {
          case ApiIframeMessageType.ConsoleMessage: {
            try {
              const decodedLog = Decode(message.data) as Message
              setLogs((logs) => [...logs, decodedLog])
            } catch (e) {
              // Sometimes the message does not get decoded properly and throws an exception.
              // Ignore those and leave the existing logs as-is.
            }
            break
          }
          case ApiIframeMessageType.LocationChanged:
            setCurrentUrl(message.url)
            if (message.reload) {
              setLogs([])
            }
            break
          case ApiIframeMessageType.OriginAccessChanged:
            setHasOriginAccess(message.access)
            setCurrentUrl(null)
            break
        }
      }
    }
    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [origin])

  const sendMessage = useCallback(
    (message: ApiParentMessage) => {
      iframeRef.current?.contentWindow?.postMessage(message, origin)
    },
    [origin]
  )

  const clearLogs = useCallback(() => {
    setLogs([])
  }, [])

  const reload = useCallback(() => {
    sendMessage({ type: ApiParentMessageType.Reload })
  }, [sendMessage])

  const navigateBack = useCallback(() => {
    sendMessage({ type: ApiParentMessageType.NavigateBack })
  }, [sendMessage])

  const navigateForward = useCallback(() => {
    sendMessage({ type: ApiParentMessageType.NavigateForward })
  }, [sendMessage])

  const setLocation = useCallback(
    (url: string) => {
      sendMessage({ type: ApiParentMessageType.SetLocation, url: new URL(url, origin).toString() })
    },
    [sendMessage, origin]
  )

  const resetLocation = useCallback(() => {
    setLocation(origin)
  }, [setLocation, origin])

  useEffect(() => {
    setCurrentUrl(origin)
    clearLogs()
    setHasOriginAccess(true)
  }, [environment?.id, clearLogs, origin])

  return (
    <ProjectBrowserContext.Provider
      value={{
        currentUrl,
        hasOriginAccess,
        iframeSrc,
        iframeRef,
        logs,
        clearLogs,
        reload,
        navigateBack,
        navigateForward,
        setLocation,
        resetLocation,
      }}
    >
      {children}
    </ProjectBrowserContext.Provider>
  )
}

export function useProjectBrowser() {
  const contextVal = useContext(ProjectBrowserContext)

  if (contextVal == null) {
    throw new Error('`useProjectBrowser` hook must be a descendant of a `ProjectIframeAPIProvider`')
  }

  return contextVal
}
