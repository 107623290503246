import { Avatar, Box, styled, SvgIcon, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import { usePlaybackParticipants } from 'packs/main/playback/hooks/usePlaybackParticipants'
import React, { FC, memo, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { useSelector } from 'react-redux'

import { ReactComponent as GptLogo } from '../../../../../../assets/images/gpt.svg'
import MarkdownOutput from '../../../markdown_output'
import { selectUserInfo } from '../../../selectors'
import CopyCodeButton from './CopyCodeButton'
import { FinishReasonCode, Role } from './Types'

const MarkdownWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),

  // position 'pre' elements as relative so copy code
  // buttons can be positioned absolutely within them
  '& pre': {
    position: 'relative',

    '& > span[data-id="copy"] > button': {
      opacity: 0,
    },

    '&:hover': {
      '& > span[data-id="copy"] > button': {
        opacity: 1,
      },
    },
  },
}))

interface ConversationMessageProps {
  role: string
  content: string
  authorId: string
  messageId: string
  finishReason?: FinishReasonCode
  incomplete?: boolean
}

const getFinishReasonDisplay = (finishReason: FinishReasonCode): string | null => {
  switch (finishReason) {
    case FinishReasonCode.CLIENT_ABORTED:
      return 'Response stopped'
    case FinishReasonCode.LENGTH:
      return 'Message length limit reached'
    case FinishReasonCode.CONTENT_FILTER:
      return 'Message content filtered'
    case FinishReasonCode.UNKNOWN:
      return 'An error occurred'
    case FinishReasonCode.PAD_TOKEN_LIMIT_LIMIT_REACHED:
      return 'AI Chat usage limit reached for the Pad'
    default:
      return null
  }
}

export const ConversationMessageRow: FC<React.PropsWithChildren<ConversationMessageProps>> = memo(
  ({ role, content, authorId, messageId, finishReason, incomplete = false }) => {
    const theme = useTheme()
    const padUsers = useSelector(selectUserInfo)
    const playbackParticipants = usePlaybackParticipants()
    const { isPlayback } = usePadConfigValues('isPlayback')
    let initials = ''
    let tooltip = ''
    let avatarColor = '#10a37e'

    const author = isPlayback ? playbackParticipants[authorId] : padUsers[authorId]
    if (role === Role.user && author) {
      initials = author.name?.[0]?.toUpperCase() ?? ''
      avatarColor = author.color
      tooltip = author.name ?? ''
    } else {
      tooltip = authorId // GPT messages have the AI model as their authorId
    }

    // The streamed response may have an opening code block, but no closing code block yet,
    // so we temporarily add the closing block to avoid weird code block formatting flickering
    const codeBlockMarkerCount = (content.match(/```/g) || []).length
    if (codeBlockMarkerCount % 2 === 1) {
      content += '\n```'
    }

    useEffect(() => {
      if (incomplete) return
      const codeBlocks = document.querySelectorAll(`div[data-message-id="${messageId}"] pre > code`)
      codeBlocks.forEach((block) => {
        const rootEl = document.createElement('span')
        rootEl.dataset.id = 'copy'
        block.parentElement?.appendChild(rootEl)
        const copyButton = React.createElement(CopyCodeButton, {
          isDarkMode: theme.palette.mode === 'dark',
        })
        const root = ReactDOM.createRoot(rootEl)
        root.render(copyButton)
      })
    }, [messageId, incomplete, theme.palette.mode])

    // Strip trailing dashes or equal signs so they don't incorrectly
    // cause the previous line to render as a header e.g. when
    // streaming a bulleted list in a response
    const trailingDashOrEqualRegEx = /\n[=-]+\s*$/
    content = content.replace(trailingDashOrEqualRegEx, '')

    const finishReasonDisplay = finishReason && getFinishReasonDisplay(finishReason)
    return (
      <Box
        data-message-id={messageId}
        sx={{ display: 'flex', flexDirection: 'row', overflowAnchor: 'none' }}
      >
        <Box sx={{ padding: 1 }}>
          <Tooltip title={tooltip} placement="top" arrow>
            <Avatar sx={{ backgroundColor: avatarColor, color: '#000', fontSize: '1.125rem' }}>
              {role === Role.assistant ? (
                <SvgIcon
                  sx={{ width: '32px', height: '32px' }}
                  inheritViewBox
                  component={GptLogo}
                />
              ) : (
                initials
              )}
            </Avatar>
          </Tooltip>
        </Box>
        <MarkdownWrapper>
          <MarkdownOutput value={content} hidden={false} />
          {finishReasonDisplay && (
            <Box textAlign="right">
              <Typography variant="overline" color="text.secondary">
                {finishReasonDisplay}
              </Typography>
            </Box>
          )}
        </MarkdownWrapper>
      </Box>
    )
  }
)

export default ConversationMessageRow
