// POLYFILLS
// The babel-preset-env setting {useBuiltIns: 'entry'} makes this
// import all the polyfills needed for an environment, and only those.
// https://babeljs.io/docs/en/babel-preset-env
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#usebuiltins-entry-with-corejs-3
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// CORE
import 'bootstrap'
import './vendor/request_animation_frame'
import './main/codemirror_extras'
import 'input-autogrow/src/input-autogrow'
import './main/initialize'
import './main/cc_form'
import './main/form_canary'
import './main/RailsMount/RailsMount'

import $ from 'jquery'
import Cookies from 'js-cookie'

import setupPad from './main/pads'
global.$ = $

if (window.padConfig) {
  setupPad()
} else {
  Cookies.set('ever_logged_in', true)
}
