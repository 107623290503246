import { Interview as CodeAndQuestionsIcon } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Stack } from '@mui/material'
import { PadSummary } from 'graphql/types'
import React, { FC } from 'react'

import { displayDuration } from '../utils'
import {
  SectionTitle,
  SummaryTableCell,
  SummaryTableCellEmphasized,
  SummaryTableRow,
} from './StyledComponents'

export const CodeAndQuestions: FC<React.PropsWithChildren<{ padSummary: PadSummary }>> = ({
  padSummary,
}) => {
  if (padSummary.drawingOnly) {
    return null
  }

  return (
    <>
      <SummaryTableRow>
        <SectionTitle>
          <Stack direction="row" spacing={1}>
            <Box pt={0.3}>
              <CodeAndQuestionsIcon sx={{ width: 14, height: 14 }} />
            </Box>
            <Box>Code and Questions</Box>
          </Stack>
        </SectionTitle>
      </SummaryTableRow>
      {padSummary.environments.map((env, i) => (
        <SummaryTableRow key={i}>
          <SummaryTableCellEmphasized>
            Tab {i + 1} - {env.name}:
          </SummaryTableCellEmphasized>
          <SummaryTableCell>
            Question used:{' '}
            {env.questionId ? (
              <a href={`/dashboard/questions/${env.questionId}`} target="_blank" rel="noreferrer">
                {env.questionTitle}
              </a>
            ) : (
              'None'
            )}
            {env.runCount != null && <Box>Ran code {env.runCount.toLocaleString()} times</Box>}
            {env.filesEditedCount != null && (
              <Box>Total files edited: {env.filesEditedCount.toLocaleString()}</Box>
            )}
            <Box>Time spent in the tab: {displayDuration(env.activeTime)}</Box>
          </SummaryTableCell>
        </SummaryTableRow>
      ))}
      {padSummary.drawingUsed && (
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Drawing:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {padSummary.participants.map((p, i) => (
              <Box key={i}>
                <b>{p.name}</b> time spent drawing: {displayDuration(p.drawingTime)}
              </Box>
            ))}
          </SummaryTableCell>
        </SummaryTableRow>
      )}
    </>
  )
}
