import { Box, styled } from '@mui/material'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useRef } from 'react'
import { connect, DefaultRootState, useSelector } from 'react-redux'

import { ErrorBoundary } from '../../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../../dashboard/components/GenericErrorView/GenericErrorView'
import { TabPanel as _TabPanel } from '../../dashboard/components/Tabs'
import { TabsContextProvider } from '../../dashboard/components/Tabs/TabsContext'
import DrawingTab from '../drawing_tab'
import { InterviewNotesTabBody } from '../InterviewNotes/InterviewNotesTabBody/InterviewNotesTabBody'
import PadReport from '../pad_report'
import { PadSummary } from '../PadSummary/PadSummary'
import ProgramOutput from '../ProgramOutput'
import TestsTab from '../tests_tab'
import { Transcript } from '../Transcriber/Transcript'
import { Header } from './Header/Header'
import { AiTab } from './Tabs/AiTab/AiTab'
import { useAiChatHistory } from './Tabs/AiTab/useAiChatHistory'
import { CandidateInstructionsCandidate } from './Tabs/CandidateInstructions/CandidateInstructionsCandidate'
import { CandidateInstructionsInterviewer } from './Tabs/CandidateInstructions/CandidateInstructionsInterviewer'
import { DatabaseTab } from './Tabs/DatabaseTab/DatabaseTab'
import { FilesTab } from './Tabs/FilesTab/FilesTab'
import { SolutionsTab } from './Tabs/SolutionsTab/SolutionsTab'

const TabPanel = styled(_TabPanel)({
  flex: 1,
  padding: 0,
  height: '100%',
  overflow: 'hidden',
})

type RightPaneProps = Record<string, never>
export const RightPane: React.FC<React.PropsWithChildren<RightPaneProps>> = () => {
  const { activeTab, visibleTabs } = useSelector((state) => state.tabs)
  const { isOwner, isPlayback } = usePadConfigValues('isOwner', 'isPlayback')
  // Needed to show chat history for pads that had chat even if the feature flag has since been turned off.
  const chatHistory = useAiChatHistory()
  const showAiTab = visibleTabs.ai || (isPlayback && chatHistory.length > 0)
  const headerButtonRef = useRef<Element>(null)

  // Because there are stateful, 3rd-party UI components inside both program output (xterm)
  // and interviewer notes (Firepad), we have to render them both regardless of which tab
  // is active, but with a "hidden" prop so they can hide themselves visually via CSS.
  return (
    <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="hidden"
        boxSizing="border-box"
        color="inherit"
      >
        {/*
        Note the `Tabs` component is not used. It's specialized internal state management and prop reading doesn't
        jive with having the tabs nested inside another component. We will still be using the other components and
        context from the `Tabs` component.
      */}
        <TabsContextProvider value={{ size: 'small', value: activeTab }}>
          <Header ref={headerButtonRef} />
          {/*
          Kind of a hack here to wrap the tab panels with another context, but it's necessary since both the request
          client and output views use the same tab panel. We need to trick the tabs into showing the "output" tab panel
          when the active tab value is actually "requestClient".
        */}
          <TabsContextProvider
            value={{ size: 'small', value: activeTab === 'requestClient' ? 'output' : activeTab }}
          >
            {visibleTabs.drawing && (
              /*
              Setting 'unmountOnHidden={true} assures DrawingTab renders the WhiteboardPreviewMode and by extension a canvas
              with dimensions. A canvas is necessary to render shapes plus fit them into view using zoomToFit()
            */
              <TabPanel value="drawing" unmountOnHidden={true}>
                <DrawingTab hidden={activeTab !== 'drawing'} width={'100%'} />
              </TabPanel>
            )}
            {visibleTabs.tests && (
              <TabPanel value="tests" unmountOnHidden={false}>
                <TestsTab hidden={activeTab !== 'tests'} />
              </TabPanel>
            )}
            {visibleTabs.output && (
              <TabPanel value="output" unmountOnHidden={false}>
                <ProgramOutput
                  hidden={activeTab !== 'output' && activeTab !== 'requestClient'}
                  buttonRef={headerButtonRef}
                />
              </TabPanel>
            )}
            {visibleTabs.database && (
              <TabPanel value="database" unmountOnHidden={false}>
                <DatabaseTab hidden={activeTab !== 'database'} />
              </TabPanel>
            )}
            {visibleTabs.files && (
              <TabPanel value="files" unmountOnHidden={false}>
                <FilesTab hidden={activeTab !== 'files'} />
              </TabPanel>
            )}
            {visibleTabs.candidateInfo &&
              (isOwner ? (
                <TabPanel value="candidateInfo" unmountOnHidden={false}>
                  <CandidateInstructionsInterviewerTab hidden={activeTab !== 'candidateInfo'} />
                </TabPanel>
              ) : (
                <TabPanel value="candidateInfo" unmountOnHidden={false}>
                  <CandidateInstructionsCandidateTab hidden={activeTab !== 'candidateInfo'} />
                </TabPanel>
              ))}
            {visibleTabs.report && (
              <TabPanel value="report" unmountOnHidden={false}>
                <PadReport hidden={activeTab !== 'report'} />
              </TabPanel>
            )}
            {visibleTabs.notes && (
              <TabPanel value="notes" unmountOnHidden={false} sx={{ padding: '0 16px' }}>
                <InterviewNotesTabBody hidden={activeTab !== 'notes'} />
              </TabPanel>
            )}
            {visibleTabs.solution && (
              <TabPanel value="solution" unmountOnHidden={false}>
                <SolutionTab hidden={activeTab !== 'solution'} />
              </TabPanel>
            )}
            {showAiTab && (
              <TabPanel value="ai" unmountOnHidden={false}>
                <AiTab hidden={activeTab !== 'ai'} buttonRef={headerButtonRef} />
              </TabPanel>
            )}
            {visibleTabs.transcript && (
              <TabPanel value="transcript" unmountOnHidden={false}>
                <Transcript hidden={activeTab !== 'transcript'} />
              </TabPanel>
            )}
            {visibleTabs.summary && (
              <TabPanel value="summary" unmountOnHidden={false}>
                <PadSummary hidden={activeTab !== 'summary'} />
              </TabPanel>
            )}
          </TabsContextProvider>
        </TabsContextProvider>
      </Box>
    </ErrorBoundary>
  )
}

function mapStateToPropsCandidateInfo(state: DefaultRootState) {
  return {
    candidateInstructions: state.question.candidateInstructions,
  }
}

const CandidateInstructionsCandidateTab = connect(mapStateToPropsCandidateInfo)(
  CandidateInstructionsCandidate
)
const CandidateInstructionsInterviewerTab = connect(mapStateToPropsCandidateInfo)(
  CandidateInstructionsInterviewer
)

function mapStateToPropsSolution(state: DefaultRootState) {
  return {
    solution: state.question.solution,
  }
}

const SolutionTab = connect(mapStateToPropsSolution)(SolutionsTab)
