import { eventChannel } from 'redux-saga'
import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { selectCallStatus } from '../selectors'
import SyncHandle from '../sync_handle'

export default function* zoomSaga() {
  yield fork(watchForZoomRoomIdAndDeviceChanges)

  yield takeEvery(
    ['intent_to_start_call_expressed', 'invite_accepted', 'reconnect_to_call_clicked'],
    enumerateDevices
  )
  yield takeEvery('device_switched', switchDevice)
  yield takeEvery('call_join_requested', joinOrCreateCall)
}

function* handleZoomRoomIdChange(id) {
  // Only show the invite dialog if the user is not in the call, and the web
  // server indicates there is an ongoing call.
  if (id == null) return
  const callStatus = yield select(selectCallStatus)
  if (callStatus !== 'no_call') return

  yield put({
    type: 'invited_to_call',
    twilioRoomId: id,
    _analytics: {
      name: 'Call Invite Received',
    },
  })
}

function* watchForZoomRoomIdAndDeviceChanges() {
  const chan = eventChannel((emit) => {
    SyncHandle().watch('zoomRoomId', (id) => emit(['room_id', id]))

    if (navigator.mediaDevices && typeof navigator.mediaDevices.addEventListener == 'function')
      navigator.mediaDevices.addEventListener('devicechange', (evt) => emit(['device_change', evt]))
    return () => null // This channel remains open for entire session, don't bother to cleanup
  })

  yield takeEvery(chan, function* ([eventType, param]) {
    if (eventType === 'room_id') yield call(handleZoomRoomIdChange, param)
    else if (eventType === 'device_change') yield call(handleDeviceChange, param)
  })
}

function* handleDeviceChange() {
  // A media device was plugged in or unplugged.
  // Ignore these unless we're in pending state...we use them to update the dropdown list.
  // The event object contains no information so we must call enumerateDevices() again.
  const callStatus = yield select(selectCallStatus)
  if (callStatus === 'pending') yield call(enumerateDevices)
}

function* enumerateDevices() {
  try {
    // Trigger browser device permissions prompt before enumerating devices.
    yield call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], {
      audio: true,
      video: true,
    })
    // Request audio and video permissions
    const stream = yield call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], {
      audio: true,
      video: true,
    })

    const devices = yield call([navigator.mediaDevices, navigator.mediaDevices.enumerateDevices])

    yield put({
      type: 'devices_enumerated',
      devices,
    })

    // Stop tracks after getting permissions
    stream.getTracks().forEach((track) => track.stop())
  } catch (err) {
    yield put({ type: 'device_enumerate_failed' })
  }
}

function* switchDevice(action) {
  // Switching to a specific device ID.
  const { kind, deviceId } = action

  yield put({
    type: 'device_switch_succeeded',
    kind,
    deviceId,
  })
}

function* joinOrCreateCall() {
  const [fromInvite, videoState] = yield select((state) => [
    state.call.fromInvite,
    state.call.videoDeviceState,
  ])
  yield put({
    type: 'call_joined',
    _analytics: {
      name: 'Call Joined',
      params: {
        provider: 'zoom',
        from_invite: fromInvite,
        // other_callers: room.participants.size,
        with_video: videoState === 'open',
      },
    },
  })
}
