import { Theme, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { _Pane, IPaneProps } from './Pane/Pane'

const useStyles = makeStyles<Theme, { orientation: PaneOrientation }>({
  paneWrapper: {
    display: 'flex',
    flexDirection: (props) => (props.orientation === 'x' ? 'row' : 'column'),
    height: '100%',
    width: '100%',
    position: 'relative',
  },
})

/**
 * This is the component that consumers of this component should be using for a Pane. Its main purpose is to collect
 * props that are used in the rendering of the actual Pane component in the SplitPane component.
 */
export const Pane: React.FC<
  React.PropsWithChildren<
    Pick<IPaneProps, 'id' | 'minSize' | 'initialSizePct' | 'hidden' | 'hideOverflow' | 'component'>
  >
> = () => null

export const DEFAULT_MIN_DIM_PX = 10

type SplitPaneChildPane = React.ReactElement<
  Pick<IPaneProps, 'id' | 'minSize' | 'initialSizePct' | 'hidden'>
>
export type SplitPaneChild = SplitPaneChildPane | null
export type SplitPaneChildren =
  | SplitPaneChild
  | SplitPaneChild[]
  | (SplitPaneChild | SplitPaneChild[])[]

export type PaneOrientation = 'x' | 'y'

export const splitPaneContext = React.createContext({
  orientation: 'x' as PaneOrientation,
  dimPcts: [100],
  numPanes: 1,
  numVisiblePanes: 1,
  onResize: (paneIdx: number, dimChangePx: number) => {},
  onResizeStop: () => {},
  shouldTransitionDim: false,
  resizingIndex: -1,
})

export function useSplitPaneContext() {
  const ctx = useContext(splitPaneContext)

  return ctx
}

interface SplitPaneProps {
  /** Control whether the panes are split vertically or horizontally. */
  orientation: PaneOrientation
  children: SplitPaneChildren
  /**
   * Can be used to disable pane size CSS transitions. This is useful in cases where during the initial load where the
   * number of panes will change quickly and you wish to omit size transitions to prevent a jarring experience. Note
   * that this prop is not strictly required, and more often than not is not necessary.
   */
  enablePaneSizeTransitions?: boolean
}
export function SplitPane({ children, orientation, enablePaneSizeTransitions }: SplitPaneProps) {
  const styles = useStyles({ orientation })
  const paneWrapperRef = React.useRef<HTMLDivElement | null>(null)

  // Filter out any null children so we are only dealing with `Pane`s.
  const childPanes = useMemo(
    () => React.Children.toArray(children).filter((c) => !!c) as SplitPaneChildPane[],
    [children]
  )

  const numPanes = childPanes.length
  const [dimPcts, setDimPcts] = useState<number[]>(new Array(numPanes).fill(0))

  // Boolean telling the child Pane components whether they should apply a CSS transition to their dimension update.
  const [shouldTransitionDim, setShouldTransitionDim] = useState(false)
  const [resizingIndex, setResizingIndex] = useState(-1)
  const isFirstSizing = useRef(true)

  const visiblePanes = childPanes.filter((cp) => !cp.props.hidden)
  const visiblePaneIds = useMemo(() => visiblePanes.map((vp) => vp.props.id).join('|'), [
    visiblePanes,
  ])

  // Effect to reset widths if the split orientation or IDs of the panes changes.
  useEffect(() => {
    // On the first rendering of a SplitPane, we should not transition Pane sizes.
    if (!isFirstSizing.current) {
      setShouldTransitionDim(true)
    }
    isFirstSizing.current = false
    // Will need to do some calculations to make sure that the sum of initial sizes is valid.
    const panesWithInitialSizePct = visiblePanes.filter(
      (cp) => !cp.props.hidden && cp.props.initialSizePct
    )
    const initialSizePctsSum = panesWithInitialSizePct.reduce(
      (acc, cp) => acc + (cp.props.initialSizePct || 0),
      0
    )

    // Make sure the initial sizes don't exceed 100%. If that's the case, use equal sized panes.
    if (initialSizePctsSum <= 100) {
      const nonInitialSizePct =
        (100 - initialSizePctsSum) / (visiblePanes.length - panesWithInitialSizePct.length)

      let usedPct = 0
      const lastVisiblePaneIdx = childPanes.reduce((acc, cp, idx) => {
        return cp.props.hidden ? acc : idx
      }, -1)
      const initialSizePcts = childPanes.map((cp, idx) => {
        // Default to the sizing for non-intial-sized Panes.
        let sizePct = nonInitialSizePct
        if (cp.props.hidden) {
          sizePct = 0
        } else if (idx === lastVisiblePaneIdx) {
          // Last pane always gets the remainder of size pct.
          sizePct = 100 - usedPct
        } else if (cp.props.initialSizePct) {
          // Respect the initial size pct of the Pane.
          sizePct = cp.props.initialSizePct
        }
        usedPct += sizePct
        return sizePct / 100
      })
      setDimPcts(initialSizePcts)
      lastResizeValueRef.current = 0
      return
    }

    // Invalid set of initial sizes, use equal sized panes.
    setDimPcts(new Array(childPanes.length).fill(1 / childPanes.length))
    lastResizeValueRef.current = 0

    // Intentionally only watching the visible pane IDs in the dependencies. This is so that changes to the
    // children of a `Pane` don't trigger this effect.
  }, [orientation, visiblePaneIds])

  const lastResizeValueRef = useRef(0)
  const onResize = useCallback(
    (paneIdx: any, dimChangePx: any) => {
      if (!paneWrapperRef.current) {
        return
      }
      setShouldTransitionDim(false)
      setResizingIndex(paneIdx)
      const wrapperDim =
        orientation === 'x'
          ? paneWrapperRef.current.offsetWidth
          : paneWrapperRef.current.offsetHeight

      const _editorDims = _.clone(dimPcts)
      const minPaneDims = childPanes.map(
        (c) => (c.props.minSize || DEFAULT_MIN_DIM_PX) / wrapperDim
      )

      const dimChange = (dimChangePx - lastResizeValueRef.current) / wrapperDim
      const dimChangeAbs = Math.abs(dimChange)
      const lastResizeValueWas = lastResizeValueRef.current
      lastResizeValueRef.current = dimChangePx
      let cursor = dimChangeAbs

      if (dimChange > 0) {
        // Shrink the pane(s) after the resized pane.
        for (let i = paneIdx + 1; i < _editorDims.length; i++) {
          const widthWas = _editorDims[i]
          const minPaneDim = minPaneDims[i]
          _editorDims[i] = Math.max(minPaneDim, widthWas - cursor)
          cursor -= widthWas - _editorDims[i]
          if (cursor <= 0) {
            break
          }
        }

        // If this was a no-op due to max/min constraints, then revert the last value
        if (cursor === dimChangeAbs) {
          lastResizeValueRef.current = lastResizeValueWas
          return
        }

        // Grow the target pane.
        for (let i = paneIdx; i >= 0; i--) {
          const widthWas = _editorDims[i]
          _editorDims[i] = Math.min(100.0, _editorDims[i] + (dimChangeAbs - cursor))
          cursor += _editorDims[i] - widthWas
          if (cursor >= dimChangeAbs) {
            break
          }
        }
      } else if (dimChange < 0) {
        // Shrink the target pane and panes(s) preceeding the target pane.
        for (let i = paneIdx; i >= 0; i--) {
          const widthWas = _editorDims[i]
          const minPaneDim = minPaneDims[i]
          _editorDims[i] = Math.max(minPaneDim, widthWas - cursor)
          cursor -= widthWas - _editorDims[i]
          if (cursor <= 0) {
            break
          }
        }

        // If this was a no-op due to max/min constraints, then revert the last value
        if (cursor === dimChangeAbs) {
          lastResizeValueRef.current = lastResizeValueWas
          return
        }

        // Grow the pane following the target pane.
        for (let i = paneIdx + 1; i < _editorDims.length; i++) {
          const heightWas = _editorDims[i]
          _editorDims[i] = Math.min(100.0, _editorDims[i] + (dimChangeAbs - cursor))
          cursor += _editorDims[i] - heightWas
          if (cursor >= dimChangeAbs) {
            break
          }
        }
      }

      setDimPcts(_editorDims)
    },
    [dimPcts, childPanes]
  )

  const onResizeStop = useCallback(() => {
    lastResizeValueRef.current = 0
    setResizingIndex(-1)
  }, [])

  // Effect to dispatch `resize` events on the window for descendants that might listen to that event to resize
  // themselves to fit their parent.
  const theme = useTheme()
  useEffect(() => {
    // Trigger `resize` events as the panes are resized so that descendants can also resize with the panes.
    window.dispatchEvent(new Event('resize'))

    // Also trigger a `resize` after the Pane dimension transition is finished. In the case where Pane dimensions
    // were mid-transition when a descendant resized itself to the size of the Pane, this will notify that descendant
    // so that it can resize to the true size of the Pane.
    const transitionTimeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, theme.transitions.duration.short) // This value should match the Pane dimension transition duration.

    return () => {
      clearTimeout(transitionTimeout)
    }
  }, [dimPcts])

  return (
    <splitPaneContext.Provider
      value={{
        orientation,
        dimPcts,
        numPanes,
        numVisiblePanes: visiblePanes.length,
        onResize,
        onResizeStop,
        shouldTransitionDim: enablePaneSizeTransitions ?? shouldTransitionDim,
        resizingIndex,
      }}
    >
      <div ref={paneWrapperRef} className={styles.paneWrapper} data-testid="splitpane">
        {childPanes.map((c, idx) => {
          return <_Pane {...c.props} paneIdx={idx} key={c.props.id} />
        })}
      </div>
    </splitPaneContext.Provider>
  )
}
