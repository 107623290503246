import { Box, Tooltip, TooltipProps } from '@mui/material'
import React from 'react'

import { ReactComponent as LockIcon } from '../../../../assets/images/Locked.svg'

export interface ShowcaseTooltipProps extends Omit<TooltipProps, 'title'> {
  sectionTitle: string
  contents: string
  titleOverride?: string
}

export const ShowcaseTooltip: React.FC<React.PropsWithChildren<ShowcaseTooltipProps>> = ({
  sectionTitle,
  contents,
  titleOverride,
  ...rest
}) => {
  return (
    <Tooltip
      {...rest}
      arrow
      title={
        titleOverride !== undefined ? (
          titleOverride
        ) : (
          <Box sx={{ textAlign: 'center', margin: '-12px', overflow: 'hidden' }}>
            <Box p="6px 12px 0 12px">
              <Box component="p" lineHeight="0.6">
                <b>{sectionTitle}</b>
              </Box>
              {contents}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '6px 4px',
                marginTop: '12px',
                backgroundColor: '#34495E',
                fontWeight: 600,
              }}
            >
              <LockIcon />
              <Box component="i" ml={1}>
                Available with free account
              </Box>
            </Box>
          </Box>
        )
      }
    />
  )
}
