import { eventChannel } from 'redux-saga'
import { debounce, put, select, takeEvery } from 'redux-saga/effects'

import logEvent from '../log_pad_event'
import padConfig from '../pad_config'
import { selectLocalEmail, selectLocalUsername, selectMyUserInfo } from '../selectors'
import SyncHandle from '../sync_handle'

export default function* usersSaga() {
  const syncHandle = SyncHandle()

  const userEventsChan = eventChannel((emit) => {
    const EVENTS_TO_WATCH = ['user_added', 'user_changed', 'user_removed', ':connected']
    for (const eventName of EVENTS_TO_WATCH)
      syncHandle.on(eventName, (arg) => emit([eventName, arg]))

    return () => ({}) // This channel remains open for entire session, don't bother to cleanup
  })

  yield takeEvery(userEventsChan, function* ([type, data]) {
    const myUserInfo = yield select(selectMyUserInfo)
    switch (type) {
      case 'user_added':
        yield put({ type: 'user_added', userObj: data })
        postMessageToFigmaWidget('user_added', {
          ...data,
          local: !myUserInfo.id || myUserInfo.id === data.userId,
        })
        break
      case 'user_changed':
        yield put({ type: 'user_changed', userObj: data })
        postMessageToFigmaWidget('user_changed', {
          ...data,
          local: !myUserInfo.id || myUserInfo.id === data.userId,
        })
        break
      case 'user_removed':
        yield put({ type: 'user_removed', userId: data.userId, userObj: data })
        postMessageToFigmaWidget('user_removed', {
          userId: data.userId,
          local: !myUserInfo.id || myUserInfo.id === data.userId,
        })
        break
      case ':connected':
        yield put({ type: 'connection_status_changed', isOnline: data })
        break
    }
  })

  // We need to inform the server in the case where the user is already signed in
  if (padConfig.isLoggedIn) logEvent('joined', { username: padConfig.loginUsername })

  yield takeEvery('logged_in', function* ({ username, email }) {
    logEvent('joined', { username, email })
    createFirebaseUser(username, email)
  })

  yield takeEvery('connection_status_changed', function* ({ isOnline }) {
    const username = yield select(selectLocalUsername)
    const email = yield select(selectLocalEmail)
    if (username && isOnline) createFirebaseUser(username, email)
  })

  yield debounce(500, 'username_edited', function* ({ newName }) {
    const myUserInfo = yield select(selectMyUserInfo)
    logEvent('renamed', {
      metadata: newName,
      username: myUserInfo && myUserInfo.name,
    })
    syncHandle.update_user({ name: newName })
  })

  yield takeEvery('drawing_mode_toggled', function* ({ isOpen }) {
    const myUserInfo = yield select(selectMyUserInfo)

    syncHandle.update_user({ drawingModeOpen: isOpen })
    if (myUserInfo.id != null) {
      syncHandle.push(`drawingEvents/${myUserInfo.id}`, {
        type: isOpen ? 'opened' : 'closed',
        timestamp: syncHandle.TIMESTAMP_SENTINEL,
      })
    }
  })
}

// idempotent, OK to call multiple times
function createFirebaseUser(username, email) {
  const syncHandle = SyncHandle()
  syncHandle.update_user({
    name: username,
    email,
    isOwner: padConfig.isOwner,
    invisible: padConfig.invisible,
  })
}

function postMessageToFigmaWidget(type, payload) {
  if (typeof parent !== 'undefined') {
    parent.postMessage(
      { pluginMessage: { type: type, data: payload }, pluginId: '1030203787137883430' },
      '*'
    )
  }
}
