import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, styled, Typography } from '@mui/material'
import React from 'react'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function AloneUserAutoStop({
  isOpen,
  requestClose,
}: {
  isOpen: boolean
  requestClose: () => void
}) {
  return (
    <Dialog
      open={isOpen}
      dialogTitle="Transcription Stopped"
      content={
        <div>
          <ContentBlock variant="body1">
            You have been the only person in this pad for 5 minutes. The transcription was
            automatically stopped.
          </ContentBlock>
        </div>
      }
      actions={[
        <Button key="alone-stopped" onClick={requestClose} variant="contained" color="primary">
          OK
        </Button>,
      ]}
    />
  )
}
