import React from 'react'

import { ReactComponent as EmptyFolderIcon } from '../../../../../../assets/images/projects/emptyFolder.svg'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { DirectoryItemInput } from '../DirectoryItemInput'
import { WrappedToDepth } from '../utils/WrappedToDepth'

interface IAddFolderProps {
  depth: number
  folderPath: string | null
  onExit: () => void
  showHighlight: boolean
  showSubHighlights: boolean
}
export const AddFolder: React.FC<React.PropsWithChildren<IAddFolderProps>> = ({
  depth,
  folderPath,
  onExit,
  showHighlight,
  showSubHighlights,
}) => {
  const { activeFile, addFolder } = useActiveEnvironment()

  if (activeFile == null) {
    return null
  }

  const handleSubmit = (folderName: string) => {
    addFolder(folderPath + folderName)
    onExit()
  }

  const activeFilePath = activeFile.path.replace(activeFile.name, '')
  const highlightDepth = activeFilePath.split('/').length - 1

  return (
    <div className="directory-item-input">
      <WrappedToDepth
        depth={depth}
        highlightDepth={highlightDepth}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
        opaque={true}
      >
        <DirectoryItemInput
          onSubmit={handleSubmit}
          onExit={onExit}
          icon={<EmptyFolderIcon className="folder-icon" />}
        />
      </WrappedToDepth>
    </div>
  )
}
