import { Box } from '@mui/material'
import React from 'react'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import MonacoEditor from '../../Monaco/MonacoEditor'
import { EditorHeader } from './EditorHeader/EditorHeader'

export const EnvironmentEditor: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isPlayback = usePadConfigValue('isPlayback')

  return (
    <Box height="100%" display="flex" className="EnvironmentEditor">
      <Box height="100%" width="100%" display="flex" flexDirection="column" flexGrow={1}>
        {!isPlayback && <EditorHeader />}
        {/* Still borrowing the `MonacoEditor` component from classic pad Monaco implementation. */}
        <Box sx={{ paddingTop: (theme) => theme.spacing(1), flexGrow: 1 }}>
          <MonacoEditor />
        </Box>
      </Box>
    </Box>
  )
}
