import { FC, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { useLocalStorage } from '../../../utils'
import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { addGlobalEvent } from '../playback/GlobalEvents/addGlobalEvent'
import { LostFocusGlobalEvent } from '../playback/GlobalEvents/GlobalEventTypes'
import { ApiIframeMessage, ApiIframeMessageType } from '../ProjectOutput/ProjectBrowserContext'
import { selectUserInfo } from '../selectors'
import SyncHandle from '../sync_handle'

export const LOST_FOCUS_MIN_DURATION = 250

export const WindowFocusDetect: FC<React.PropsWithChildren<unknown>> = () => {
  const users = useSelector(selectUserInfo)
  const hasMounted = useRef(false)
  const { firebaseAuthorId, slug, isOwner } = usePadConfigValues(
    'firebaseAuthorId',
    'slug',
    'isOwner'
  )
  const [lastBlurTime, setLastBlurTime] = useLocalStorage<number>(`lastBlurTime_${slug}`, 0)
  const cachedLastBlurTime = useRef(-1)
  const isIframeFocused = useRef(false)

  const changeUserFocusState = useCallback(
    (isOutsidePad: boolean) => {
      const syncHandle = SyncHandle.findOrInit(firebaseAuthorId)
      syncHandle.update_user({ isOutsidePad }, () => {})
    },
    [firebaseAuthorId]
  )

  const onFocus = useCallback(() => {
    if (isOwner) return
    // prevent duplicate events when there's overlap in the event listeners
    if (cachedLastBlurTime.current === lastBlurTime) return
    if (lastBlurTime === 0) return
    if (users[firebaseAuthorId].name == null) return

    changeUserFocusState(false)

    const duration = Date.now() - lastBlurTime

    if (duration > LOST_FOCUS_MIN_DURATION) {
      cachedLastBlurTime.current = lastBlurTime
      addGlobalEvent<LostFocusGlobalEvent>({
        type: 'lost-focus',
        user: firebaseAuthorId,
        data: {
          duration,
        },
      })
    }
  }, [changeUserFocusState, firebaseAuthorId, isOwner, lastBlurTime, users])

  const onBlur = useCallback(() => {
    if (isOwner) return
    setTimeout(() => {
      if (isIframeFocused.current) return
      setLastBlurTime(Date.now())
      changeUserFocusState(true)
    }, 200)
  }, [changeUserFocusState, isOwner, setLastBlurTime])

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      onFocus()
    } else {
      onBlur()
    }
  }, [onBlur, onFocus])

  const handlePostMessage = useCallback((event: MessageEvent) => {
    const message = event.data as ApiIframeMessage
    if (message.type === ApiIframeMessageType.IFrameFocused) {
      isIframeFocused.current = true
    } else if (message.type === ApiIframeMessageType.IFrameBlurred) {
      isIframeFocused.current = false
    }
  }, [])

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    document.addEventListener('visibilitychange', onVisibilityChange)
    window.addEventListener('beforeunload', onBlur)
    window.addEventListener('unload', onBlur)
    window.addEventListener('pagehide', onBlur)
    window.addEventListener('message', handlePostMessage)
    window.onbeforeunload = onBlur

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
      document.removeEventListener('visibilitychange', onVisibilityChange)
      window.removeEventListener('beforeunload', onBlur)
      window.removeEventListener('unload', onBlur)
      window.removeEventListener('pagehide', onBlur)
      window.removeEventListener('message', handlePostMessage)
      window.onbeforeunload = null
    }
  }, [
    firebaseAuthorId,
    handlePostMessage,
    lastBlurTime,
    onBlur,
    onFocus,
    onVisibilityChange,
    setLastBlurTime,
  ])

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true
      onFocus()
    }
  }, [onFocus])

  return null
}
