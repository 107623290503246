import classNames from 'classnames'
import _ from 'lodash'
import React, { FC } from 'react'

interface CallQualityProps {
  size: number
  quality: 1 | 2 | 3 | 4 | 5
}

const CallQuality: FC<React.PropsWithChildren<CallQualityProps>> = ({ size, quality }) => {
  const barWidth = Math.floor(size / 5)
  const barSpacing = Math.floor(barWidth * 0.7)
  return (
    <div className="CallQuality" style={{ height: size, width: 5 * barWidth + 4 * barSpacing }}>
      {_.times(5, (i) => (
        <div
          className={classNames('CallQuality-bar', { 'CallQuality-barActive': i < quality })}
          key={i}
          style={{ height: `${(i + 1) * 20}%`, width: barWidth }}
        />
      ))}
    </div>
  )
}

export default CallQuality
