import React from 'react'

interface MiniBrowserError {
  name: string
  message: string
  parsedStack: {
    functionName: string
    args: string[]
    fileName: string
    lineNumber: number
    columnNumber: number
    isEval: boolean
    isNative: boolean
  }[]
}

interface IMiniBrowserContext {
  errors: MiniBrowserError[]
  processedHtml: string | null
  timestamp: number
}

const miniBrowserContext = React.createContext<IMiniBrowserContext>({
  errors: [],
  processedHtml: '',
  timestamp: 0,
})

export const MiniBrowserProvider: React.FC<React.PropsWithChildren<IMiniBrowserContext>> = ({
  children,
  ...values
}) => {
  return <miniBrowserContext.Provider value={values}>{children}</miniBrowserContext.Provider>
}

export const useMiniBrowser = () => {
  return React.useContext(miniBrowserContext)
}
