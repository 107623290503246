import { NotificationOutlined } from '@codinpad/shared-components/components/icons/index.js'
import { Badge, Box, Tooltip } from '@mui/material'
import { logger } from '@sentry/utils'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback, useEffect, useState } from 'react'

import { BeamerCredentialless } from './BeamerCredentialless'
import { FooterButton } from './FooterButton'

export function BeamerButton() {
  const [beamer, setBeamer] = useState<any>(null)
  const { isPlayback } = usePadConfigValues('isPlayback')
  const [hasBeamerBeenShown, setHasBeamerBeenShown] = useState(false)

  const handleClick = useCallback(() => {
    beamer?.show()
    if (!hasBeamerBeenShown) {
      setHasBeamerBeenShown(true)
    }
  }, [beamer, hasBeamerBeenShown])

  useEffect(() => {
    const config = window['beamer_config']
    if (config) {
      config.pad_view = true
      config.force_filter = 's_dLvnvdJJ21912'
      config.bounce = false
      if (window['beamerPromise']) {
        window['beamerPromise']
          .then(() => {
            window['Beamer'].init()
            setBeamer(window['Beamer'])
          })
          .catch((error: any) => logger.error(error))
      }
    }
  }, [])

  return (
    <Tooltip
      placement="top"
      arrow
      title={<Box textAlign="center">Learn about new dev-focused CoderPad features</Box>}
    >
      <span>
        {hasBeamerBeenShown && <BeamerCredentialless />}
        <FooterButton
          onClick={handleClick}
          iconOnly={isPlayback}
          startIcon={
            <Badge
              variant="dot"
              color="success"
              overlap="circular"
              invisible={window['beamerPosts'] == null || window['beamerPosts'] == 0}
            >
              <NotificationOutlined sx={{ width: 20, height: 20 }} />
            </Badge>
          }
        >
          <Box sx={{ display: isPlayback ? 'none' : { xs: 'none', lg: 'initial' } }}>
            What's New
          </Box>
        </FooterButton>
      </span>
    </Tooltip>
  )
}
