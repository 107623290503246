import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, styled, Typography } from '@mui/material'
import React from 'react'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function AutoStop({ isOpen, requestClose }: { isOpen: boolean; requestClose: () => void }) {
  return (
    <Dialog
      open={isOpen}
      dialogTitle="Transcription Stopped"
      content={
        <div>
          <ContentBlock variant="body1">
            The time limit for transcription has been reached and transcription was automatically
            stopped.
          </ContentBlock>
        </div>
      }
      actions={[
        <Button
          key="auto-stop-acknowledged"
          onClick={requestClose}
          variant="contained"
          color="primary"
        >
          OK
        </Button>,
      ]}
    />
  )
}
