import { ProjectTemplate } from 'graphql/types'
import { FirepadHeadless } from 'packs/main/Monaco/types'
import { join } from 'path-browserify'
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { pathToFileId } from '../../../../utils/multifile'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { addGlobalEvent } from '../../playback/GlobalEvents/addGlobalEvent'
import {
  AddFileGlobalEvent,
  AddFolderGlobalEvent,
  DeleteFileGlobalEvent,
  DeleteFolderGlobalEvent,
  RenameFileGlobalEvent,
  RenameFolderGlobalEvent,
} from '../../playback/GlobalEvents/GlobalEventTypes'
import SyncHandle from '../../sync_handle'
import {
  EnvironmentSummary,
  IFile,
  useEnvironments,
} from '../EnvironmentsContext/EnvironmentsContext'
import { useEnvironmentFiles } from './useEnvironmentFiles'
import { useEnvironmentProjectTemplate } from './useEnvironmentProjectTemplate'
import { QuestionSummary, useEnvironmentQuestion } from './useEnvironmentQuestion'
import { useReduxAdapter } from './useReduxAdapter'

export interface IActiveEnvironmentContextContract {
  environment: EnvironmentSummary | null
  files: IFile[]
  emptyFolderPaths: string[]
  activeFile: IFile | null
  activateFile: (fileId: string) => void
  question: QuestionSummary | null
  projectTemplate: ProjectTemplate | null | undefined
  getFileContents: (fileId: string) => Promise<string>
  setFileContents: (fileId: string, contents: string) => void
  folderExists: (folderPath: string) => boolean
  addFile: (filePath: string, contents: string, activate: boolean) => Promise<void>
  addFiles: (files: { path: string; contents: string }[]) => Promise<void>
  addFolder: (folderPath: string) => void
  deleteFile: (fileId: string, fireEvent?: boolean) => void
  deleteFolder: (folderPath: string) => void
  renameFile: (
    oldFilePath: string,
    newFilePath: string,
    activate?: boolean,
    fireEvent?: boolean
  ) => void
  renameFolder: (oldFolderPath: string, newFolderPath: string) => void
  setLargeFile: (fileId: string, large: boolean) => void
}

export const ActiveEnvironmentContext = createContext<IActiveEnvironmentContextContract>({
  environment: null,
  files: [],
  emptyFolderPaths: [],
  activeFile: null,
  activateFile: () => null,
  question: null,
  projectTemplate: null,
  getFileContents: () => Promise.resolve(''),
  setFileContents: () => null,
  folderExists: () => false,
  addFile: () => Promise.resolve(),
  addFiles: () => Promise.resolve(),
  addFolder: () => null,
  deleteFile: () => null,
  deleteFolder: () => null,
  renameFile: () => null,
  renameFolder: () => null,
  setLargeFile: () => null,
})

export const ActiveEnvironmentProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { activeEnvironment } = useEnvironments()
  const [activeFileId, setActiveFileId] = useState('')
  const [largeFiles, setLargeFiles] = useState<Record<string, boolean>>({})
  const [emptyFolderPaths, setEmptyFolderPaths] = useState<string[]>([])
  const envQuestion = useEnvironmentQuestion(activeEnvironment?.questionId)
  const { projectTemplate } = useEnvironmentProjectTemplate(activeEnvironment?.projectTemplateSlug)
  const { firebaseAuthorId: _firebaseAuthorId } = usePadConfigValues('firebaseAuthorId')
  const dispatch = useDispatch()
  // Firepad operations expect a string for the author id.
  const firebaseAuthorId = `${_firebaseAuthorId}`

  useReduxAdapter(envQuestion)

  const files = useEnvironmentFiles(activeEnvironment)

  const filesWithUpdatedLargeProperty = files.map((f) => ({
    ...f,
    isLargeFile: largeFiles[f.id] ?? f.isLargeFile ?? false,
  }))
  const activeFile = filesWithUpdatedLargeProperty.find((f) => f.id === activeFileId) ?? null

  const folderExists = useCallback(
    (folderPath: string) => {
      // to check if a folder exists, we need to check if it's included
      // in any of the file paths or if it's in the empty folder paths
      return (
        files.some((f) => f.path.startsWith(folderPath)) ||
        emptyFolderPaths.some((p) => `${p}/`.startsWith(folderPath))
      )
    },
    [files, emptyFolderPaths]
  )

  useEffect(() => {
    setLargeFiles(files.reduce((o, f) => ({ ...o, [f.id]: f.isLargeFile ?? false }), {}))
  }, [files])

  const setLargeFile = useCallback((fileId: string, large: boolean) => {
    setLargeFiles((largeFiles) => ({ ...largeFiles, [fileId]: large }))
  }, [])

  // A change in env id + presence of files, so we need to determine which file to activate if there is not already
  // an active file.
  useEffect(() => {
    if (
      activeEnvironment?.id != null &&
      activeFile == null &&
      files.length > 0 &&
      (activeEnvironment?.projectTemplateSlug == null || projectTemplate != null) &&
      // In the case of adding a file, the file might not be immediately available in the files list, so ignore this
      // condition if a file has just been added.
      lastAddedFile.current == null
    ) {
      // look up the default active file in the settings, or pick README.md
      const defaultActiveFileName = projectTemplate?.settings?.defaultActiveFile ?? 'README.md'
      const defaultActiveFile = files.find(({ name }) => name === defaultActiveFileName)

      const newFile = defaultActiveFile ?? files[0]
      if (newFile != null) {
        setActiveFileId(newFile.id)
      }
    }
    // Clear the last added file so that this effect will run on the next environment change or file deletion.
    lastAddedFile.current = null
  }, [
    activeEnvironment?.id,
    files,
    activeFile,
    projectTemplate,
    activeEnvironment?.projectTemplateSlug,
  ])

  const getFileContents = useCallback<IActiveEnvironmentContextContract['getFileContents']>(
    async (fileId) => {
      const file = files.find((f) => f.id === fileId)
      return new Promise<string>((resolve, reject) => {
        if (file != null) {
          const hlFP: FirepadHeadless = SyncHandle().firepadHeadless(
            `environmentFiles/${activeEnvironment?.slug}/${fileId}`
          )
          hlFP.on('ready', () => {
            hlFP.getText().then((text) => {
              hlFP.dispose()
              resolve(text)
            })
          })
        } else {
          reject(new Error('File not found'))
        }
      })
    },
    [files, activeEnvironment?.slug]
  )

  const setFileContents = useCallback<IActiveEnvironmentContextContract['setFileContents']>(
    (fileId, contents) => {
      const file = files.find((f) => f.id === fileId)
      if (file != null) {
        const hlFP: FirepadHeadless = SyncHandle().firepadHeadless(
          `environmentFiles/${activeEnvironment?.slug}/${fileId}`
        )
        hlFP.on('ready', () => {
          hlFP.setText(contents)
          hlFP.dispose()
        })
      }
    },
    [files, activeEnvironment?.slug]
  )
  const lastAddedFile = useRef<string | null>(null)
  const addFile = useCallback<IActiveEnvironmentContextContract['addFile']>(
    (filePath, contents = '', activate = true) => {
      // Setting this ref so that we can reference it in the effect to set the active file when the specified one
      // is missing. This is neccessary because there is a race condition between setting the active file in this
      // context and the file being available in the files list.
      lastAddedFile.current = filePath
      return new Promise((resolve, reject) => {
        if (activeEnvironment != null) {
          const fileId = pathToFileId(filePath)
          const hlFP: FirepadHeadless = SyncHandle().firepadHeadless(
            `environmentFiles/${activeEnvironment.slug}/${fileId}`
          )
          hlFP.on('ready', () => {
            hlFP.setText(contents)
            hlFP.dispose()
            SyncHandle().set(`environmentFileIds/${activeEnvironment.slug}/${fileId}`, true, () => {
              if (activate) {
                setActiveFileId(fileId)
              }
              addGlobalEvent<AddFileGlobalEvent>({
                type: 'add-file',
                user: firebaseAuthorId!,
                data: {
                  environment: activeEnvironment.id,
                  filePath,
                  fileId,
                  contents,
                },
              })
              resolve()
            })
          })
        } else {
          reject(new Error('No active environment'))
        }
      })
    },
    [activeEnvironment, firebaseAuthorId]
  )

  /**
   * A more performant way to write bulk files to firebase.
   * This works by fetching the entire files array, concatenating the
   * new files onto it, and then writing the entire array back to firebase.
   *
   * Previously, we would need to perform a request for every file, which
   * was very slow when moving/uploading large amounts of files.
   */
  const addFiles = useCallback<IActiveEnvironmentContextContract['addFiles']>(
    (files) => {
      return new Promise((resolve, reject) => {
        if (activeEnvironment != null) {
          SyncHandle().get(
            `environmentFiles/${activeEnvironment.slug}`,
            (
              data: {
                [id: string]: {
                  history: {
                    A0: {
                      a: string
                      o: string[]
                      t: number
                    }
                  }
                }
              } = {}
            ) => {
              const updates = data
              files.forEach((file) => {
                const fileId = pathToFileId(file.path)
                updates[fileId] = {
                  history: {
                    A0: {
                      a: firebaseAuthorId,
                      o: [file.contents],
                      t: Date.now(),
                    },
                  },
                }
              })

              SyncHandle().set(`environmentFiles/${activeEnvironment.slug}`, updates, () => {
                SyncHandle().get(
                  `environmentFileIds/${activeEnvironment.slug}`,
                  (data: { [id: string]: boolean }) => {
                    const updates = data
                    files.forEach((file) => {
                      const fileId = pathToFileId(file.path)
                      updates[fileId] = true
                    })
                    SyncHandle().set(
                      `environmentFileIds/${activeEnvironment.slug}`,
                      updates,
                      () => {
                        files.forEach((file) => {
                          const fileId = pathToFileId(file.path)
                          addGlobalEvent<AddFileGlobalEvent>({
                            type: 'add-file',
                            user: firebaseAuthorId!,
                            data: {
                              environment: activeEnvironment.id,
                              filePath: file.path,
                              fileId,
                              contents: file.contents,
                            },
                          })
                        })
                        resolve()
                      }
                    )
                  }
                )
              })
            }
          )
        } else {
          reject(new Error('No active environment'))
        }
      })
    },
    [activeEnvironment, firebaseAuthorId]
  )

  const deleteFile = useCallback<IActiveEnvironmentContextContract['deleteFile']>(
    (fileId, fireEvent = true) => {
      // Make sure the file exists.
      if (activeEnvironment != null && files.some((f) => f.id === fileId)) {
        SyncHandle().set(`environmentFileIds/${activeEnvironment.slug}/${fileId}`, false, () => {
          if (fireEvent) {
            addGlobalEvent<DeleteFileGlobalEvent>({
              type: 'delete-file',
              user: firebaseAuthorId!,
              data: {
                environment: activeEnvironment.id,
                fileId,
              },
            })
          }
        })
      }
    },
    [activeEnvironment, files, firebaseAuthorId]
  )

  const deleteFolder = useCallback<IActiveEnvironmentContextContract['deleteFolder']>(
    (path) => {
      if (activeEnvironment != null) {
        // ensure folderPath ends with a slash
        const folderPath = join(path, '/')
        const toDelete = {}
        files.forEach((f) => {
          toDelete[f.id] = !f.path.startsWith(folderPath)
        })
        SyncHandle().update(`environmentFileIds/${activeEnvironment.slug}`, toDelete, () => {
          setEmptyFolderPaths((emptyFolderPaths) =>
            emptyFolderPaths.filter((folder) => !join(folder, '/').startsWith(folderPath))
          )
          addGlobalEvent<DeleteFolderGlobalEvent>({
            type: 'delete-folder',
            user: firebaseAuthorId!,
            data: {
              environment: activeEnvironment.id,
              folderPath,
            },
          })
        })
      }
    },
    [activeEnvironment, files, firebaseAuthorId]
  )

  const renameFile = useCallback<IActiveEnvironmentContextContract['renameFile']>(
    (oldFilePath, newFilePath, activate = true, fireEvent = true) => {
      if (activeEnvironment != null && oldFilePath !== newFilePath) {
        const oldFileId = pathToFileId(oldFilePath)
        const newFileId = pathToFileId(newFilePath)
        SyncHandle().get(
          `environmentFiles/${activeEnvironment.slug}/${oldFileId}/history`,
          (oldFileContent: string) => {
            SyncHandle().set(
              `environmentFiles/${activeEnvironment.slug}/${newFileId}/history`,
              oldFileContent
            )
            SyncHandle().update(
              `environmentFileIds/${activeEnvironment.slug}`,
              {
                [`${newFileId}`]: true,
                [`${oldFileId}`]: false,
              },
              () => {
                if (activate) {
                  setActiveFileId(newFileId)
                }
                addGlobalEvent<DeleteFileGlobalEvent>({
                  type: 'delete-file',
                  user: firebaseAuthorId!,
                  data: {
                    environment: activeEnvironment.id,
                    fileId: oldFileId,
                  },
                })
                addGlobalEvent<AddFileGlobalEvent>({
                  type: 'add-file',
                  user: firebaseAuthorId!,
                  data: {
                    environment: activeEnvironment.id,
                    filePath: oldFilePath,
                    fileId: newFileId,
                    contents: oldFileContent,
                  },
                })
              }
            )
            if (fireEvent) {
              addGlobalEvent<RenameFileGlobalEvent>({
                type: 'rename-file',
                user: firebaseAuthorId!,
                data: {
                  environment: activeEnvironment.id,
                  oldFilePath,
                  newFilePath,
                },
              })
            }
          }
        )
      }
    },
    [activeEnvironment, firebaseAuthorId]
  )

  const addFolder = useCallback<IActiveEnvironmentContextContract['addFolder']>(
    (folderPath) => {
      if (activeEnvironment != null && folderPath != null) {
        setEmptyFolderPaths((emptyFolderPaths) => [...emptyFolderPaths, folderPath])
        addGlobalEvent<AddFolderGlobalEvent>({
          type: 'add-folder',
          user: firebaseAuthorId!,
          data: {
            environment: activeEnvironment.id,
            folderPath,
          },
        })
      }
    },
    [activeEnvironment, setEmptyFolderPaths, firebaseAuthorId]
  )

  const renameFolder = useCallback<IActiveEnvironmentContextContract['renameFolder']>(
    (oldFolderPath, newFolderPath) => {
      oldFolderPath = join(oldFolderPath, '/')
      newFolderPath = join(newFolderPath, '/')
      if (activeEnvironment != null && oldFolderPath !== newFolderPath) {
        files.forEach((f) => {
          if (f.path.startsWith(oldFolderPath)) {
            const newFilePath = f.path.replace(oldFolderPath, newFolderPath)
            renameFile(f.path, newFilePath, false, false)
          }
        })
        setEmptyFolderPaths((emptyFolderPaths) =>
          emptyFolderPaths.map((folder) => folder.replace(oldFolderPath, newFolderPath))
        )
        addGlobalEvent<RenameFolderGlobalEvent>({
          type: 'rename-folder',
          user: firebaseAuthorId!,
          data: {
            environment: activeEnvironment.id,
            oldFolderPath,
            newFolderPath,
          },
        })
      }
    },
    [activeEnvironment, files, firebaseAuthorId, renameFile]
  )

  useEffect(() => {
    if (activeEnvironment?.id != null) {
      const cpadJSONFile = files.find((f) => f.path === '.cpad')
      if (cpadJSONFile) {
        const watcher = SyncHandle().watch(cpadJSONFile.firebasePath, () => {
          getFileContents(cpadJSONFile.id).then((contents) => {
            try {
              const parsed = JSON.parse(contents)
              dispatch({
                type: 'project/updateCpadConfiguration',
                value: parsed,
              })
            } catch (e) {
              // failed to parse JSON
              // we prevent anything from happening here so that the state
              // retains the last valid value
              // For now, we just throw this away, since syntax errors are
              // usually caused from adding a target.
            }
          })
        })

        return () => {
          if (watcher) {
            SyncHandle().off(cpadJSONFile.firebasePath, watcher)
          }
        }
      } else {
        dispatch({
          type: 'project/updateRunTargets',
          value: {},
        })
      }
    }
    return () => {}
  }, [activeEnvironment?.id, dispatch, files, getFileContents])

  const ctxVal = {
    environment: activeEnvironment,
    files: filesWithUpdatedLargeProperty,
    emptyFolderPaths,
    question: envQuestion,
    projectTemplate,
    activeFile,
    activateFile: setActiveFileId,
    getFileContents,
    setFileContents,
    folderExists,
    addFile,
    addFiles,
    addFolder,
    deleteFile,
    deleteFolder,
    renameFile,
    renameFolder,
    setLargeFile,
  }

  return (
    <ActiveEnvironmentContext.Provider value={ctxVal}>{children}</ActiveEnvironmentContext.Provider>
  )
}

export function useActiveEnvironment() {
  const contextVal = useContext(ActiveEnvironmentContext)

  if (contextVal == null) {
    throw new Error(
      '`useActiveEnvironment` hook must be a descendant of a `ActiveEnvironmentProvider`'
    )
  }

  return contextVal
}
