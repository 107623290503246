import { styled, Typography } from '@mui/material'
import CustomIcon, { Icons } from 'packs/main/components/CustomIcon/CustomIcon'
import { useDebouncedEffect } from 'packs/main/playback/hooks/useDebouncedEffect'
import React, { FC, useCallback } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'

import { useDragAndDrop } from '../DragAndDrop/DragAndDropContext'
import { FileTooLargeDialog } from '../DragAndDrop/FileMessageDialog/dialogs/FileTooLargeDialog'
import { MaxFileSize, PrettyMaxFileSize } from '../FolderDropZone/config'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '1 1 100%',
  '& svg': {
    marginBottom: theme.spacing(1),
  },
  overflow: 'hidden',
}))

const Container = styled('div')(({ theme }) => ({
  pointerEvents: 'none',
  textAlign: 'center',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 150ms',
}))

const useHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.scrollHeight)
    })

    const refToObserve = ref.current
    if (refToObserve != null) {
      resizeObserver.observe(refToObserve)
    }

    return () => {
      if (refToObserve != null) {
        resizeObserver.unobserve(refToObserve)
      }
    }
  }, [ref])

  return height
}

interface DropZoneMessagingProps {
  isHovering?: boolean
  onFileUpload?: (files: FileWithPath[], destination: string) => void
}

export const DropZoneMessaging: FC<React.PropsWithChildren<DropZoneMessagingProps>> = ({
  onFileUpload,
  isHovering,
}) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { setFileMessageDialogQueue, popFileMessageQueue } = useDragAndDrop()

  const wrapperHeight = useHeight(wrapperRef)
  const containerHeight = useHeight(containerRef)

  const isOverflowing = wrapperHeight < containerHeight

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // filter out node modules directories
      // TODO: determine what other dependency directories we should filter out
      acceptedFiles = acceptedFiles.filter(
        (file: FileWithPath) => !file.path?.includes('node_modules')
      )
      onFileUpload?.(acceptedFiles, '')
    },
    [onFileUpload]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noDragEventsBubbling: true,
    validator(file) {
      // reject files over our max file size
      if (file.size > MaxFileSize) {
        setFileMessageDialogQueue((queue) => [
          ...queue,
          FileTooLargeDialog({
            file,
            onOk: popFileMessageQueue,
          }),
        ])
        return {
          code: 'file-too-large',
          message: `File is too large (max ${PrettyMaxFileSize}MB)`,
        }
      }
      return null
    },
  })

  useDebouncedEffect(
    () => {
      setIsVisible(!!isHovering)
    },
    250,
    [isHovering]
  )

  return (
    <Wrapper {...getRootProps()} ref={wrapperRef}>
      <input {...getInputProps()} webkitdirectory="" directory="" mozdirectory="" />
      <Container
        style={{ opacity: (isVisible || isDragActive) && !isOverflowing ? 1 : 0 }}
        ref={containerRef}
      >
        <CustomIcon icon={Icons.MultiSelectCursor} width={20} height={20} />
        <Typography variant="body1">Drag & drop files to upload</Typography>
      </Container>
    </Wrapper>
  )
}
