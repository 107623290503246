import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, styled } from '@mui/material'
import { track } from 'packs/main/coderpad_analytics'
import React, { FC, useEffect, useRef, useState } from 'react'

// note that this component is not wrapped in one of our ThemeProviders,
// so it only has access to the default MUI theme.  Hence the hard-coded
// color values...
const StyledButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isDarkMode' })<{
  isDarkMode?: boolean
}>(({ theme, isDarkMode }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: isDarkMode ? '#fff' : '#34495e',
  transition: 'all 0.2s ease-in-out',
  width: '24px',
  height: '24px',
}))

const CopyIcon = styled(ContentCopyIcon, {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show: boolean }>(({ show }) => ({
  opacity: show ? 1 : 0,
  transition: show ? 'all 0.3s ease-in-out' : 'all 0.1s ease-in-out',
  position: 'absolute',
}))

const CopiedIcon = styled(CheckIcon, {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show: boolean }>(({ show }) => ({
  opacity: show ? 1 : 0,
  transition: show ? 'all 0.1s ease-in-out' : 'all 0.3s ease-in-out',
  position: 'absolute',
}))

type CopyCodeButtonProps = {
  isDarkMode: boolean
}

export const CopyCodeButton: FC<React.PropsWithChildren<CopyCodeButtonProps>> = ({
  isDarkMode,
}) => {
  const [copied, setCopied] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const onClick = (e: React.MouseEvent) => {
    const codeEl = (e.target as HTMLElement).closest('pre')?.querySelector('code')
    if (navigator?.clipboard) {
      track('Copy AI Code Block')
      navigator.clipboard.writeText(codeEl?.innerText || '')
      setCopied(true)
      timerRef.current = setTimeout(() => setCopied(false), 1250)
    }
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <StyledButton onClick={onClick} size="small" isDarkMode={isDarkMode}>
      <CopyIcon fontSize="inherit" show={!copied} />
      <CopiedIcon fontSize="inherit" show={copied} />
    </StyledButton>
  )
}

export default CopyCodeButton
