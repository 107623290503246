import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { PadAnalyticsEvent } from '../../constants'
import trackEvent from '../../track_event'
import { useRequestClient } from '../RequestClientContext/RequestClientContext'
import { useRequestClientSelected } from '../RequestClientSelectedContext/RequestClientSelectedContext'

const MethodCell = styled(TableCell)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
}))
const PathCell = styled(TableCell)({
  width: '100%',
})
const HistoryRow = styled(TableRow)({
  cursor: 'pointer',
})

export const History: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    requestHistory,
    currentlyViewingRequestId,
    onSelectHistoricalRequest,
  } = useRequestClient()
  const dispatch = useDispatch()
  const { isDirty, openUnsentRequestDialog } = useRequestClientSelected()

  const selectHistoricalRequest = useCallback(
    (id: string) => {
      onSelectHistoricalRequest(id)
      dispatch({ type: 'tab_selected', tab: 'requestClient' })
      trackEvent(PadAnalyticsEvent.RequestClientHistoryItemClicked)
    },
    [onSelectHistoricalRequest, dispatch]
  )

  const onRowClick = useCallback(
    (id: string) => {
      if (isDirty) {
        openUnsentRequestDialog(() => {
          selectHistoricalRequest(id)
        })
      } else {
        selectHistoricalRequest(id)
      }
    },
    [isDirty, openUnsentRequestDialog, selectHistoricalRequest]
  )

  return (
    <ScrollView>
      <TableContainer>
        <Table size="small" aria-label="API request history">
          <TableBody>
            {requestHistory.map((summary) => (
              <HistoryRow
                key={summary.id}
                hover
                onClick={() => onRowClick(summary.id)}
                selected={currentlyViewingRequestId === summary.id}
                aria-selected={currentlyViewingRequestId === summary.id ? 'true' : 'false'}
              >
                <MethodCell align="right">{summary.method}</MethodCell>
                <PathCell align="left">{summary.path}</PathCell>
              </HistoryRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollView>
  )
}
