import { useActiveEnvironment } from 'packs/main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import SyncHandle from 'packs/main/sync_handle'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RequestClientUnsentRequestDialog } from '../RequestClientConfiguration/RequestClientUnsentRequestDialog/RequestClientUnsentRequestDialog'
import { useRequestClient } from '../RequestClientContext/RequestClientContext'
import {
  Request,
  RequestConfig,
  RequestResponse,
  RequestStatus,
  RequestTimings,
} from '../RequestClientContext/types'

interface RequestClientSelectedContract {
  currentlyViewingRequestId: string | null
  currentlyViewingRequest: RequestConfig | null
  currentlyViewingRequestTimings: RequestTimings | null
  currentlyViewingRequestResponse: RequestResponse | null
  currentlyViewingRequestStatus: RequestStatus | null
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
  openUnsentRequestDialog: (onContinue: () => void) => void
}

export const RequestClientSelected = React.createContext<RequestClientSelectedContract>({
  currentlyViewingRequestId: null,
  currentlyViewingRequest: null,
  currentlyViewingRequestTimings: null,
  currentlyViewingRequestResponse: null,
  currentlyViewingRequestStatus: null,
  isDirty: false,
  setIsDirty: () => {},
  openUnsentRequestDialog: () => {},
})

export const RequestClientSelectedProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { currentlyViewingRequestId } = useRequestClient()
  const { environment } = useActiveEnvironment()

  const [isDirty, setIsDirty] = useState(false)

  const [response, setResponse] = useState<RequestResponse | null>(null)
  const [timings, setTimings] = useState<RequestTimings | null>(null)
  const [config, setConfig] = useState<RequestConfig | null>(null)
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(null)

  const dispatch = useDispatch()
  const [unsentRequestDialogOpen, setUnsentRequestDialogOpen] = useState(false)
  const [onContinue, setOnContinue] = useState<() => void>(() => () => {})

  const clearDetail = useCallback(() => {
    setResponse(null)
    setTimings(null)
    setRequestStatus(null)
  }, [])

  useEffect(() => {
    let configWatcher: any
    let requestWatcher: any
    if (currentlyViewingRequestId && environment?.slug) {
      // The request config _shouldn't_ change, however using `watch` instead of `get` in case there is latency
      // writing the request records from execute to Firebase.
      configWatcher = SyncHandle().watch(
        `environmentsData/${environment.slug}/requests/${currentlyViewingRequestId}/config`,
        (config: RequestConfig) => {
          if (config) {
            setConfig(config)
          }
        }
      )

      // Watch the request detail in Firebase so that we can update the response and timings.
      requestWatcher = SyncHandle().watch(
        `environmentsData/${environment.slug}/requests/${currentlyViewingRequestId}`,
        (request: Request) => {
          setTimings(request?.timings ?? null)
          setResponse(request?.response ?? null)
          setRequestStatus(request?.status ?? null)
        }
      )
    }

    return () => {
      if (requestWatcher) {
        SyncHandle().off(
          `environmentsData/${environment?.slug}/requests/${currentlyViewingRequestId}`,
          requestWatcher
        )
      }
      if (configWatcher) {
        SyncHandle().off(
          `environmentsData/${environment?.slug}/requests/${currentlyViewingRequestId}/config`,
          configWatcher
        )
      }
      clearDetail()
    }
  }, [currentlyViewingRequestId, environment?.slug, clearDetail])

  const openUnsentRequestDialog = useCallback((newOnContinue: () => void) => {
    setUnsentRequestDialogOpen(true)
    setOnContinue(() => newOnContinue)
  }, [])

  const onContinueAnyway = useCallback(() => {
    setUnsentRequestDialogOpen(false)
    setIsDirty(false)
    onContinue()
  }, [onContinue])

  const onGoToRequest = useCallback(() => {
    setUnsentRequestDialogOpen(false)
    dispatch({ type: 'tab_selected', tab: 'requestClient' })
  }, [dispatch])

  const contextValue = useMemo(() => {
    return {
      currentlyViewingRequestId,
      currentlyViewingRequest: config,
      currentlyViewingRequestTimings: timings,
      currentlyViewingRequestResponse: response,
      currentlyViewingRequestStatus: requestStatus,
      isDirty,
      setIsDirty,
      openUnsentRequestDialog,
    }
  }, [
    currentlyViewingRequestId,
    config,
    response,
    timings,
    requestStatus,
    isDirty,
    openUnsentRequestDialog,
  ])

  return (
    <RequestClientSelected.Provider value={contextValue}>
      <RequestClientUnsentRequestDialog
        isOpen={unsentRequestDialogOpen}
        onContinueAnyway={onContinueAnyway}
        onGoToRequest={onGoToRequest}
      />
      {children}
    </RequestClientSelected.Provider>
  )
}

export function useRequestClientSelected() {
  const contextVal = useContext(RequestClientSelected)

  if (contextVal == null) {
    throw new Error(
      '`useRequestClient` hook must be a descendant of a `RequestClientSelectedProvider`'
    )
  }

  return contextVal
}
