import MultipleEventIcon from '@mui/icons-material/FiberSmartRecord'
import { styled, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'

import { EventFrame, EventFrameTypes } from '../../types'

const EventDot = styled('span')({
  width: '100%',
  height: 8,
  position: 'relative',
  left: -4,
  borderRadius: 5,
})

const EventTick = styled('span')({
  width: 0,
  height: '100%',
  position: 'absolute',
  zIndex: 5,
  pointerEvents: 'all',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SingleTick = styled(EventDot, {
  shouldForwardProp: (prop) => prop !== 'tickColor',
})<{ tickColor: string }>(({ tickColor }) => ({
  backgroundColor: tickColor,
}))

const MultipleTick = styled(MultipleEventIcon, {
  shouldForwardProp: (prop) => prop !== 'mainTickColor' && prop !== 'secondaryTickColor',
})<{ mainTickColor: string; secondaryTickColor: string }>(
  ({ mainTickColor, secondaryTickColor }) => ({
    width: 12,
    height: 12,
    '& > circle': {
      color: mainTickColor,
    },
    '& > path': {
      color: secondaryTickColor,
    },
  })
)

interface TimelineEventTickTooltipProps {
  events: EventFrame[]
}

const TimelineEventTickTooltip: FC<React.PropsWithChildren<TimelineEventTickTooltipProps>> = ({
  events,
}) => (
  <Box>
    {events.map((event, index) => (
      <Typography key={index} sx={{ fontSize: '0.75rem' }}>
        {event.tooltip}
      </Typography>
    ))}
  </Box>
)

interface TimelineEventTickProps {
  leftOffset: number | string
  events: EventFrame[]
  onClick: () => void
  width?: number
}

const TimelineEventTick: FC<React.PropsWithChildren<TimelineEventTickProps>> = ({
  leftOffset,
  width,
  events,
  onClick,
}) => {
  const theme = useTheme()

  const colorMap: Record<EventFrameTypes, string> = {
    execution: theme.palette.playback.timeline.ticks.run,
    externalPaste: theme.palette.playback.timeline.ticks.paste,
    lostFocus: theme.palette.playback.timeline.ticks.focus,
  }

  return (
    <Tooltip placement="top" arrow title={<TimelineEventTickTooltip events={events} />}>
      <EventTick
        sx={{
          left: leftOffset,
          width: width ? `${width}%` : 8,
        }}
        onClick={onClick}
      >
        {events.length > 1 ? (
          <MultipleTick
            mainTickColor={colorMap[events[0].type]}
            secondaryTickColor={colorMap[events[1].type]}
            data-testid="stacked-event-tick"
          />
        ) : (
          <SingleTick
            tickColor={colorMap[events[0].type]}
            data-testid="event-tick"
            data-event-type={events[0].type}
          />
        )}
      </EventTick>
    </Tooltip>
  )
}

export default TimelineEventTick
