import FastRewindIcon from '@mui/icons-material/FastRewind'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, Grid, IconButton, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { format } from 'date-fns'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { track } from 'packs/main/coderpad_analytics'
import { PadAnalyticsEvent } from 'packs/main/constants'
import React, { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCanRewind, getIsPlaybackAccelerated } from '../../selectors/playback_history'
import { REWIND_MS } from '../constants'
import { useFrames } from '../hooks/useFrames'
import { usePlaybackParticipants } from '../hooks/usePlaybackParticipants'
import { TimelineMain } from './Timeline/TimelineMain'
import { UserDot } from './UserDot'

const FooterIconButton = styled(IconButton)({
  padding: 0,
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  timelineControls: {
    marginLeft: 18,
    marginRight: 18,
    paddingBottom: 10,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timelineButtons: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
  },
  rightAlign: {
    marginLeft: 'auto',
    display: 'flex',
    marginRight: '1em',
  },
  playButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.palette.activation?.main,
  },
  rewindButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    cursor: 'not-allowed',
    color: theme.palette.grey[700],
    '&.is-enabled': {
      cursor: 'pointer',
      color: theme.palette.activation?.main,
    },
  },
  speedButton: {
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.grey[700],
    '&.is-accelerated': {
      color: theme.palette.activation?.main,
    },
  },
  collapseButton: {
    background: theme.palette.playback?.timeline.background.primary,
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    cursor: 'pointer',
    transform: 'rotate(0deg)',
    transition: 'transform 300ms ease-out',
    flex: '0 0 auto',
    '&.collapsed': {
      transform: 'rotate(180deg)',
    },
  },
  userIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    height: 30,
    width: 30,
    color: '#37C773',
  },
  timestampsRoot: {
    flex: '1 0 auto',
    fontSize: '0.875rem',
    lineHeight: '17px',
    textAlign: 'center',
    padding: '0 8px',
  },
  date: {
    fontSize: '0.6875rem',
    opacity: 0.8,
    padding: '0 4px',
  },
  time: {
    fontSize: '0.75rem',
    padding: '0 4px',
    '&:first-child': {
      borderRight: '1px solid #BDBDBD',
    },
  },
}))

export const PlaybackControls: FC<
  React.PropsWithChildren<{
    setCollapsed: (s: boolean) => void
    setShouldCollapse: (s: boolean) => void
    collapsed?: boolean
  }>
> = ({ setCollapsed, collapsed, setShouldCollapse }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const frameIndex = useSelector((state) => state.playbackHistory.frameIndex)
  const playing = useSelector((state) => state.playbackHistory.playing)
  const playbackSpeed = useSelector((state) => state.playbackHistory.playbackSpeed)
  const canRewind = useSelector((state) => getCanRewind(state))
  const isPlaybackAccelerated = useSelector((state) => getIsPlaybackAccelerated(state))
  const participants = usePlaybackParticipants()
  const trackedUserId = useSelector((state) => state.playbackHistory.trackedUserId)
  const activeUser = useMemo(() => (trackedUserId != null ? participants[trackedUserId] : null), [
    trackedUserId,
    participants,
  ])
  const { getTimelineForUser } = useFrames()

  const userId = useSelector((state) => state.userState.userId)
  const padId = usePadConfigValue('slug')

  const analyticsPayload = useMemo(
    () => ({
      user_id: userId,
      pad_id: padId,
      timestamp: Math.floor(Date.now() / 1000),
    }),
    [userId, padId]
  )

  const timeline = useMemo(() => {
    if (trackedUserId) {
      return getTimelineForUser(trackedUserId)
    }
    return null
  }, [trackedUserId, getTimelineForUser])

  const onPlay = useCallback(() => {
    dispatch({ type: 'playback_play' })
    if (!collapsed) {
      setShouldCollapse(true)
    }
    track(PadAnalyticsEvent.PlaybackButtonClicked, analyticsPayload)
  }, [dispatch, collapsed, analyticsPayload, setShouldCollapse])

  const onPause = useCallback(() => {
    dispatch({ type: 'playback_pause' })
  }, [dispatch])

  const onPlaybackSpeedShift = useCallback(() => {
    dispatch({ type: 'playback_speed_shifted' })
  }, [dispatch])

  const onPlaybackRewindMs = useCallback(
    (ms: number) => {
      dispatch({ type: 'playback_rewound_ms', ms })
    },
    [dispatch]
  )

  if (timeline == null || timeline.frames.length === 0) return <span />

  const maxTimestamp = timeline.frames[timeline.frames.length - 1].timestamp || 0
  const frame = timeline.frames[frameIndex]

  // Block rendering until we have frame data to generate timeline
  if (!frame) return null

  return (
    <Grid container>
      <Grid item xs={3} className={styles.cell}>
        <Grid item xs={2} className={styles.cell}>
          <span
            className={classNames(styles.collapseButton, {
              collapsed,
            })}
            onClick={() => setCollapsed(!collapsed)}
          >
            <KeyboardArrowDownIcon />
          </span>
        </Grid>
        <div
          style={{
            flex: '1 1 auto',
            cursor: 'pointer',
            overflow: 'hidden',
          }}
          className={styles.cell}
          onClick={() => setCollapsed(!collapsed)}
        >
          {activeUser && (
            <>
              <Box
                display={{
                  xs: 'none',
                  md: 'inline-flex',
                }}
                className={styles.userIndicator}
              >
                <UserDot color={activeUser.color} />
              </Box>
              <Box
                display={{
                  xs: 'none',
                  sm: 'inherit',
                }}
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {activeUser.name}
              </Box>
            </>
          )}
        </div>
        <Grid item xs={9} sm={6} lg={5} className={styles.cell}>
          <div className={styles.rightAlign}>
            <FooterIconButton
              disableFocusRipple
              onClick={() => onPlaybackRewindMs(REWIND_MS)}
              className={classNames(styles.rewindButton, { 'is-enabled': canRewind })}
            >
              <FastRewindIcon fontSize="large" />
            </FooterIconButton>
            <FooterIconButton
              disableFocusRipple
              className={styles.playButton}
              onClick={playing ? onPause : onPlay}
            >
              {playing ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
            </FooterIconButton>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={7} className={styles.cell}>
        <TimelineMain timeline={timeline} />
      </Grid>

      <Grid item container xs={2} wrap="nowrap">
        <Box
          onClick={onPlaybackSpeedShift}
          className={classNames(styles.speedButton, {
            'is-accelerated': isPlaybackAccelerated,
          })}
          display="flex"
          alignItems="center"
          style={{
            color: '#BDBDBD',
            fontSize: '0.875rem',
            marginLeft: 8,
          }}
        >
          {playbackSpeed}&times;
        </Box>
        <Box display={{ xs: 'none', md: 'inherit' }} className={styles.timestampsRoot}>
          <Grid container wrap="nowrap" direction="column">
            <Grid container>
              <Grid container item xs={6} className={styles.date} justifyContent="flex-start">
                {frame && frame.timestamp && format(frame.timestamp, 'MMM d')}
              </Grid>
              <Grid container item xs={6} className={styles.date} justifyContent="flex-end">
                {format(frame.timestamp, 'MMM d') == format(maxTimestamp, 'MMM d')
                  ? ''
                  : format(maxTimestamp, 'MMM d')}
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={6} className={styles.time} justifyContent="flex-start">
                {frame && frame.timestamp && format(frame.timestamp, "h:mmaaaaa'm'")}
              </Grid>
              <Grid container item xs={6} className={styles.time} justifyContent="flex-end">
                {format(maxTimestamp, "h:mmaaaaa'm'")}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
