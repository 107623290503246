import { styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import Slider, { SliderProps, SliderTypeMap } from '@mui/material/Slider'
import makeStyles from '@mui/styles/makeStyles'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { track } from 'packs/main/coderpad_analytics'
import { PadAnalyticsEvent } from 'packs/main/constants'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePlayback } from '../../PlaybackContext'
import { EventFrame, Frame, Timeline } from '../../types'
import TimelineEventTick from './TimelineEventTick'

// This is the type signature of MUI's slider component.
export type SliderType = OverridableComponent<SliderTypeMap<'span', unknown>>

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 14,
    borderRadius: 6,
    margin: '0 1em',
  },
  editTick: {
    height: '100%',
    backgroundColor: theme.palette.playback?.timeline.ticks.edit,
    pointerEvents: 'none',
    zIndex: 2,
  },
  blankTick: {
    height: '100%',
    backgroundColor: theme.palette.playback?.timeline.ticks.idle,
    pointerEvents: 'none',
  },
  aiChatTick: {
    height: '100%',
    backgroundColor: theme.palette.playback?.timeline.ticks.aiChat,
    pointerEvents: 'none',
  },
}))

export const TimelineSliderBase = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})(() => ({
  '&.MuiSlider-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 0,
    height: '100%',
  },
  '& .MuiSlider-rail': {
    height: '100%',
    borderRadius: 6,
    backgroundColor: 'transparent',
    opacity: 1,
  },
  '& .MuiSlider-track': {
    height: '100%',
    backgroundColor: '#37C773',
    zIndex: 1,
    borderTopLeftRadius: '7px',
    borderBottomLeftRadius: '7px',
    borderWidth: 0,
  },
  '& .MuiSlider-thumb': {
    marginTop: 0,
    width: 18,
    height: 18,
    top: '50%',
    zIndex: 10,
  },
}))

const TickWrapper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  display: 'flex',
  borderRadius: 20,
  overflow: 'hidden',
  pointerEvents: 'none',
})

export interface TimelineBaseProps extends SliderProps {
  timeline: Timeline
  /**
   * Overridable MUI slider component.
   */
  SliderComponent?: SliderType
}

/**
 * This is the base timeline component. It was intentionally written with a
 * base/variant pattern in mind. This allows us to use the exact same logic
 * for both the primary and auxillary timelines, but with different styles.
 * Note: You will frequently see `2` appear as a magic number below. The
 * reason behind it, is that we *always* skip the first frame (0), as our
 * UI is set to start at frame 1. Also, we interpret the "active" frame as
 * the frame block that appears *behind* the cursor beam. For this reason,
 * the first timeline frame should not get displayed, since the cursor beam
 * starts at the very left edge of the timeline. So we end up having to skip
 * 2 frames when dealing with the presentation logic.
 */
export const TimelineBase: FC<React.PropsWithChildren<TimelineBaseProps>> = ({
  timeline,
  SliderComponent = TimelineSliderBase,
  ...props
}) => {
  const { scrubbing, setScrubbing } = usePlayback()
  const styles = useStyles()
  const frameIndex = useSelector((state) => state.playbackHistory.frameIndex)
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.userState.userId)
  const padId = usePadConfigValue('slug')

  const analyticsPayload = useMemo(
    () => ({
      user_id: userId,
      pad_id: padId,
      timestamp: Math.floor(Date.now() / 1000),
    }),
    [userId, padId]
  )

  const onSeekToFrame = useCallback(
    (frame: number | number[]) => {
      dispatch({ type: 'playback_seek_to_frame', index: frame })
    },
    [dispatch]
  )

  const startScrub = useCallback(() => {
    setScrubbing(true)
    track(PadAnalyticsEvent.PlaybackScrubberInteracted, analyticsPayload)
  }, [analyticsPayload, setScrubbing])

  /**
   * This effect allows us to listed for "scrubbing" events on the timeline.
   * The timeline component has a mouseDown event that sets the scrubbing
   * state to `true`, and then we immediately listen on the entire body for
   * a mouseUp event, so that we can set the scrubbing state to `false`.
   */
  useEffect(() => {
    if (scrubbing) {
      const mouseUpHandler = () => {
        setScrubbing(false)
      }
      document.body.addEventListener('mouseup', mouseUpHandler)
      return () => {
        document.body.removeEventListener('mouseup', mouseUpHandler)
      }
    }
    return () => null
  }, [scrubbing, setScrubbing])

  /**
   * Converts the timeline framedata into "chunks", which are adjacent frames
   * of the same type. This allows us to render significantly less DOM elements
   * It also fixes issues with relying on flexbox layout at sub-1px granularity.
   */
  const chunks = useMemo(() => {
    // group adjacent frames of the same type together
    let lastFrame: Frame | null = null
    let blockSize = 0
    let blockType: 'edit' | 'blank' | 'ai-chat' = 'blank'
    const frameChunks: {
      type: 'edit' | 'blank' | 'ai-chat'
      length: number
      width: number
    }[] = []

    const getFrameBlockType = (frame: Frame) => {
      switch (frame.type) {
        case 'ai-chat':
          return 'ai-chat'
        case 'insert':
        case 'delete':
          return 'edit'
        default:
          return 'blank'
      }
    }
    const width = (length: number) => (length / (timeline.frames.length - 2)) * 100
    const pushChunk = () => {
      frameChunks.push({
        type: blockType!,
        length: blockSize,
        width: width(blockSize),
      })
    }

    timeline.frames.slice(2).forEach((frame, index) => {
      if (
        lastFrame != null &&
        getFrameBlockType(frame) === getFrameBlockType(lastFrame) &&
        index !== timeline.frames.length - 2
      ) {
        blockSize++
      } else {
        pushChunk()
        blockSize = 1
        blockType = getFrameBlockType(frame)
      }
      lastFrame = frame
    })
    pushChunk()
    return frameChunks
  }, [timeline])

  const groupedEvents = useMemo(
    () =>
      timeline.events.reduce<Record<number, EventFrame[]>>((acc, event) => {
        const frame = Array.isArray(event.frame) ? event.frame[0] : event.frame
        acc[frame] = acc[frame] || []
        acc[frame].push(event)
        return acc
      }, {}),
    [timeline.events]
  )

  // We memoize the ticks so that we don't have to rebuild the timeline every frame
  // This has a fairly significant performance impact when running at >4x speed.
  const ticks = useMemo(
    () => [
      chunks.map((frame, index) =>
        frame.type === 'edit' ? (
          <span
            className={styles.editTick}
            style={{ width: `${frame.width}%` }}
            key={index}
            data-testid="edit-tick"
          />
        ) : frame.type === 'ai-chat' ? (
          <span
            className={styles.aiChatTick}
            style={{ width: `${frame.width}%` }}
            key={index}
            data-testid="ai-chat-tick"
          />
        ) : (
          <span
            className={styles.blankTick}
            style={{ width: `${frame.width}%` }}
            key={index}
            data-testid="empty-tick"
          />
        )
      ),
      Object.keys(groupedEvents).map((frame, index) => {
        const frameNumber = Number(frame)
        const left = (100 / (timeline.frames.length - 2)) * (frameNumber - 1)

        const events = groupedEvents[frameNumber]
        const isRange = events.length === 1 && Array.isArray(events[0].frame)
        const frameSpan = isRange ? events[0].frame[1] - events[0].frame[0] : 1
        const width = isRange ? (100 / (timeline.frames.length - 2)) * frameSpan : undefined
        const isOnLastFrame = frameNumber === timeline.frames.length - 1

        return (
          <TimelineEventTick
            leftOffset={isOnLastFrame ? 'calc(100% - 8px)' : `${left}%`}
            events={groupedEvents[frameNumber]}
            width={width}
            key={index}
            onClick={() => {
              onSeekToFrame(frameNumber)
            }}
          />
        )
      }),
    ],
    [chunks, styles, groupedEvents, timeline.frames.length, onSeekToFrame]
  )

  return (
    <div className={styles.root} style={{ width: '100%' }}>
      <SliderComponent
        min={1}
        max={timeline.frames.length - 1}
        step={1}
        onChange={(_: Event, frame: number | number[]) => onSeekToFrame(frame)}
        onMouseDown={startScrub}
        value={frameIndex}
        {...props}
      />
      <TickWrapper>{ticks}</TickWrapper>
    </div>
  )
}
