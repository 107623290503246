import Close from '@mui/icons-material/Close'
import {
  Button,
  DialogContent,
  DialogTitle,
  Paper,
  Popper,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLocalStorage } from '../../../utils'
import { ShowcaseTooltip } from '../Sandbox/ShowcaseTooltip'
import { selectIsSandboxShowcase, selectOnlineUsers } from '../selectors'

const FooterButton = styled(Button)({
  padding: '3px 16px',
  marginLeft: 10,
  lineHeight: 1.5,
})

const Title = styled(DialogTitle)({
  color: 'inherit',
  fontSize: 16,
})

const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
  padding: 0,
  minWidth: 'auto',
}))

export const EndInterviewButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const users = useSelector((state) => selectOnlineUsers(state))
  const { isOwner, padStartedAt, slug } = usePadConfigValues('isOwner', 'padStartedAt', 'slug')
  const [nudgeShown, setNudgeShown] = useLocalStorage(`nudge_shown_${slug}`, false)
  const [nonAdminLastSeen, setNonAdminLastSeen] = useState<number | null>(null)
  const [syntheticPadTimer, setSyntheticPadTimer] = useState<number>(0)
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const [openOverride, setOpenOverride] = useState(true)
  const isSandboxShowcase = useSelector(selectIsSandboxShowcase)

  const handleClose = useCallback(() => {
    setOpenOverride(false)
    setNudgeShown(true)
  }, [setNudgeShown])

  const nonOwnersInPad = useMemo(() => users.filter((u) => u.isOnline && !u.isOwner), [users])

  useEffect(() => {
    // check if there are other non owners in the pad
    if (nonOwnersInPad.length > 0 && nonAdminLastSeen === null) {
      setNonAdminLastSeen(Date.now())
    }
  }, [nonOwnersInPad, nonAdminLastSeen, setNonAdminLastSeen])

  useEffect(() => {
    if (nonAdminLastSeen === null || !isOwner) return
    const timer = setInterval(() => {
      setSyntheticPadTimer((n) => n + 60000)
    }, 60000)
    return () => {
      clearInterval(timer)
      setSyntheticPadTimer(0)
    }
  }, [nonAdminLastSeen, isOwner])

  // if 20 minutes have passed
  const enoughTimeHasPassed = useMemo(() => {
    const requiredTime = 20 * 60 * 1000
    return (
      (padStartedAt !== 0 && Date.now() - padStartedAt >= requiredTime) ||
      (nonAdminLastSeen !== null && syntheticPadTimer >= requiredTime)
    )
  }, [nonAdminLastSeen, padStartedAt, syntheticPadTimer])

  const shouldShowNudge = useMemo(
    () =>
      !nudgeShown && isOwner && nonOwnersInPad.length === 0 && enoughTimeHasPassed && openOverride,
    [nudgeShown, isOwner, nonOwnersInPad, enoughTimeHasPassed, openOverride]
  )

  const handleOnClick = useCallback(() => {
    $('#end-interview-modal').modal('show')
  }, [])

  return (
    <>
      <ShowcaseTooltip
        sectionTitle="End Interview"
        contents="Ending interview locks the pad and enables playback which replays the code typed."
        {...(!isSandboxShowcase && { titleOverride: '' })}
      >
        <span>
          <FooterButton
            ref={anchorRef}
            variant="contained"
            color="error"
            size="small"
            onClick={handleOnClick}
          >
            {large ? 'End Interview' : 'End'}
          </FooterButton>
        </span>
      </ShowcaseTooltip>
      <Popper
        open={shouldShowNudge}
        anchorEl={anchorRef.current}
        placement="top"
        sx={{ zIndex: 1, maxWidth: 320 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [4, 14],
              },
            },
          ],
        }}
      >
        <Paper data-testid="end-interview-nudge">
          <CloseButton onClick={handleClose} data-testid="end-interview-nudge-close">
            <Close />
          </CloseButton>
          <Title variant="h2">Have you finished your interview?</Title>
          <DialogContent>
            <Typography variant="body1">
              Click the <b>End Interview</b> button below to lock the pad and activate interview
              playback
            </Typography>
          </DialogContent>
        </Paper>
      </Popper>
    </>
  )
}
