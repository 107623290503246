import { Settings as SettingsIcon } from '@codinpad/shared-components/components/icons/index.js'
import { IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { PadTranslation } from '../constants'

const createStyles = makeStyles({
  tooltip: {
    maxWidth: '200px',
    fontWeight: 400,
    borderRadius: '6px',
    padding: '12px',
  },
  tooltipTitle: {
    fontSize: '1.1em',
    fontWeight: 'bold',
  },
  buttonContainer: {
    padding: '9px', // this reduces the hover circle size
    margin: '3px',
  },
})

export interface PadSettingsButtonProps {
  onClick: () => void
  opensFrom?: 'bottomRight' | 'left'
}

export const PadSettingsButton: React.FC<React.PropsWithChildren<PadSettingsButtonProps>> = (
  props
) => {
  const { onClick, opensFrom = 'left' } = props
  const styles = createStyles()

  return (
    <Tooltip
      arrow
      classes={{ tooltip: styles.tooltip }}
      placement={opensFrom === 'bottomRight' ? 'top' : 'right'}
      title={PadTranslation.padSettingsButtonTooltip}
    >
      <IconButton
        className={styles.buttonContainer}
        id="padSettingsBtn"
        onClick={onClick}
        size="large"
      >
        <SettingsIcon sx={{ color: (theme) => theme.palette.text.primary, ƒill: 'currentColor' }} />
      </IconButton>
    </Tooltip>
  )
}
