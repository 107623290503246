import { Box, CircularProgress, IconButton, SvgIcon, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as DuplicatePadIcon } from '../../../../assets/images/duplicate_pad.svg'
import { usePadFork } from '../../../graphql/hooks/pad/usePadFork/usePadFork'
import { useCurrentUser } from '../../../graphql/hooks/users/useCurrentUser/useCurrentUser'
import { usePadConfigValues } from '../../../packs/dashboard/components/PadContext/PadContext'
import { enqueueNotif } from '../reducers/notifications'
import { selectPadSettings } from '../selectors'
import DuplicatePadDialogResultDisplay from './DuplicatePadDialog/DuplicatePadDialogResultDisplay'

interface DuplicatePadButtonProps {
  tooltipPlacement?: 'top' | 'left' | 'bottom' | 'right'
}

const createStyles = makeStyles({
  tooltip: {
    backgroundColor: '#3F434B',
    maxWidth: '200px',
    fontWeight: 400,
    borderRadius: '6px',
    padding: '12px',
  },
  tooltipTitle: {
    fontSize: '1.1em',
    fontWeight: 'bold',
  },
  tooltipArrow: {
    color: '#3F434B',
  },
  buttonContainer: {
    padding: '9px', // this reduces the hover circle size
    margin: '3px',
  },
})

/**
 * Pad duplication button
 */
export const DuplicatePadButton: React.FC<React.PropsWithChildren<DuplicatePadButtonProps>> = ({
  tooltipPlacement,
}) => {
  const styles = createStyles()
  const dispatch = useDispatch()
  const { currentUser } = useCurrentUser()
  const { uiType } = usePadConfigValues('uiType')
  const [isLoading, setIsLoading] = useState(false)
  const { title } = useSelector(selectPadSettings)
  const { slug } = usePadConfigValues('slug')
  const { padFork } = usePadFork()

  const [isDuplicatePadDialogOpen, setIsDuplicatePadDialogOpen] = useState(false)
  const [newPadData, setNewPadData] = useState({})

  const handleDuplicateClick = () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    padFork({
      slug: `${slug}`,
      title: `Copy - ${title}`,
    }).then(({ pad, message }) => {
      setIsLoading(false)
      if (pad?.slug) {
        const newWindow = window.open(`/${pad.slug}`)
        if (newWindow) {
          newWindow.openedFromFork = true
        } else {
          setNewPadData(pad)
          setIsDuplicatePadDialogOpen(true)
        }
      } else {
        dispatch(
          enqueueNotif({
            message: message || 'We could not generate the copy. Please try again.',
            key: 'duplication-error',
            variant: 'error',
            autoDismissMs: 5000,
          })
        )
      }
    })
  }

  return currentUser?.allowPadCreation && uiType !== 'drawing_only' ? (
    <>
      <Tooltip
        arrow
        placement={tooltipPlacement}
        classes={{
          tooltip: styles.tooltip,
          arrow: styles.tooltipArrow,
        }}
        title={
          <>
            <Box className={styles.tooltipTitle}>Duplicate Pad</Box>
            Drawings and interviewer notes will not be copied to the new pad.
          </>
        }
      >
        <IconButton
          className={styles.buttonContainer}
          data-testid="duplicate-pad-btn"
          onClick={handleDuplicateClick}
          size="large"
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            <SvgIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                fill: 'currentColor',
              }}
            >
              <DuplicatePadIcon />
            </SvgIcon>
          )}
        </IconButton>
      </Tooltip>

      <DuplicatePadDialogResultDisplay
        isOpen={isDuplicatePadDialogOpen}
        newPadData={newPadData}
        close={() => setIsDuplicatePadDialogOpen(false)}
      />
    </>
  ) : null
}

export default DuplicatePadButton
