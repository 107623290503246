import { Box, styled, Table, TableCell, TableRow } from '@mui/material'

export const SummaryTableContainer = styled(Box)(({ theme }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.pricing.sandboxButton,
  borderRadius: '4px',
  margin: theme.spacing(2),
}))
export const SummaryTable = styled(Table)(({ theme }) => ({
  border: 0,
}))
export const SummaryTableRow = styled(TableRow)(({ theme }) => ({
  border: 0,
}))
export const SummaryTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  borderWidth: '1px 0 1px 0',
  borderStyle: 'solid',
  borderColor: theme.palette.pricing.sandboxButton,
}))
export const SummaryTableCellEmphasized = styled(SummaryTableCell)(({ theme }) => ({
  fontWeight: 600,
}))
export const SectionTitle = styled(SummaryTableCellEmphasized)(({ theme }) => ({
  color: theme.palette.tokens.coderpad[400],
}))
SectionTitle.defaultProps = { colSpan: 2 }
