import { Box } from '@mui/material'
import React from 'react'

import { Whiteboard } from '../../../dashboard/components/Whiteboard/Whiteboard'
import CodePane from '../../code_pane'
import { useRecordWorkspace } from '../CodeSpace/hooks/useRecordWorkspace'

/**
 * A workspace content that renders a Drawing Mode fullscreen (no right pane, and covers the whole window obscuring
 * the pad footer as well).
 */
export const FullScreenDrawing: React.FC<React.PropsWithChildren<unknown>> = () => {
  useRecordWorkspace()

  return (
    <>
      <Box className="DrawingContainer" sx={{ height: '100%' }}>
        <Whiteboard authorId={window.padConfig?.firebaseAuthorId} {...window.padConfig} />
      </Box>
      {/* Need to include the editor, but hidden, so that Firepad can initialize and sync user data */}
      <Box visibility="hidden">
        <CodePane width={'0px'} handleOpenDrawingMode={() => null} />
      </Box>
    </>
  )
}
