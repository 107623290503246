import { Box, Divider, List, ListItem, Pagination, styled, Theme, Typography } from '@mui/material'
import { QuestionLanguageDisplay } from 'packs/dashboard/Questions/QuestionLanguageDisplay/QuestionLanguageDisplay'
import React from 'react'

import { SearchParamNames } from '../../../../../graphql/hooks/question/useQuestionFilters/useQuestionFilters'
import { memoizedMakeUseQuestions } from '../../../../../graphql/hooks/question/useQuestionSearch/useQuestionSearch'
import * as queryStates from '../../../../../graphql/queryStates'
import { QueryQuestionsSearchArgs } from '../../../../../graphql/types'
import { LoadingBlock } from '../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { FavoritingStar } from '../../../../dashboard/Questions/FavoritingStar/FavoritingStar'
import { ScrollView } from '../../../ScrollView/ScrollView'

interface IQuestionListProps {
  filters: QueryQuestionsSearchArgs
  setFilter: (name: SearchParamNames, value: any) => void
  onSelect: (questionId: number) => void
  selectedQuestionId?: number
}

const ListItemTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}))

export const QuestionList: React.FC<React.PropsWithChildren<IQuestionListProps>> = ({
  filters,
  setFilter,
  onSelect,
  selectedQuestionId,
}) => {
  const useQuestions = memoizedMakeUseQuestions(`
    id
    favorited
    title
    language
    createdAt
    description
    user {
      id
      name
    }
    projectTemplate {
      name
      slug
    }
    spreadsheet {
      id
      gsheetId
    }
  `)
  const { questions, status, pageInfo } = useQuestions(filters)
  const isSearching = queryStates.isLoadingState(status)

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <ScrollView flex="1 1 auto" overflow="auto">
        <LoadingBlock isLoading={isSearching} message="Searching questions" minDisplayMS={0}>
          <List>
            {!questions.length && (
              <Box display="flex" justifyContent="center">
                No question search results
              </Box>
            )}
            {questions.map((question) => (
              <React.Fragment key={question.id}>
                <ListItem
                  button
                  onClick={() => onSelect(question.id)}
                  selected={question.id === selectedQuestionId}
                >
                  <Box width="100%">
                    <ScrollView
                      overflow="hidden"
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      mb={1}
                    >
                      <FavoritingStar questionId={question.id} favorited={question.favorited} />
                      <Box minWidth={0} ml={0.5}>
                        <ListItemTitle noWrap variant="body1" textOverflow="ellipsis">
                          {question.title}
                        </ListItemTitle>
                        {question.description && (
                          <Typography
                            noWrap
                            variant="body2"
                            textOverflow="ellipsis"
                            sx={{ marginBottom: 0.5 }}
                          >
                            {question.description}
                          </Typography>
                        )}
                        <Box display="flex" alignItems="center">
                          <QuestionLanguageDisplay question={question} />
                          <Typography
                            noWrap
                            variant="caption"
                            textOverflow="ellipsis"
                            sx={{ marginLeft: '4px' }}
                          >
                            {question.user?.name ? ` by ${question.user.name}` : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </ScrollView>
                  </Box>
                </ListItem>
                <Divider
                  sx={(theme: Theme) => ({
                    borderColor: theme.palette.mode === 'dark' ? '#1F2328' : 'rgba(0,0,0,0.12)',
                  })}
                />
              </React.Fragment>
            ))}
          </List>
        </LoadingBlock>
      </ScrollView>
      <Box display="flex" justifyContent="flex-end">
        <Pagination
          count={pageInfo.filteredPageCount}
          // The MUI Pagination component is 1-based.
          page={pageInfo.pageIdx + 1}
          onChange={(e, v) => setFilter('page', v - 1)}
        />
      </Box>
    </Box>
  )
}
