import React, { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'

interface InputAutogrowProps extends InputHTMLAttributes<HTMLInputElement> {
  minWidth?: number
  maxWidth?: number
  trailingSpace?: number
}

export const InputAutogrow: FC<React.PropsWithChildren<InputAutogrowProps>> = ({
  minWidth = 20,
  maxWidth = 200,
  trailingSpace = 10,
  value = '',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputWidth, setInputWidth] = useState<number>(minWidth)

  useEffect(() => {
    if (inputRef.current) {
      const textWidth = getTextWidth(String(value))
      // Calculate the new input width
      let newWidth = textWidth + trailingSpace
      if (newWidth < minWidth) {
        newWidth = minWidth
      } else if (newWidth > maxWidth) {
        newWidth = maxWidth
      }
      setInputWidth(newWidth)
    }
  }, [maxWidth, minWidth, trailingSpace, value])

  // Helper function to get the width of a given text string
  const getTextWidth = (text: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = getComputedStyle(inputRef.current as Element).getPropertyValue('font')
      const metrics = context.measureText(text)
      return metrics.width
    }
    canvas.remove()
    return 0
  }

  return <input {...props} ref={inputRef} style={{ width: `${inputWidth}px` }} value={value} />
}
