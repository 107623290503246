import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'

import * as queryStates from '../../../../graphql/queryStates'
import { useEnvironments } from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentSummary } from '../../Environments/EnvironmentsContext/types'

interface IDeleteEnvironmentDialogProps {
  environment: EnvironmentSummary | null
  onRequestClose: () => void
}

export const DeleteEnvironmentDialog: React.FC<
  React.PropsWithChildren<IDeleteEnvironmentDialogProps>
> = ({ environment, onRequestClose }) => {
  const { deleteEnvironment, clearDeleteStatus, deleteStatus } = useEnvironments()

  const handleDelete = useCallback(() => {
    if (!environment) {
      return
    }

    deleteEnvironment(environment.slug).then((success) => {
      if (success) {
        onRequestClose()
        clearDeleteStatus()
      }
    })
  }, [environment, deleteEnvironment, onRequestClose, clearDeleteStatus])

  useEffect(() => {
    clearDeleteStatus()
  }, [clearDeleteStatus, environment])

  const isDeleting = queryStates.isLoadingState(deleteStatus)
  const isOpen = !!environment

  return (
    <Dialog open={isOpen}>
      {isOpen && (
        <>
          <DialogTitle variant="h2" component="div">
            Delete {environment?.nameDisplayable} Environment?
          </DialogTitle>
          <DialogContent>
            <Collapse in={queryStates.isErrorState(deleteStatus)}>
              <Alert severity="error" sx={{ mb: (theme) => theme.spacing(1) }}>
                {deleteStatus[1] || 'Unexpected error deleting environment'}
              </Alert>
            </Collapse>
            <Typography variant="body1">
              The code in this <strong>{environment?.nameDisplayable}</strong> tab will be deleted
              and will not be available in playback. Are you sure you want to permanently delete the{' '}
              <strong>{environment?.nameDisplayable}</strong> environment?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onRequestClose} color="inherit">
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              disabled={isDeleting}
              endIcon={isDeleting ? <CircularProgress size={24} color="inherit" /> : null}
            >
              Delete
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
