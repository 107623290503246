import {
  DndContext as DndKitContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { Chip } from '@mui/material'
import { isText } from 'istextorbinary'
import { track } from 'packs/main/coderpad_analytics'
import { PadAnalyticsEvent } from 'packs/main/constants'
import path from 'path-browserify'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { FileWithPath } from 'react-dropzone'

import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useMonacoContext } from '../../MonacoContext'
import { DraggingFile, DroppableData, FileOperationProgress } from '../FolderDropZone/types'
import { readFileContents } from '../FolderDropZone/utils'
import { MonacoFile } from '../utils/types'
import { FileExistsDialog } from './FileMessageDialog/dialogs/FileExistsDialog'
import { UnsupportedFileTypeDialog } from './FileMessageDialog/dialogs/UnsupportedFileTypeDialog'
import { FileMessageDialog, FileMessageQueueItem } from './FileMessageDialog/FileMessageDialog'
import { convertWindowsToUnixLineEndings, findNextAvailableFileName } from './utils'

export type DndProviderContract = {
  selectedFiles: string[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  draggingFiles: DraggingFile[] | null
  handleFileUpload: (files: FileWithPath[], destination: string) => void
  currentOperation: FileOperationProgress | null
  setCurrentOperation: (operation: FileOperationProgress | null) => void
  setFileMessageDialogQueue: React.Dispatch<React.SetStateAction<FileMessageQueueItem[]>>
  popFileMessageQueue: () => void
}

export const DragAndDropContext = React.createContext({} as DndProviderContract)

export const DragAndDropProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { files } = useMonacoContext()
  const {
    activeFile,
    getFileContents,
    addFile,
    addFiles,
    deleteFile,
    setFileContents,
  } = useActiveEnvironment()

  const [FileMessageDialogQueue, setFileMessageDialogQueue] = useState<Array<FileMessageQueueItem>>(
    []
  )

  const FileMessageDialogOpen = useMemo(() => FileMessageDialogQueue.length > 0, [
    FileMessageDialogQueue,
  ])

  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [isDragging, setIsDragging] = useState(false)
  const [draggingFiles, setDraggingFiles] = useState<DraggingFile[] | null>(null)

  const [currentOperation, setCurrentOperation] = useState<FileOperationProgress | null>(null)

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  })
  const sensors = useSensors(mouseSensor)

  const popFileMessageQueue = useCallback(() => {
    setFileMessageDialogQueue((queue) => queue.slice(1))
  }, [])

  const handleFileExistsDialogReplace = useCallback(() => {
    setFileMessageDialogQueue((queue) => {
      const { newFileContents, existingFile, fileToDelete } = queue[0].data

      if (fileToDelete) {
        deleteFile(fileToDelete.fileId)
      }

      setFileContents(existingFile.fileId, newFileContents)

      return queue.slice(1)
    })
  }, [deleteFile, setFileContents])

  const handleFileExistsDialogKeepBoth = useCallback(() => {
    setFileMessageDialogQueue((queue) => {
      const { newFileContents, existingFile, fileToDelete } = queue[0].data

      if (fileToDelete) {
        deleteFile(fileToDelete.fileId)
      }

      const newFileName = findNextAvailableFileName(files, existingFile.path, 1)
      addFile(newFileName, newFileContents, false)

      return queue.slice(1)
    })
  }, [addFile, deleteFile, files])

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setIsDragging(true)
      const data = event.active.data.current as DroppableData

      if (data.type === 'folder') {
        const folderPath = data.path
        if (folderPath != null) {
          setDraggingFiles([
            {
              type: 'folder',
              path: folderPath,
            },
          ])
        }
      } else {
        const { file } = event.active?.data?.current as DroppableData
        if (file) {
          // If the dragged file is included in the selected files, drag all selected files
          // otherwise, de-select all other files and drag the single file
          if (activeFile?.id === file.fileId || selectedFiles.includes(file.fileId)) {
            const newDraggingFiles = selectedFiles
              .map((fileId) => {
                return files.find((file) => file.fileId === fileId)
              })
              .filter((file): file is MonacoFile => file != null)
            const activeMonacoFile = files.find((file) => file.fileId === activeFile?.id)
            if (activeMonacoFile != null) {
              newDraggingFiles.push(activeMonacoFile)
            }

            setDraggingFiles(
              newDraggingFiles.map(
                (file) =>
                  ({
                    type: 'file',
                    path: file.path,
                    file,
                  } as DraggingFile)
              )
            )
          } else {
            setDraggingFiles([
              {
                type: 'file',
                path: file.path,
                file,
              },
            ])
            setSelectedFiles([])
          }
        }
      }
    },
    [files, selectedFiles, activeFile]
  )

  const handleDragCancel = useCallback(() => {
    setIsDragging(false)
    setDraggingFiles(null)
  }, [])

  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { over, active } = event
    if (over == null || active == null) {
      return
    }

    const { path: targetFolder } = over?.data?.current as DroppableData

    if (targetFolder == null) return
  }, [])

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      setIsDragging(false)
      setDraggingFiles(null)
      // on dropping a file into a folder, we need to move the file to the new folder
      const { over, active } = event
      if (over == null || active == null) {
        return
      }

      const { path: targetFolder } = over?.data?.current as DroppableData
      if (targetFolder == null) return

      for (const droppedEntity of draggingFiles ?? []) {
        if (droppedEntity.type === 'file' && droppedEntity.file) {
          const droppedFile = droppedEntity.file
          if (droppedFile.path === path.join(targetFolder, droppedFile.name)) return
          // if a file with the same path already exists
          const targetFilePath = path.join(targetFolder, droppedFile.name)
          if (files.find((file) => file.path === targetFilePath)) {
            const newFileContents = await getFileContents(droppedFile.fileId)
            const existingFile = files.find((file) => file.path === targetFilePath)
            if (existingFile) {
              setFileMessageDialogQueue((queue) => [
                ...queue,
                FileExistsDialog({
                  fileName: droppedFile.name,
                  existingFile,
                  newFileContents,
                  fileToDelete: droppedFile,
                  onReplace: handleFileExistsDialogReplace,
                  onKeepBoth: handleFileExistsDialogKeepBoth,
                  onSkip: popFileMessageQueue,
                }),
              ])
            }
            return
          }

          // delete the file from its current location and add it to the new location
          const fileId = droppedFile.fileId
          const newFilePath = targetFolder + droppedFile.name
          const fileContents = await getFileContents(fileId)

          // delete the file from its current location
          deleteFile(fileId)

          const shouldAutoFocus = droppedFile.path === activeFile?.path

          // add the file to the new location
          addFile(newFilePath, fileContents ?? '', shouldAutoFocus)
        } else if (droppedEntity.type === 'folder') {
          // if we dropped a folder, we need to find every file in that folder and move it to the new folder. This is recursive.
          const droppedFolderPath = droppedEntity.path

          if (
            droppedFolderPath === path.join(targetFolder, path.basename(droppedFolderPath) + '/')
          ) {
            return
          }

          const filesToMove = files.filter((file) => file.path.startsWith(droppedFolderPath))

          for (let i = 0; i < filesToMove.length; i++) {
            const fileToMove = filesToMove[i]
            setCurrentOperation({
              text: `Moving ${i + 1} of ${filesToMove.length} files...`,
              progress: i / filesToMove.length,
            })
            const newFilePath = path.join(
              targetFolder,
              path.basename(droppedFolderPath),
              fileToMove.path.replace(droppedFolderPath, '')
            )

            // if a file with the same path already exists
            const targetFilePath = newFilePath
            if (files.find((file) => file.path === targetFilePath)) {
              const newFileContents = await getFileContents(fileToMove.fileId)
              const existingFile = files.find((file) => file.path === targetFilePath)
              if (existingFile) {
                setFileMessageDialogQueue((queue) => [
                  ...queue,
                  FileExistsDialog({
                    fileName: fileToMove.name,
                    existingFile,
                    newFileContents,
                    fileToDelete: fileToMove,
                    onReplace: handleFileExistsDialogReplace,
                    onKeepBoth: handleFileExistsDialogKeepBoth,
                    onSkip: popFileMessageQueue,
                  }),
                ])
              }
              continue
            }

            const fileContents = await getFileContents(fileToMove.fileId)

            // delete the file from its current location
            deleteFile(fileToMove.fileId)

            const shouldAutoFocus = fileToMove.path === activeFile?.path

            // add the file to the new location
            addFile(newFilePath, fileContents ?? '', shouldAutoFocus)
          }

          setCurrentOperation(null)
        }
      }
    },
    [
      draggingFiles,
      files,
      getFileContents,
      deleteFile,
      activeFile?.path,
      addFile,
      popFileMessageQueue,
      handleFileExistsDialogReplace,
      handleFileExistsDialogKeepBoth,
    ]
  )

  const draggedEntityName = useMemo(() => {
    // if there are multiple dragged files, just show the number of files
    // if there is a single file, show the file name
    // if there is a single folder, show the folder postfixed with `/`
    if (draggingFiles == null) return null

    if (draggingFiles.length > 1) {
      return `${draggingFiles.length} files`
    }

    const draggedFile = draggingFiles[0]
    if (draggedFile.type === 'file' && draggedFile.file) {
      return draggedFile.file.name
    }

    return path.basename(draggedFile.path) + '/'
  }, [draggingFiles])

  const handleFileUpload = useCallback(
    async (newFiles: FileWithPath[], destination: string) => {
      const batchFiles: {
        path: string
        contents: string
      }[] = []
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i]
        setCurrentOperation({
          text: `Uploading ${i + 1}/${newFiles.length} files...`,
          progress: i / newFiles.length,
        })
        const fileContents = await readFileContents(file)
        const fileBuffer = await file.arrayBuffer()
        if (!isText(file.name, Buffer.from(fileBuffer)) && !file.type.startsWith('text/')) {
          setFileMessageDialogQueue((queue) => [
            ...queue,
            UnsupportedFileTypeDialog({
              file,
              onOk: popFileMessageQueue,
            }),
          ])
          continue
        }

        // check if file already exists
        const existingFile = files.find(
          (f) => f.path === path.join(destination, file.path ?? '').replace(/^\/+/, '')
        )
        if (existingFile != null) {
          setFileMessageDialogQueue((queue) => [
            ...queue,
            FileExistsDialog({
              fileName: file.name,
              existingFile,
              newFileContents: fileContents,
              onReplace: handleFileExistsDialogReplace,
              onKeepBoth: handleFileExistsDialogKeepBoth,
              onSkip: popFileMessageQueue,
            }),
          ])
        } else {
          batchFiles.push({
            path: path.join(destination, file.path ?? '').replace(/^\/+/, ''),
            contents: convertWindowsToUnixLineEndings(fileContents),
          })
        }
      }

      if (batchFiles.length > 0) {
        await addFiles(batchFiles)
        track(PadAnalyticsEvent.FileTreeUploadedFile, {
          count: batchFiles.length,
        })
      }

      setCurrentOperation(null)
    },
    [
      files,
      popFileMessageQueue,
      handleFileExistsDialogReplace,
      handleFileExistsDialogKeepBoth,
      addFiles,
    ]
  )

  const value = useMemo(
    () => ({
      selectedFiles,
      setSelectedFiles,
      isDragging,
      setIsDragging,
      draggingFiles,
      handleFileUpload,
      currentOperation,
      setCurrentOperation,
      setFileMessageDialogQueue,
      popFileMessageQueue,
    }),
    [
      selectedFiles,
      setSelectedFiles,
      isDragging,
      setIsDragging,
      draggingFiles,
      handleFileUpload,
      currentOperation,
      setCurrentOperation,
      setFileMessageDialogQueue,
      popFileMessageQueue,
    ]
  )

  return (
    <DragAndDropContext.Provider value={value}>
      <FileMessageDialog
        title={FileMessageDialogQueue[0]?.title ?? undefined}
        message={FileMessageDialogQueue[0]?.message ?? undefined}
        open={FileMessageDialogOpen && currentOperation == null}
        onClose={popFileMessageQueue}
        actions={FileMessageDialogQueue[0]?.actions ?? []}
      />
      <DndKitContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <DragOverlay modifiers={[snapCenterToCursor]}>
          {isDragging && draggingFiles != null ? <Chip label={draggedEntityName} /> : null}
        </DragOverlay>
        {children}
      </DndKitContext>
    </DragAndDropContext.Provider>
  )
}

export function useDragAndDrop() {
  const contextVal = useContext(DragAndDropContext)

  if (contextVal == null) {
    throw new Error('`useDndContext` hook must be a descendant of a `DndProvider`')
  }

  return contextVal
}
