import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, styled, Typography } from '@mui/material'
import React, { useCallback } from 'react'

import { useTranscriberContext } from './TranscriberContext'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function StopConfirmation({
  isOpen,
  requestClose,
}: {
  isOpen: boolean
  requestClose: () => void
}) {
  const { stopTranscription } = useTranscriberContext()

  const stop = useCallback(() => {
    stopTranscription()
    requestClose()
  }, [stopTranscription, requestClose])

  return (
    <Dialog
      open={isOpen}
      dialogTitle="Stop Transcription"
      content={
        <div>
          <ContentBlock variant="body1">
            Are you sure you want to stop being transcribed?
          </ContentBlock>
        </div>
      }
      actions={[
        <Button key="keep-transcribing" onClick={requestClose} variant="outlined" color="inherit">
          Keep Transcribing
        </Button>,
        <Button key="stop-transcribing" onClick={stop} variant="contained" color="primary">
          Stop
        </Button>,
      ]}
    />
  )
}
