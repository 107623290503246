import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useEnvironments } from '../Environments/EnvironmentsContext/EnvironmentsContext'

export enum EnvironmentSelectorMenuTabs {
  Languages = 0,
  Questions = 1,
}

export const environmentSelectorMenuContext = createContext<{
  isOpen: boolean
  currentTab: EnvironmentSelectorMenuTabs
  openMenu: () => void
  closeMenu: () => void
  setTab: (tab: EnvironmentSelectorMenuTabs) => void
  openMenuAndSetTab: (tab: EnvironmentSelectorMenuTabs) => void
}>({
  isOpen: false,
  currentTab: EnvironmentSelectorMenuTabs.Languages,
  openMenu: () => null,
  closeMenu: () => null,
  setTab: (tab: EnvironmentSelectorMenuTabs) => null,
  openMenuAndSetTab: (tab: EnvironmentSelectorMenuTabs) => null,
})

export const EnvironmentSelectorMenuProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(EnvironmentSelectorMenuTabs.Languages)
  const { activeEnvironment } = useEnvironments()

  const openMenu = useCallback(() => setIsOpen(true), [])

  const closeMenu = useCallback(() => setIsOpen(false), [])

  const openMenuAndSetTab = useCallback(
    (newTab: EnvironmentSelectorMenuTabs) => {
      setCurrentTab(newTab)
      openMenu()
    },
    [openMenu]
  )

  // Close the menu when the active environment changes. This redirects the user's attention to the active env.
  useEffect(() => {
    closeMenu()
    setCurrentTab(EnvironmentSelectorMenuTabs.Languages)
  }, [activeEnvironment, closeMenu])

  const ctxVal = {
    isOpen,
    currentTab,
    openMenu,
    closeMenu,
    setTab: setCurrentTab,
    openMenuAndSetTab,
  }

  return (
    <environmentSelectorMenuContext.Provider value={ctxVal}>
      {children}
    </environmentSelectorMenuContext.Provider>
  )
}

export function useEnvironmentSelectorMenuContext() {
  const contextVal = useContext(environmentSelectorMenuContext)

  if (contextVal == null) {
    throw new Error(
      '`useEnvironmentSelectorMenuContext` hook must be a descendant of a `EnvironmentSelectorMenuProvider`'
    )
  }

  return contextVal
}
