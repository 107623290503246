import { gql, useMutation } from '@apollo/client'
import { ForkQuestionPayload, QuestionForkAttributes } from 'graphql/types'

import * as queryStates from '../../../queryStates'

export const QUESTION_FORK_MUTATION = gql`
  mutation ForkQuestion($input: ForkQuestionInput!) {
    forkQuestion(input: $input) {
      question {
        id
      }
      errors {
        message
        path
      }
      message
    }
  }
`

export function useQuestionFork(onError?: (error: Error) => void) {
  const [questionFork, { data, error, loading }] = useMutation<{
    forkQuestion: ForkQuestionPayload
  }>(QUESTION_FORK_MUTATION)

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error('There was an error forking the question.')
  } else if (data?.forkQuestion?.question) {
    status = queryStates.success('Question Forked: ' + data.forkQuestion.question.id)
  }

  return {
    status,
    async questionFork(forkAttrs: QuestionForkAttributes) {
      const response = await questionFork({
        variables: { input: { questionForkAttributes: forkAttrs } },
        context: { source: 'useQuestionFork.ts' },
      }).catch((err) => null)
      return {
        question: response?.data?.forkQuestion?.question,
        message: response?.data?.forkQuestion?.message,
        errors: response?.data?.forkQuestion?.errors,
      }
    },
  }
}
