import React, { useCallback, useEffect, useRef, useState } from 'react'
import { srcDoc } from 'srcdoc-polyfill'

import SpinnerPulse from '../../spinner_pulse'
import { Execution } from '../types'

export interface IPlaybackExecutionHTML {
  executions: Execution[]
}

export const PlaybackExecutionHTML: React.FC<React.PropsWithChildren<IPlaybackExecutionHTML>> = ({
  executions,
}) => {
  const [loading, setLoading] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const findRenderedExecution = useCallback(() => {
    if (executions) {
      for (let i = executions.length - 1; i >= 0; i--) {
        if (executions[i].type === 'output') {
          return executions[i].text
        }
      }
    }
    return null
  }, [executions])

  const renderIframe = useCallback(() => {
    setLoading(true)
    const content = findRenderedExecution()
    srcDoc.set(iframeRef.current!, content, { force: false })
  }, [findRenderedExecution])

  const handleIframeLoad = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    renderIframe()
  }, [renderIframe, executions])

  return (
    <div className="ExecutionHtml">
      <iframe
        className="ExecutionHtml-iframe"
        onLoad={handleIframeLoad}
        ref={iframeRef}
        sandbox="allow-forms allow-modals allow-popups allow-scripts"
      />
      {loading && <SpinnerPulse delay={300} />}
    </div>
  )
}
