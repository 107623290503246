import { Alert, Box, Button, styled, Typography } from '@mui/material'
import { ProjectOutput } from 'packs/main/ProjectOutput'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { Pane, SplitPane } from '../../../../dashboard/components/SplitPane/SplitPane'
import CodePane from '../../../code_pane'
import { useEnvironments } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentEditor } from '../../../EnvironmentsPane/EnvironmentEditor/EnvironmentEditor'
import { FileExplorer } from '../../../EnvironmentsPane/EnvironmentEditor/FileExplorer/FileExplorer'
import { RightPane } from '../../../RightPane/RightPane'
import { selectHasRightPane } from '../../../selectors'
import { GSheetsIframe } from '../GSheetsIframe'

const HackForRepositioningProjectOutput = styled('div')({
  position: 'absolute',
  top: '-53px',
  height: 'calc(100% + 53px)',
  left: 0,
  right: 0,
})

/**
 * A workspace content containing a left code pane and resources/notes right pane. Includes the "floating" resizable,
 * movable Drawing Mode and the methods necessary to support that.
 */
export const Horizontal: React.FC<
  React.PropsWithChildren<{ handleOpenDrawingMode: () => void }>
> = ({ handleOpenDrawingMode }) => {
  const hasRightPane = useSelector(selectHasRightPane)
  const {
    activeEnvironment,
    ready,
    setSandboxExecutionEnvironment,
    isExecPossible,
    environments,
  } = useEnvironments()

  const { hasEnvironments, isPlayback } = usePadConfigValues('hasEnvironments', 'isPlayback')

  const isPadReady =
    (hasEnvironments && ready && activeEnvironment) ||
    !hasEnvironments ||
    (environments.length === 0 && isPlayback)
  const shouldShowRightPane = hasRightPane && isPadReady
  const isJupyter = activeEnvironment?.projectTemplateSlug?.startsWith('jupyter')
  const isGsheets = activeEnvironment?.spreadsheet != null
  const noEnvironmentsPlayback = ready && environments.length === 0 && isPlayback

  const leftPaneEditor = useMemo(() => {
    return noEnvironmentsPlayback ? (
      <Pane id="no-environments-playback" initialSizePct={40}>
        <Box display="flex" justifyContent="center" pt="80px">
          <Alert severity="warning">
            No code playback is available; all code environments were deleted before this pad was
            ended.
          </Alert>
        </Box>
      </Pane>
    ) : isGsheets ? (
      <Pane id="projectOutput-spreadsheet" initialSizePct={70} minSize={150}>
        <GSheetsIframe spreadsheetId={activeEnvironment.spreadsheet} />
      </Pane>
    ) : isJupyter ? (
      <Pane id="projectOutput-jupyter" initialSizePct={70} minSize={150}>
        <HackForRepositioningProjectOutput>
          <ProjectOutput hidden={false} />
        </HackForRepositioningProjectOutput>
      </Pane>
    ) : (
      <Pane id="editor" minSize={150}>
        {hasEnvironments ? (
          <EnvironmentEditor />
        ) : (
          <CodePane handleOpenDrawingMode={handleOpenDrawingMode} />
        )}
      </Pane>
    )
  }, [
    activeEnvironment?.spreadsheet,
    noEnvironmentsPlayback,
    handleOpenDrawingMode,
    hasEnvironments,
    isGsheets,
    isJupyter,
  ])

  return (
    <SplitPane orientation="x">
      <Pane id="file-explorer" hidden={!activeEnvironment?.allowMultipleFiles} initialSizePct={15}>
        <FileExplorer />
      </Pane>
      <Pane id="editor-and-output">
        {isPadReady ? (
          <SplitPane orientation="x">
            {leftPaneEditor}
            {shouldShowRightPane ? (
              <Pane id="right-pane" minSize={150}>
                {!isExecPossible ? (
                  <Box display="flex" justifyContent="center" pt="80px">
                    <div>
                      <Typography variant="h2" sx={{ color: 'text.primary' }}>
                        Click to activate execution for this environment!
                      </Typography>
                      <Box display="flex" justifyContent="center" mt="24px">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            activeEnvironment &&
                            setSandboxExecutionEnvironment(activeEnvironment.slug)
                          }
                        >
                          Activate Execution
                        </Button>
                      </Box>
                    </div>
                  </Box>
                ) : (
                  <RightPane />
                )}
              </Pane>
            ) : null}
          </SplitPane>
        ) : null}
      </Pane>
    </SplitPane>
  )
}
