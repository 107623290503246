import { Link, Paper, styled, Typography } from '@mui/material'
import { useHasEnterprisePlan } from 'packs/dashboard/Questions/hooks'
import React, { FC } from 'react'

import { ReactComponent as TranscriptionSvg } from '../../../../../../assets/images/playback_ad_space/transcription.svg'
import _LanguageIcon from '../../../Monaco/FilePane/LanguageIcon'
import { useAdSpaceAnalytics } from '../useAdSpaceAnalytics'

const Wrapper = styled(Paper)(({ theme }) => ({
  background: '#2B3036',
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}))

const Text = styled(Typography)({
  fontSize: '14px',
  color: '#FFFFFF',
  lineHeight: '150%',
})

const SvgWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& svg': {
    width: '100%',
    maxHeight: '100%',
  },
}))

export const TranscriptionAd: FC<React.PropsWithChildren<unknown>> = () => {
  const trackAnalytics = useAdSpaceAnalytics('transcription')
  const isEnterprise = useHasEnterprisePlan()

  const TranscriptionLink = (
    <Link
      href="https://coderpad.io/resources/docs/interview/pads/live-interview-transcription/"
      target="_blank"
      rel="noreferrer"
    >
      Transcription
    </Link>
  )

  const AdText = isEnterprise ? (
    <>Want help with interview summaries? {TranscriptionLink} is available for your plan. </>
  ) : (
    <>Want help with interview summaries? {TranscriptionLink} is available for Custom plans. </>
  )
  const AdLinkText = isEnterprise ? 'Go to Settings →' : 'Contact Us →'
  const AdLinkUrl = isEnterprise ? '/dashboard/organization/settings' : '/billing/contact'

  return (
    <Wrapper>
      <Text>{AdText}</Text>
      <Text>
        <Link
          href={AdLinkUrl}
          target="_blank"
          onClick={() =>
            trackAnalytics({
              destination: {
                url: AdLinkUrl,
              },
            })
          }
        >
          {AdLinkText}
        </Link>
      </Text>
      <SvgWrapper>
        <TranscriptionSvg />
      </SvgWrapper>
    </Wrapper>
  )
}
