import { GridValidRowModel } from '@mui/x-data-grid'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback } from 'react'

import { DataTable } from '../../../components/DataTable/DataTable'
import { useRequestClientConfiguration } from '../RequestClientConfigurationContext'

export const RequestClientCookies: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isPlayback = usePadConfigValue('isPlayback')
  const { cookiesData, defaultCookieDomain } = useRequestClientConfiguration()

  // Instead of deleting cookies, we want to set an expiration date
  // This allows us to queue up cookie deletions and send them all at once
  // when the request is sent. Expired cookies are filtered out of the table
  // so they don't show up in the UI.
  const onDeleteRow = useCallback(
    (rowToDelete: GridValidRowModel) => {
      rowToDelete.expires = new Date()
      cookiesData.onRowEdit(rowToDelete)
      return rowToDelete
    },
    [cookiesData]
  )

  const onAddRow = useCallback(() => {
    cookiesData.handleAddRow({ domain: defaultCookieDomain })
  }, [cookiesData, defaultCookieDomain])

  return (
    <DataTable
      rows={cookiesData.rows.filter((row) => !row.expires || new Date(row.expires) > new Date())}
      columns={cookiesData.columns}
      onAddRow={onAddRow}
      onDeleteRow={onDeleteRow}
      onRowEdit={cookiesData.onRowEdit}
      readOnly={isPlayback}
    />
  )
}
