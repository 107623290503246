import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material'
import React from 'react'

const Title = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

export interface RequestClientUnsentRequestDialogProps {
  isOpen: boolean
  onContinueAnyway: () => void
  onGoToRequest: () => void
}

export const RequestClientUnsentRequestDialog: React.FC<
  React.PropsWithChildren<RequestClientUnsentRequestDialogProps>
> = ({ isOpen, onContinueAnyway, onGoToRequest }) => {
  return (
    <Dialog open={isOpen}>
      <Title>You have an unsent request</Title>
      <DialogContent>
        <DialogContentText>
          Navigating away from this request form will discard all current edits.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onContinueAnyway} sx={{ color: 'inherit' }} variant="text">
          Discard current edits
        </Button>
        <Button variant="contained" color="primary" onClick={onGoToRequest}>
          Return to form
        </Button>
      </DialogActions>
    </Dialog>
  )
}
