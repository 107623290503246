import { Stack, SvgIcon, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { camelCase } from 'lodash'
import React, { memo, useCallback, useMemo } from 'react'

import { ReactComponent as InfoIcon } from '../../../../assets/images/info_icon.svg'

export interface PadSettingsToggleOption<TValue> {
  label: string
  value: TValue
}

export interface PadSettingsToggleProps<TValue> {
  options: PadSettingsToggleOption<TValue>[]
  infoTooltip?: string
  label: string
  onValueChange: (changedValue: TValue) => void
  value: TValue
}

function PadSettingsToggleComponent<TValue>(props: PadSettingsToggleProps<TValue>) {
  const { infoTooltip, label, onValueChange, options = [], value } = props

  const handleChange = useCallback(
    (e: any) => {
      const optionIndex = parseInt(e.target.value)
      const newValue = options[optionIndex].value

      if (newValue === value) return

      onValueChange(newValue)
    },
    [options, onValueChange, value]
  )

  const toggleButtonGroupValue = useMemo(
    () => options.findIndex((option) => option.value === value),
    [options, value]
  )

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography>{label}</Typography>
        {!!infoTooltip && (
          <Tooltip title={infoTooltip}>
            <SvgIcon
              fontSize="small"
              sx={{
                '& svg': {
                  fill: (theme) => theme.palette.secondary.main,
                },
              }}
            >
              <InfoIcon />
            </SvgIcon>
          </Tooltip>
        )}
      </Stack>
      <ToggleButtonGroup
        color="primary"
        exclusive
        onChange={handleChange}
        value={toggleButtonGroupValue}
      >
        {options.map((option, index) => (
          <ToggleButton
            data-testid={`PadSettingsToggle-${camelCase(label)}-Option--${option.label}`}
            key={index}
            size="small"
            sx={{ fontSize: '0.875rem', height: 32 }}
            value={index}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  )
}

export const PadSettingsToggle = memo(
  PadSettingsToggleComponent
) as typeof PadSettingsToggleComponent
