import { Button, Menu, MenuItem, styled } from '@mui/material'
import _ from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomIcon, { Icons } from '../../components/CustomIcon/CustomIcon'
import { selectOnlineUsers } from '../../selectors'
import { InputAutogrow } from '../InputAutoGrow'

const Wrapper = styled('div')(() => ({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
}))

/**
 * Colored user dot icon
 */
const UserDot = styled('span')<{ color: string }>(({ theme, color }) => ({
  display: 'block',
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: color,
  flex: '0 0 auto',
}))

/**
 * Individual user buttons
 */
const UserListItem = styled(Button)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'none',
  overflow: 'hidden',
  flex: '0 1 auto',
  minWidth: 0,
  '& .username': {
    flex: '0 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

/**
 * The button that appears when there are too many users
 * to list in the footer.
 */
const UserOverflowButton = styled(Button)(() => ({
  flex: 'none',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}))

const UserOverflowMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: theme.palette.editor.footer,
  },
}))

const UserOverflowMenuItem = styled(MenuItem)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'default',
  },
}))

const StyledInputAutogrow = styled(InputAutogrow)(({ theme }) => ({
  padding: 0,
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[600]}`,
  background: 'transparent',
  outline: 0,
  color: 'inherit',
  margin: theme.spacing(0, 1),
}))

const OfflineMessage = styled('div')(() => ({
  position: 'absolute',
  marginTop: '4px',
  marginLeft: '15px',
  transition: 'opacity 0.2s ease-in-out 0s',
  opacity: 1,
}))

export const UserList: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch()
  const isOnline = useSelector((state) => state.userState.isOnline)
  const myUser = useSelector((state) => state.userState.userInfo[state.userState.userId])
  const trackedUserId = useSelector((state) => state.userState.trackedUserId)
  const focusTimeEnabled = useSelector((state) => state.padSettings.focusTimeEnabled)
  const users = useSelector(selectOnlineUsers)
  const [wasOnline, setWasOnline] = useState(isOnline)
  const [userName, setUserName] = useState<string>(myUser.name)

  const MAX_USERS_IN_FOOTER = 2
  const filteredUsers = useMemo(() => _.filter(users, (user) => !user.self), [users])
  const userListTruncated = useMemo(() => _.take(_.values(filteredUsers), MAX_USERS_IN_FOOTER), [
    filteredUsers,
  ])
  const userListOverflow = useMemo(() => _.drop(_.values(filteredUsers), MAX_USERS_IN_FOOTER), [
    filteredUsers,
  ])

  const userOverflowButtonRef = useRef<HTMLButtonElement>(null)
  const [userOverflowOpen, setUserOverflowOpen] = useState(false)

  const showReconnectMsg = !isOnline && wasOnline

  useEffect(() => {
    setUserName((prev) => {
      if (prev === myUser.name) return prev
      return myUser.name
    })
  }, [myUser.name])

  useEffect(() => {
    if (isOnline && !wasOnline) {
      setWasOnline(true)
    }
  }, [isOnline, myUser.name, userName, wasOnline])

  const handleFocus = useCallback((evt: any) => {
    evt.target.select()
  }, [])

  const handleBlur = useCallback(() => {
    dispatch({ type: 'username_edited', newName: userName })
  }, [dispatch, userName])

  const handleUsernameEdit = useCallback((evt: any) => {
    setUserName(evt.target.value)
  }, [])

  const trackUser = useCallback(
    (id: string) => {
      dispatch({ type: 'tracked_user_changed', userId: id })
    },
    [dispatch]
  )

  return (
    <Wrapper>
      {showReconnectMsg ? (
        <OfflineMessage>
          {/* TODO: replace FUI icon with MUI or custom icon */}
          <span className="fui-alert-circle UserList-offlineText" /> Reconnecting&hellip;
        </OfflineMessage>
      ) : (
        <>
          <UserDot color={myUser.color} />
          <StyledInputAutogrow
            value={userName}
            aria-label="Your display name"
            onChange={handleUsernameEdit}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {_.map(userListTruncated, (user) => (
            <UserListItem
              key={user.id}
              onClick={() => trackUser(user.id)}
              color="inherit"
              variant="text"
              startIcon={
                trackedUserId === user.id ? (
                  <CustomIcon icon={Icons.Eye} color={user.color} />
                ) : (
                  <UserDot color={user.color} />
                )
              }
            >
              <span className="username">
                {user.name} {focusTimeEnabled ? '(Focus Time)' : ''}
              </span>
            </UserListItem>
          ))}
          {userListOverflow.length > 0 && (
            <>
              <UserOverflowButton
                ref={userOverflowButtonRef}
                color="inherit"
                onClick={() => setUserOverflowOpen(true)}
              >
                +{userListOverflow.length} More
              </UserOverflowButton>
              <UserOverflowMenu
                anchorEl={userOverflowButtonRef.current}
                open={userOverflowOpen}
                onClose={() => setUserOverflowOpen(false)}
                anchorOrigin={{
                  vertical: -8,
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {_.map(userListOverflow, (user) => (
                  <UserOverflowMenuItem disableRipple key={user.id}>
                    <UserListItem
                      key={user.id}
                      onClick={() => trackUser(user.id)}
                      color="inherit"
                      variant="text"
                      startIcon={
                        trackedUserId === user.id ? (
                          <CustomIcon icon={Icons.Eye} color={user.color} />
                        ) : (
                          <UserDot color={user.color} />
                        )
                      }
                    >
                      <span className="username">
                        {user.name} {focusTimeEnabled ? '(Focus Time)' : ''}
                      </span>
                    </UserListItem>
                  </UserOverflowMenuItem>
                ))}
              </UserOverflowMenu>
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}
