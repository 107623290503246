import { styled, TableBody } from '@mui/material'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { track } from '../coderpad_analytics'
import { PadAnalyticsEvent } from '../constants'
import { useFrames } from '../playback/hooks/useFrames'
import { ScrollView } from '../ScrollView/ScrollView'
import { useTranscripts } from './hooks/useTranscripts'
import { Search } from './Search'
import { TranscriptItem } from './TranscriptItem'
import { TranscriptEntryKind } from './types'

const Table = styled('table')(({ theme }) => ({
  '& tr': {},
  '& td': {
    verticalAlign: 'top',
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    '&#author': {
      whiteSpace: 'nowrap',
      textAlign: 'right',
      paddingRight: 0,
    },
    '&#timestamp': {
      paddingLeft: theme.spacing(2),
      textAlign: 'right',
      '& span': {
        fontFamily: 'monospace',
        background: '#4848ef',
        textShadow: '1px 1px 0 #3b00c9',
        color: '#d7bffa',
        cursor: 'pointer',
        padding: theme.spacing(0.5),
        borderRadius: 4,
      },
    },
    '&#transcript': {
      paddingRight: theme.spacing(2),
      '& b': {
        background: theme.palette.mode === 'dark' ? '#ff5600' : '#fa9d6e',
        textShadow: theme.palette.mode === 'dark' ? '1px 1px 0 black' : '1px 1px 0 #FAFAFA',
      },
      // Setting 100% width makes the transcription message take up as much space as possible and pushes the
      // timestmap and author to the left.
      width: '100%',
    },
  },
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

interface TranscriptProps {
  hidden: boolean
}

export const Transcript: FC<React.PropsWithChildren<TranscriptProps>> = ({ hidden }) => {
  const transcripts = useTranscripts()
  const { frameIndex, frames } = useFrames()
  const scrollRef = useRef<HTMLPreElement>(null)
  const dispatch = useDispatch()

  const scrollToElement = useCallback((element: HTMLElement) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = element.offsetTop - 200
    }
  }, [])

  const firstTimestamp = useMemo(() => {
    transcripts.sort((a, b) => a.timestamp - b.timestamp)
    return transcripts[0]?.timestamp
  }, [transcripts])

  const currentTimestamp = frames[frameIndex]?.timestamp

  const [searchText, setSearchText] = useState('')
  const [searchIndex, setSearchIndex] = useState<number | null>(null)
  const searchResults = useMemo(() => {
    const filtered = transcripts.filter((transcript) =>
      transcript.kind === TranscriptEntryKind.SystemMessage
        ? false
        : transcript.transcripts[transcript.preferredTranscript ?? 0]
            .toLowerCase()
            .includes(searchText.toLowerCase())
    )
    setSearchIndex(0)

    return filtered
  }, [searchText, transcripts])

  useEffect(() => {
    if (searchIndex !== null && searchResults[searchIndex]) {
      dispatch({
        type: 'playback_seek_to_frame',
        index: frames.findIndex(
          (frame) => frame.timestamp === searchResults[searchIndex]?.timestamp
        ),
      })
    }
  }, [searchIndex, searchResults, frames, dispatch])

  const activeTranscript = useMemo(() => {
    const index = transcripts.findLastIndex(
      (transcript) => transcript.timestamp <= currentTimestamp
    )
    return transcripts[index]
  }, [currentTimestamp, transcripts])

  if (hidden) {
    return null
  }
  return (
    <div style={{ height: '100%', paddingBottom: 60, boxSizing: 'border-box' }}>
      <Header>
        <Search
          value={searchText}
          onChange={setSearchText}
          onShowNext={() => {
            setSearchIndex((prev) =>
              prev !== null
                ? // loop to the start if we're at the end
                  prev + 1 === searchResults.length
                  ? 0
                  : prev + 1
                : 0
            )
          }}
          onShowPrevious={() => {
            setSearchIndex((prev) =>
              prev !== null
                ? // loop to the end if we're at the start
                  prev - 1 === -1
                  ? searchResults.length - 1
                  : prev - 1
                : 0
            )
          }}
        />
      </Header>
      <ScrollView ref={scrollRef}>
        <Table>
          <TableBody>
            {transcripts.map((transcriptEntry) => (
              <TranscriptItem
                searchText={searchText}
                key={transcriptEntry.id}
                ref={(element) => {
                  if (element && transcriptEntry.id === activeTranscript?.id) {
                    scrollToElement(element)
                  }
                }}
                firstTimestamp={firstTimestamp}
                transcriptEntry={transcriptEntry}
                active={transcriptEntry.id == activeTranscript?.id}
                onClick={() => {
                  track(PadAnalyticsEvent.TranscriptionPlaybackLineClicked)
                  const index = frames.findIndex(
                    (frame) => frame.timestamp === transcriptEntry.timestamp
                  )
                  dispatch({ type: 'playback_seek_to_frame', index })
                }}
              />
            ))}
          </TableBody>
        </Table>
      </ScrollView>
    </div>
  )
}
