import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from './button'
import { useActiveEnvironment } from './Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useEnvironmentProjectTemplate } from './Environments/ActiveEnvironmentContext/useEnvironmentProjectTemplate'
import Console from './legacy_console'
import MarkdownOutput from './markdown_output'
import { MiniBrowser } from './MiniBrowser'
import padConfig from './pad_config'
import { PlaybackOutput } from './playback/PlaybackOutput'
import { ProjectOutput } from './ProjectOutput/index'
import { MarkdownOutputContainer } from './RightPane/Tabs/components/MarkdownOutputContainer'

interface ProgramOutputProps {
  hidden: boolean
  buttonRef: React.RefObject<Element>
}
const ProgramOutput: React.FC<React.PropsWithChildren<ProgramOutputProps>> = function ({
  hidden,
  buttonRef,
}) {
  const { language, execEnabled } = useSelector((state) => state.padSettings)
  const { darkColorScheme } = useSelector((state) => state.editorSettings)
  const { activeTab } = useSelector((state) => state.tabs)
  const { environment } = useActiveEnvironment()
  const { invisible, isPlayback } = usePadConfigValues('invisible', 'isPlayback')
  const dispatch = useDispatch()
  const { projectTemplate } = useEnvironmentProjectTemplate(environment?.projectTemplateSlug)

  const onResetClick = useCallback(() => {
    dispatch({ type: 'reset_clicked' })
  }, [dispatch])

  const onClearClick = useCallback(() => {
    dispatch({ type: 'clear_clicked' })
  }, [dispatch])

  const Component = padConfig.isPlayback
    ? PlaybackOutput
    : environment?.projectTemplateSlug?.startsWith('jupyter')
    ? null
    : environment?.projectTemplateSlug != null
    ? ProjectOutput
    : language === 'markdown'
    ? (props: { hidden: boolean }) => (
        <MarkdownOutputContainer hidden={props.hidden}>
          <MarkdownOutput {...props} />
        </MarkdownOutputContainer>
      )
    : language === 'html'
    ? MiniBrowser
    : Console

  // duplicates the logic for the run button in pads.js
  const languageCanExecute = window.CoderPad.LANGUAGES[language]?.execution
  const languageHasRunButton = languageCanExecute && language !== 'markdown'
  const resetButtonVisible =
    activeTab === 'output' && !isPlayback && !invisible && languageHasRunButton
  const resetButtonDisabled = resetButtonVisible && !execEnabled
  const isRunCommandProject = projectTemplate?.settings?.executionType === 'RunCommand'
  const clearButtonVisible =
    activeTab === 'output' && !isPlayback && !invisible && isRunCommandProject

  return (
    <>
      {Component != null && <Component hidden={hidden} />}
      {resetButtonVisible &&
        buttonRef.current &&
        createPortal(
          <Button
            type="regular"
            darkBg={darkColorScheme}
            onClick={onResetClick}
            disabled={resetButtonDisabled}
            tooltip={{
              placement: 'bottom',
              title: 'Resets your console, but not your code.',
              trigger: 'hover manual',
            }}
          >
            Reset
          </Button>,
          buttonRef.current
        )}
      {clearButtonVisible &&
        buttonRef.current &&
        createPortal(
          <Button
            type="regular"
            darkBg={darkColorScheme}
            onClick={onClearClick}
            disabled={clearButtonVisible && !execEnabled}
            tooltip={{
              placement: 'bottom',
              title: 'Clears the program output.',
              trigger: 'hover manual',
            }}
          >
            Clear
          </Button>,
          buttonRef.current
        )}
    </>
  )
}

export default ProgramOutput
