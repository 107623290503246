import { DataTable } from 'packs/main/components/DataTable/DataTable'
import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { RequestResponse } from '../../RequestClientContext/types'

export interface ResponseHeadersProps {
  headers: RequestResponse['headers']
}

export const ResponseHeaders: React.FC<React.PropsWithChildren<ResponseHeadersProps>> = ({
  headers,
}) => {
  const headerRows = useMemo(
    () => Object.entries(headers).map(([name, value]) => ({ id: uuid(), name, value })),
    [headers]
  )

  return (
    <DataTable
      rows={headerRows}
      columns={[
        { field: 'name', headerName: 'Name', width: 200, editable: false, sortable: false },
        { field: 'value', headerName: 'Value', flex: 1, editable: false, sortable: false },
      ]}
    />
  )
}
