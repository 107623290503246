import { Box, styled } from '@mui/material'
import React from 'react'

import { Resizer } from '../Resizer/Resizer'
import { useSplitPaneContext } from '../SplitPane'

type PaneDivProps = {
  shouldTransitionDim: boolean
}
const PaneDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldTransitionDim',
})<PaneDivProps>(({ theme, shouldTransitionDim }) => ({
  position: 'relative',
  transition: shouldTransitionDim
    ? theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      })
    : 'none',
}))

const PaneContents = styled(Box)(() => ({
  height: '100%',
  overflow: 'hidden',
}))

export interface IPaneProps {
  /** This prop will come from the SplitPane component. */
  paneIdx: number
  /**
   * User supplied value for the id of the pane. `id` is used as the key for the pane when rendered in SplitPane.
   * This prevents the pane contents getting re-rendered is the number of panes in a SplitPane changes.
   */
  id: string
  /** User supplied value for the minimum size of the pane in px. */
  minSize?: number
  /** User supplied value for the initial size of the pane in a percentage of its parent. */
  initialSizePct?: number
  /** User supplied value to indicate that this pane should be hidden */
  hidden?: boolean
  /** Optional prop to control whether the Pane hides the overflow of its contents. By default overflow is hidden. */
  hideOverflow?: boolean
  component?: React.ElementType<any>
}

/**
 * This is the pseudo-private implementation of a Pane. It is only intended to be used by SplitPane.
 */
export const _Pane: React.FC<React.PropsWithChildren<IPaneProps>> = ({
  paneIdx,
  children,
  hidden,
  component,
}) => {
  const {
    dimPcts,
    orientation,
    numPanes,
    numVisiblePanes,
    onResize,
    onResizeStop,
    shouldTransitionDim,
    resizingIndex,
  } = useSplitPaneContext()
  const hasResizer = numVisiblePanes > 1 && paneIdx < numPanes - 1
  const sizePct = dimPcts[paneIdx] * 100

  return (
    <>
      <PaneDiv
        shouldTransitionDim={shouldTransitionDim}
        data-testid="splitpane-pane"
        // Note: Using inline styles for the dimensions and pointer events, since these styles will change frequently at
        // a rapid rate and we wish to avoid MUI computing/appending additional classes for every dimension change.
        style={{
          width: orientation === 'x' ? `${sizePct}%` : '100%',
          height: orientation === 'x' ? '100%' : `${sizePct}%`,
          pointerEvents: resizingIndex >= 0 ? 'none' : undefined,
        }}
      >
        <PaneContents component={component || 'div'}>{children}</PaneContents>
      </PaneDiv>
      {!hidden && hasResizer && (
        <Resizer
          onResize={(dimChange) => onResize(paneIdx, dimChange)}
          onResizeStop={onResizeStop}
          isActive={resizingIndex === paneIdx}
        />
      )}
    </>
  )
}
