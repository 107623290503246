import { LinearProgress, styled, Typography } from '@mui/material'
import React from 'react'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export interface ProgressOverlayProps {
  operation: string
  /**
   * The progress of the operation, between 0 and 1.
   */
  progress: number
}

export const ProgressOverlay: React.FC<React.PropsWithChildren<ProgressOverlayProps>> = ({
  operation,
  progress,
}) => {
  return (
    <Container>
      <Typography variant="body1" sx={{ padding: 2 }}>
        {operation}
      </Typography>
      <LinearProgress variant="determinate" value={progress * 100} />
    </Container>
  )
}
