import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'

import * as queryStates from '../../../../graphql/queryStates'
import { useEnvironments } from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentSummary, EnvironmentTypes } from '../../Environments/EnvironmentsContext/types'

interface IResetEnvironmentDialogProps {
  environment: EnvironmentSummary | null
  onRequestClose: () => void
}

export const ResetEnvironmentDialog: React.FC<
  React.PropsWithChildren<IResetEnvironmentDialogProps>
> = ({ environment, onRequestClose }) => {
  const { resetEnvironment, clearResetStatus, resetStatus } = useEnvironments()

  const handleReset = useCallback(() => {
    if (!environment) {
      return
    }

    resetEnvironment(environment.slug).then((success) => {
      if (success) {
        onRequestClose()
        clearResetStatus()
      }
    })
  }, [environment, resetEnvironment, onRequestClose, clearResetStatus])

  useEffect(() => {
    clearResetStatus()
  }, [clearResetStatus, environment])

  const isDeleting = queryStates.isLoadingState(resetStatus)
  const isOpen = !!environment

  const envUsesProjectTemplate =
    environment?.kind === EnvironmentTypes.Project || environment?.projectTemplateSlug
  const resetWarning = envUsesProjectTemplate ? (
    <List sx={{ listStyle: 'disc inside' }}>
      <ListItem sx={{ display: 'list-item' }}>
        Any edits made to existing files will be deleted.
      </ListItem>
      <ListItem sx={{ display: 'list-item' }}>
        Any files deleted from the initial code will be added back to the tab.
      </ListItem>
      <ListItem sx={{ display: 'list-item' }}>Any added files will be removed.</ListItem>
    </List>
  ) : (
    <Typography variant="body1">Any edits made to the code will be deleted. </Typography>
  )

  return (
    <Dialog open={isOpen}>
      {isOpen && (
        <>
          <DialogTitle variant="h2" component="div">
            Reset {environment?.nameDisplayable} Environment?
          </DialogTitle>
          <DialogContent>
            <Collapse in={queryStates.isErrorState(resetStatus)}>
              <Alert severity="error" sx={{ mb: (theme) => theme.spacing(1) }}>
                {resetStatus[1] || 'Unexpected error resetting environment'}
              </Alert>
            </Collapse>
            {resetWarning}
            <Typography variant="body1">
              Are you sure you want to reset the <strong>{environment?.nameDisplayable}</strong>{' '}
              environment to its original state?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onRequestClose} color="inherit">
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleReset}
              disabled={isDeleting}
              endIcon={isDeleting ? <CircularProgress size={24} color="inherit" /> : null}
            >
              Reset
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
