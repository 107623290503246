import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, Typography } from '@mui/material'
import React from 'react'

interface DeclinedTranscriptionProps {
  isOpen: boolean
  requestClose: () => void
}
export function DeclinedTranscription({ isOpen, requestClose }: DeclinedTranscriptionProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={requestClose}
      dialogTitle="Someone declined call transcription"
      content={
        <div>
          <Typography variant="body1">
            At least 1 call participant chose to join the call without accepting transcription
            recording.
          </Typography>
          <Typography variant="body1">
            The Interviewer Notes tab is available for note taking.
          </Typography>
        </div>
      }
      actions={[
        <Button key="accept" onClick={requestClose} variant="contained" color="primary">
          Got it
        </Button>,
      ]}
    />
  )
}
