import { monaco } from '@codingame/monaco-editor-wrapper'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { createContext, FC, useContext, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFrames } from './hooks/useFrames'
import { usePlaybackParticipants } from './hooks/usePlaybackParticipants'
import { PlaybackParticipants } from './types'

export interface FileModels {
  [key: string]: monaco.editor.ITextModel | null
}

export interface IPlaybackContext {
  frameIndex: number
  playing: boolean
  paused: boolean
  scrubbing: boolean
  setScrubbing: React.Dispatch<React.SetStateAction<boolean>>
  participants: PlaybackParticipants
  frameDelay: number
}

const defaultPlaybackContext: IPlaybackContext = {
  frameIndex: 0,
  playing: false,
  paused: false,
  scrubbing: false,
  setScrubbing: () => null,
  participants: {},
  frameDelay: 0,
}

const PlaybackContext = createContext<IPlaybackContext>(defaultPlaybackContext)

/**
 * Convenience hook for accessing the playback context.
 */
export function usePlayback() {
  const context = useContext(PlaybackContext)

  if (!context) {
    throw new Error('`usePlayback` hook must be a descendant of a `PlaybackContextProvider`')
  }

  return context
}

export const PlaybackProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const frames = useSelector((state) => state.playbackHistory.frames)
  const playing = useSelector((state) => state.playbackHistory.playing)
  const [scrubbing, setScrubbing] = useState(false)
  const participants = usePlaybackParticipants()
  const { frameIndex, frameDelay } = useFrames()
  const iioLayout = usePadConfigValue('iioLayout')

  const currentTimestamp = useMemo(() => frames?.[frameIndex]?.timestamp ?? null, [
    frames,
    frameIndex,
  ])
  const lastTimestamp = useRef<number>(0)

  const isIIOPlaying = useMemo(() => {
    if (!iioLayout) return false
    const isPlaying = lastTimestamp.current !== currentTimestamp
    lastTimestamp.current = currentTimestamp
    return isPlaying
  }, [currentTimestamp, iioLayout])

  const paused = !playing && !scrubbing && !isIIOPlaying

  return (
    <PlaybackContext.Provider
      value={{
        ...defaultPlaybackContext,
        frameIndex,
        playing,
        paused,
        scrubbing,
        setScrubbing,
        participants,
        frameDelay,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  )
}
