import * as Sentry from '@sentry/browser'
import SyncHandle from 'packs/main/sync_handle'
import { MessageRelayType } from 'packs/zoom_room/types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useTranscriberContext } from '../../Transcriber/TranscriberContext/TranscriberContext'
import { useZoomRoomRelay } from './ZoomRoomRelay'

export const useVideoActionListeners = () => {
  const zoomRelay = useZoomRoomRelay()
  const dispatch = useDispatch()
  const { muteTranscription } = useTranscriberContext()

  const [isConnected, setIsConnected] = useState(false)
  const [isUpdatingAudioMuteStatus, setIsUpdatingAudioMuteStatus] = useState(false)
  const [isUpdatingVideoMuteStatus, setIsUpdatingVideoMuteStatus] = useState(false)

  // listen for a message from the iframe to know when when the zoom room id is ready
  // the zoom room is unable to communicate directly with firebase due to cross-origin restrictions
  useEffect(() => {
    const roomIdListener = (message: MessageEvent) => {
      if (message.data.id) {
        SyncHandle().set('zoomRoomId', message.data.id)
      }
    }
    const removeRoomIdListener = zoomRelay.onMessage(MessageRelayType.Id, roomIdListener)

    const removeAudioMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfAudioMuted,
      () => {
        setIsUpdatingAudioMuteStatus(false)
        dispatch({ type: 'mute_clicked', kind: 'audio', enabled: false })
        muteTranscription(true)
      }
    )
    const removeAudioUnMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfAudioUnmuted,
      () => {
        setIsUpdatingAudioMuteStatus(false)
        dispatch({ type: 'mute_clicked', kind: 'audio', enabled: true })
        muteTranscription(false)
      }
    )

    const removeVideoMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfVideoMuted,
      () => {
        setIsUpdatingVideoMuteStatus(false)
        dispatch({ type: 'mute_clicked', kind: 'video', enabled: false })
      }
    )
    const removeVideoUnmutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfVideoUnmuted,
      () => {
        setIsUpdatingVideoMuteStatus(false)
        dispatch({ type: 'mute_clicked', kind: 'video', enabled: true })
      }
    )
    const removeConnectedListener = zoomRelay.onMessage(MessageRelayType.Connected, () => {
      setIsConnected(true)
    })
    const removeDisconnectedListener = zoomRelay.onMessage(MessageRelayType.Disconnected, () => {
      setIsConnected(false)
    })
    const errorListener = (message: MessageEvent) => {
      Sentry.captureException(message.data.error, {
        tags: { layer: 'react', feature: 'zoom_room' },
      })
    }
    const removeErrorListener = zoomRelay.onMessage(MessageRelayType.Error, errorListener)

    return () => {
      removeRoomIdListener()
      removeAudioMutedStatusListener()
      removeAudioUnMutedStatusListener()
      removeVideoMutedStatusListener()
      removeVideoUnmutedStatusListener()
      removeConnectedListener()
      removeDisconnectedListener()
      removeErrorListener()
    }
  }, [dispatch, zoomRelay, muteTranscription])

  const handleAudioMute = useCallback(
    (muted: boolean) => {
      setIsUpdatingAudioMuteStatus(true)
      zoomRelay.sendMessage({
        type: muted ? MessageRelayType.SelfAudioMuted : MessageRelayType.SelfAudioUnmuted,
      })
    },
    [zoomRelay]
  )
  const handleVideoMute = useCallback(
    (muted: boolean) => {
      setIsUpdatingVideoMuteStatus(true)
      zoomRelay.sendMessage({
        type: muted ? MessageRelayType.SelfVideoMuted : MessageRelayType.SelfVideoUnmuted,
      })
    },
    [zoomRelay]
  )

  return {
    isConnected,
    isUpdatingAudioMuteStatus,
    isUpdatingVideoMuteStatus,
    handleAudioMute,
    handleVideoMute,
  }
}
