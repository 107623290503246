import { useHistory, useLocation } from 'react-router-dom'
import { ErrorNotice, SuccessNotice } from 'utils/notices/notices'

export const useSendSuccessNotice = () => {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  return (msgKey: SuccessNotice) =>
    history.replace(pathname + search + hash, { message: { msgKey, severity: 'success' } })
}

export const useSendErrorNotice = () => {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  return (msgKey: ErrorNotice) =>
    history.replace(pathname + search + hash, { message: { msgKey, severity: 'error' } })
}

export const useSendSuccessNoticeMessage = () => {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  return (msg: string) =>
    history.replace(pathname + search + hash, { message: { msg, severity: 'success' } })
}

export const useSendErrorNoticeMessage = () => {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  return (msg: string) =>
    history.replace(pathname + search + hash, { message: { msg, severity: 'error' } })
}

export const useResetNotice = () => {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  return () => history.replace(pathname + search + hash)
}
