import { pickBy } from 'lodash'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useOrgMembersSearch } from '../../../../graphql/hooks/organization/useOrgMembersSearch/useOrgMembersSearch'
import { selectMyUserId } from '../../selectors'
import SyncHandle from '../../sync_handle'
import { IInterviewNotesAuthor } from '../types'

export interface IInterviewNotesContextContract {
  interviewNotesArePrivate: boolean
  loading: boolean
  accessAllowed: boolean
  authors: IInterviewNotesAuthor[]
}

export interface IInterviewNotesUser {
  isAuthor?: boolean
  connections?: Record<string, boolean>
}

export interface IInterviewNotesUsers {
  [userId: string]: IInterviewNotesUser
}

export const defaultInterviewNotesContext = {
  interviewNotesArePrivate: true,
  loading: true,
  authorIds: [],
  accessAllowed: false,
  authors: [],
}

export const InterviewNotesContext = createContext<IInterviewNotesContextContract>(
  defaultInterviewNotesContext
)

export const InterviewNotesContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [interviewNotesArePrivate, setInterviewNotesPrivate] = useState(
    defaultInterviewNotesContext.interviewNotesArePrivate
  )
  const [loading, setLoading] = useState(defaultInterviewNotesContext.loading)
  const [authorIds, setAuthorIds] = useState<string[]>(defaultInterviewNotesContext.authorIds)
  const userId = useSelector(selectMyUserId)
  const accessAllowed =
    !interviewNotesArePrivate || authorIds.length === 0 || authorIds.includes(userId)

  const handle = SyncHandle()

  useEffect(() => {
    const areInterviewNotesPrivateWatchCallback = handle.watch(
      'notes/private',
      (isPrivate: boolean | null) => {
        setInterviewNotesPrivate(!!isPrivate)
        setLoading(false)
      }
    )

    const authorIdsWatchCallback = handle.watch('notes/users', (users: IInterviewNotesUsers) => {
      const usersFilteredByAuthor = pickBy(users, (userAttributes, _userId) => {
        return userAttributes.isAuthor
      })
      setAuthorIds(Object.keys(usersFilteredByAuthor))
    })

    return () => {
      // cleanup watch handle
      handle.off('notes/private', areInterviewNotesPrivateWatchCallback)
      handle.off('notes/users', authorIdsWatchCallback)
    }
  }, []) // empty b/c should only run once

  const orgMemberSearchFilter = useMemo(() => {
    return { firebaseIds: authorIds }
  }, [authorIds])

  const { members: authors } = useOrgMembersSearch(orgMemberSearchFilter, {
    skip: !interviewNotesArePrivate || authorIds.length < 1,
  })

  return (
    <InterviewNotesContext.Provider
      value={{ interviewNotesArePrivate, loading, accessAllowed, authors }}
    >
      {children}
    </InterviewNotesContext.Provider>
  )
}

export function useInterviewNotesContext() {
  const contextVal = useContext(InterviewNotesContext)

  if (contextVal == null) {
    throw new Error(
      '`useInterviewNotesContext` hook must be a descendant of a `InterviewNotesContextProvider`'
    )
  }

  return contextVal
}
