import { DeleteForever } from '@mui/icons-material'
import { IconButton, styled } from '@mui/material'
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { DataTableFooter } from './DataTableFooter'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',

  '& .MuiDataGrid-columnsContainer': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
    borderBottomColor: `${theme.palette.divider} !important`,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
  },
}))

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

export type DataTableColumns = GridColDef[]

export interface DataTableProps {
  /**
   * The columns to display in the table. This comes from MUI
   * Docs: https://mui.com/x/api/data-grid/grid-col-def/#properties
   */
  columns: DataTableColumns
  /**
   * The rows to display in the table.
   */
  rows: any[]
  /**
   * The callback to call when a row is finished editing.
   */
  onRowEdit?: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => GridValidRowModel | Promise<GridValidRowModel>
  /**
   * The callback to call when the add button in the footer is clicked.
   * If not provided, the add button will not be shown.
   */
  onAddRow?: () => void
  /**
   * The callback to call when the delete button in the row is clicked.
   * If not provided, the delete button will not be shown.
   */
  onDeleteRow?: (row: GridRowModel) => void
  /**
   * If true, the table will be in read only mode.
   * This will disable editing, deleting, and adding rows.
   */
  readOnly?: boolean
}

export const DataTable: FC<React.PropsWithChildren<DataTableProps>> = ({
  rows,
  columns,
  onRowEdit,
  onAddRow,
  onDeleteRow,
  readOnly,
}) => {
  /**
   * If an onDeleteRow handler is provided, we automatically add a delete_row column
   * and set a custom render cell to render a delete button hooked up to the handler.
   */
  const augmentedColumns = useMemo(() => {
    if (onDeleteRow && !readOnly) {
      return [
        ...columns,
        {
          field: 'delete_row',
          headerName: '',
          editable: false,
          sortable: false,
          align: 'right',
          renderCell: (params: GridCellParams) => (
            <StyledDeleteButton
              aria-label="delete"
              onClick={() => onDeleteRow(params.row)}
              disableRipple
              data-testid="delete-row"
            >
              <DeleteForever fontSize="small" />
            </StyledDeleteButton>
          ),
        },
      ] as DataTableColumns
    }
    return columns
  }, [columns, onDeleteRow, readOnly])

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})

  /**
   * This is a workaround for supporting single-click editing. By default
   * MUI's data grid is configured to support double-click editing. This is
   * unintuitive when coming from a platform like Postman, and it makes it
   * cumbersome to quickly modify the value of a cell.
   *
   * This workaround is based on: https://github.com/mui/mui-x/issues/2186
   */
  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      setCellModesModel((prevModel) => {
        if (!params.isEditable || readOnly) return prevModel
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        }
      })
    },
    [readOnly]
  )

  const handleCellModesModelChange = useCallback((newModel: any) => {
    setCellModesModel(newModel)
  }, [])

  return (
    <div>
      <StyledDataGrid
        rows={rows}
        columns={augmentedColumns}
        disableSelectionOnClick
        disableColumnMenu
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        processRowUpdate={onRowEdit}
        onProcessRowUpdateError={(err) => console.error(err)}
        autoHeight
        isCellEditable={() => !readOnly}
        components={{
          Footer: readOnly ? () => null : () => <DataTableFooter onAddRow={onAddRow} />,
        }}
        rowHeight={32}
        headerHeight={32}
      />
    </div>
  )
}
