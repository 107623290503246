import { Organization, PadUiTypes } from '../../../../graphql/types'

export enum PadState {
  pending = 'pending',
  started = 'started',
  ended = 'ended',
  deleted = 'deleted',
  submitted_take_home = 'submitted_take_home',
  opened_take_home = 'opened_take_home',
}

export enum PlaybackV3VariantType {
  record_only = 'record-only',
  record_and_playback = 'record-and-playback',
  nothing = 'nothing',
}

export enum CheatingPasteDetectionVariantType {
  NoTracking = 'no-tracking',
  Playback = 'playback',
  Realtime = 'realtime',
  TeamSettings = 'team-settings',
}

export enum CheatingLeftPadVariantType {
  NoTracking = 'no-tracking',
  Playback = 'playback',
  Realtime = 'realtime',
  TeamSettings = 'team-settings',
}

export interface IPadConfig {
  /** Pad UI color scheme bool. false makes pad in a "light" color scheme. */
  darkColorScheme: boolean
  /**
   * Interviewing.io flag. I.io is effectively a whitelabel of CoderPad. This flag is typically used to hide UI
   * elements or features that I.io does not want in their whitelabel of CoderPad.
   */
  iioLayout: boolean
  /** Should indicate that the pad is a demo pad, meaning that the user is not logged in and this is not a sandbox. */
  isDemoPad: boolean
  /** True if user is on free plan and pad was created longer ago than backend thinks is acceptable.  */
  isExpired: boolean
  /** True if user is logged in */
  isLoggedIn: boolean
  /**
   * True is user is the creator of the pad or a member of the org that the pad belongs to.
   * Note: This is also true if the user is a CoderPad Rails admin.
   */
  isOwner: boolean
  /** True is the pad is being viewed in pad playback. */
  isPlayback: boolean
  /** True if the pad is open to anonymous users */
  isPublic: boolean
  /** True if the pad is a sandbox pad (no slug, no editor syncing, no video calling, etc...). */
  isSandbox: boolean
  /** Time limit in minutes for the given take home test. 0 = unlimited */
  takeHomeTimeLimit: number
  /** True if timed take homes are disabled. Used during scheduled maintenance to prevent timed tests from being inturrupted by maintenance */
  timedTakeHomesDisabled: boolean
  /** Extra message to include with the "timed take homes are disabled" maintenance page. Useful to include downtime information. */
  timedTakeHomesMaintenanceExtraMessage: string
  /** True if Drawing Mode is in maintenance mode. Typically used to hide Drawing Mode in an emergency. */
  drawingModeMaintenance: boolean
  /** True if the pad is a Take-Home pad. */
  takeHome: boolean
  /** "#{browser.name} #{browser.full_version}; #{browser.platform.name} #{browser.platform.version}" */
  browser: string
  /**
   * True if the user has entered the pad "invisibly". This gives CoderPad team members the ability to drop in on a pad
   * to view the pad and its participants without the other participants being notified that the CoderPad team member
   * is in the pad.
   */
  invisible: boolean
  /** The pad's slug identifier. */
  slug: string
  /** The color the user's cursor tracker will be in the pad editor. */
  color: string
  /** Id to use for the user as an id in the Firebase rtdb. */
  firebaseAuthorId: string
  /** A flag that targets Goldman Sachs pads/users specifically. Used for hacks in the codebase just for GS. */
  goldman: boolean
  /** Name of the logged in user. */
  username: string
  padCreatedAt: number
  padStartedAt: number
  padOpenedAt: number
  padEndedAt: number
  /** Current state of the pad */
  padState: PadState
  /** The "ui type" of the pad. Used to designate coding vs. drawing pads. drawing = 'drawing_only'. code = 'coding' */
  uiType: string
  /** True if the pad is private. */
  isPadPrivate: boolean
  /** True if the pad settings button/modal should not be available. */
  disablePadSettings: boolean
  /** Contains the board id, if any */
  drawingBoardId?: string
  /** Contains the pad branding logo, if any */
  logoUrl?: string
  /** True if this pad has environments enabled, instead of a classic pad. */
  hasEnvironments: boolean
  /** Language of the pad on initial load. */
  lang: string
  /** Whether questions are allowed to be added to the pad */
  questionsEnabled: boolean
  /** Whether questions are read-only for all but org owners */
  questionsRestricted: boolean
  /** Whether the user have access to the notes */
  notesEnabled: boolean
  /** the Question object that is associated with the pad at page load */
  /** TODO: use Question type? */
  question: any
  /** Whether intellisense has been disabled by the org that owns the pad */
  intellisenseDisabledByOrganization: boolean
  /** Whether Focus Time is available in this pad. */
  focusTimeSupported: boolean
  /** Whether AI Chat is available in this pad. */
  aiChatEnabled: boolean
  /** Is the pad a scratch pad sandbox? */
  isScratchPad: boolean
  /** If this pad is being used for a sandbox preview environment, this is the environment slug for that environment. */
  sandboxEnvironmentPreviewSlug: string | null
  /** Is this pad being used for a sandbox question draft preview environment. */
  isQuestionDraftPreview: boolean
  /** Whether the pad should be allowed to show the request client. */
  requestClientEnabled: boolean
  /** Current Org of the pad */
  padOrg: {
    id?: number
    customIntro: Organization['customIntro']
    customIntroFirstTimeUserContentEnabled: Organization['customIntroFirstTimeUserContentEnabled']
    name: Organization['name']
    idpEnabled: Organization['idpEnabled']
    idpIssuer: Organization['idpIssuer']
    seededEmail?: string
    tsaPasteNotificationEnabled: Organization['tsaPasteNotificationEnabled']
    tsaPastePlaybackEnabled: Organization['tsaPastePlaybackEnabled']
    tsaDefocusNotificationEnabled: Organization['tsaDefocusNotificationEnabled']
    tsaDefocusPlaybackEnabled: Organization['tsaDefocusPlaybackEnabled']
  }
  databaseUrl: string
  firebaseShard?: string
  /* Whether this pad has access to AI chat */
  hasAiChat: boolean
  hasSpreadsheets: boolean
  hasTranscriptions?: boolean
  hasPureTranscription?: boolean
  callingEnabled?: boolean
  sandboxDrawingBoardId?: string | null
  videoServiceProvider: 'twilio' | 'zoom'
  playbackV3Variant?: PlaybackV3VariantType | null
  cheatingPasteDetectionVariant?: CheatingPasteDetectionVariantType | null
  cheatingCandidateLeftPadVariant?: CheatingLeftPadVariantType | null
  hasPlaybackTranscription: boolean
  hasPadSummary: boolean
}

export type PadConfigKeys = keyof IPadConfig

export const defaultPadConfig: IPadConfig = {
  darkColorScheme: true,
  iioLayout: false,
  isDemoPad: false,
  isExpired: false,
  isLoggedIn: false,
  isOwner: false,
  isPlayback: false,
  isPublic: false,
  isSandbox: false,
  takeHomeTimeLimit: 0,
  timedTakeHomesDisabled: false,
  timedTakeHomesMaintenanceExtraMessage: '',
  drawingModeMaintenance: false,
  takeHome: false,
  browser: '',
  invisible: false,
  slug: '',
  color: 'blue',
  firebaseAuthorId: '',
  goldman: false,
  username: '',
  padState: PadState.pending,
  padCreatedAt: 0,
  padStartedAt: 0,
  padOpenedAt: 0,
  padEndedAt: 0,
  uiType: PadUiTypes.Coding,
  isPadPrivate: false,
  disablePadSettings: false,
  hasEnvironments: false,
  lang: 'java',
  questionsEnabled: true,
  questionsRestricted: false,
  notesEnabled: false,
  question: null,
  intellisenseDisabledByOrganization: false,
  focusTimeSupported: false,
  isScratchPad: false,
  sandboxEnvironmentPreviewSlug: null,
  isQuestionDraftPreview: false,
  requestClientEnabled: false,
  padOrg: {
    id: undefined,
    customIntro: null,
    customIntroFirstTimeUserContentEnabled: true,
    name: '',
    idpEnabled: false,
    idpIssuer: '',
    tsaPasteNotificationEnabled: false,
    tsaPastePlaybackEnabled: false,
    tsaDefocusNotificationEnabled: false,
    tsaDefocusPlaybackEnabled: false,
  },
  databaseUrl: '',
  hasAiChat: false,
  hasSpreadsheets: false,
  aiChatEnabled: false,
  playbackV3Variant: null,
  hasTranscriptions: false,
  hasPureTranscription: false,
  hasPlaybackTranscription: false,
  videoServiceProvider: 'twilio',
  hasPadSummary: false,
}
