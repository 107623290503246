import React, { createContext, useCallback, useContext, useMemo } from 'react'

import track from '../../../main/track_event'

const AnalyticsZoneContext = createContext<{
  name: string
  trackEvent: (eventName: string, extraProps?: Record<string, any>) => void
  defaultProps: Record<string, any>
}>({
  name: '',
  trackEvent: () => {},
  defaultProps: {},
})

interface AnalyticsZoneProps {
  name: string
  children: React.ReactNode
  defaultEventProps?: Record<string, any>
  inheritDefaultProps?: boolean
}

export function AnalyticsZone({
  name,
  children,
  defaultEventProps = {},
  inheritDefaultProps = false,
}: AnalyticsZoneProps) {
  const { name: parentName, defaultProps: parentDefaultProps } = useAnalyticsZone()
  const zoneName = parentName.length > 0 ? `${parentName}.${name}` : name

  const trackEvent = useCallback(
    (eventName: string, extraProps: Record<string, any> = {}) => {
      const props = inheritDefaultProps
        ? { ...parentDefaultProps, ...defaultEventProps, ...extraProps }
        : { ...defaultEventProps, ...extraProps }
      props.zone = zoneName
      track(eventName, props)
    },
    [zoneName, defaultEventProps, parentDefaultProps, inheritDefaultProps]
  )

  const value = useMemo(() => {
    return {
      name: zoneName,
      trackEvent,
      defaultProps: defaultEventProps,
    }
  }, [zoneName, trackEvent, defaultEventProps])

  return <AnalyticsZoneContext.Provider value={value}>{children}</AnalyticsZoneContext.Provider>
}

export function useAnalyticsZone() {
  return useContext(AnalyticsZoneContext)
}
