import { OrganizationMainChallenge } from '../../../../../graphql/types'

export enum trialKeySteps {
  'set_up_organization' = 'set_up_organization',
  'screen_checkout_demo_test' = 'screen_checkout_demo_test',
  'screen_explore_question_bank' = 'screen_explore_question_bank',
  'screen_create_first_test' = 'screen_create_first_test',
  'screen_send_your_test' = 'screen_send_your_test',
  'interview_interview_top_candidate' = 'interview_interview_top_candidate',
  'interview_explore_demo_interview' = 'interview_explore_demo_interview',
  'interview_invite_expert' = 'interview_invite_expert',
  'interview_create_first_interview' = 'interview_create_first_interview',
  'screen_discover_screen_assesment' = 'screen_discover_screen_assesment',
}

export const stepsAvailableLength = {
  [OrganizationMainChallenge.SpeedUpHiring]: 6,
  [OrganizationMainChallenge.ReduceRecruitingCost]: 6,
  [OrganizationMainChallenge.MeetTechnicalGoals]: 5,
  [OrganizationMainChallenge.MinimizeTurnoverCosts]: 5,
}
