import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { track } from 'packs/main/coderpad_analytics'
import { PadAnalyticsEvent } from 'packs/main/constants'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFrames } from '../hooks/useFrames'
import { PlaybackParticipants as PlaybackParticipantsType } from '../types'
import { ParticipantRow } from './ParticipantRow'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flex: '1 1 auto',
    flexWrap: 'nowrap',
  },
  header: {
    flex: '0 0 auto',
    color: '#9C9C9C',
    paddingLeft: '1em',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  panel: {
    flex: '1 1 auto',
    border: `1px solid ${theme.palette.playback?.border}`,
    borderTopRightRadius: '4px',
    borderLeft: 'none',
    borderBottom: 'none',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.editor?.footer,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
}))

export const PlaybackParticipants: FC<
  React.PropsWithChildren<{
    participants: PlaybackParticipantsType
    activeUserId?: string
    setActiveUserId: (participant: string) => void
    setShouldCollapse: (shouldCollapse: boolean) => void
  }>
> = ({ participants, activeUserId, setActiveUserId, setShouldCollapse }) => {
  const styles = useStyles()
  const playing = useSelector((state) => state.playbackHistory.playing)
  const trackedUserId = useSelector((state) => state.playbackHistory.trackedUserId)
  const dispatch = useDispatch()
  const { getTimelineForUser } = useFrames()
  const userId = useSelector((state) => state.userState.userId)
  const padId = usePadConfigValue('slug')
  const analyticsPayload = useMemo(
    () => ({
      user_id: userId,
      pad_id: padId,
      timestamp: Math.floor(Date.now() / 1000),
    }),
    [userId, padId]
  )

  const handlePlay = useCallback(() => {
    dispatch({
      type: 'playback_play',
    })
  }, [dispatch])

  const handleSelect = useCallback(
    (authorId: string) => {
      setActiveUserId(authorId)
      setShouldCollapse(true)
      if (!playing) {
        handlePlay()
      }
      track(PadAnalyticsEvent.PlaybackUserTrackInteracted, analyticsPayload)
    },
    [setActiveUserId, setShouldCollapse, playing, analyticsPayload, handlePlay]
  )

  // Filter out all users without edits, and sort by edit count
  const filteredParticipants = useMemo(() => {
    return Object.entries(participants)
      .map(([authorId, participant]) => ({
        authorId,
        participant,
        timeline: getTimelineForUser(authorId),
      }))
      .filter((participant) => participant.timeline.edits > 0)
      .sort((a, b) => b.timeline.edits - a.timeline.edits)
  }, [participants, getTimelineForUser])

  useEffect(() => {
    // once our filteredParticipants list is built, auto-track the top user
    if (filteredParticipants.length > 0 && trackedUserId == null) {
      dispatch({
        type: 'playback_track_user',
        userId: filteredParticipants[0].authorId,
      })
    }
  }, [dispatch, filteredParticipants, trackedUserId])

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid container className={styles.header}>
        <Grid item container xs={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            Attendees
          </Grid>
        </Grid>
        <Grid item xs={7}>
          Activity Timeline
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'center' }}>
          Edits
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'center' }}>
          Runs
        </Grid>
      </Grid>
      <Box className={styles.panel}>
        {filteredParticipants.map((participant, i) => (
          <ParticipantRow
            key={participant.authorId}
            authorId={participant.authorId}
            participant={participant.participant}
            active={participant.authorId === activeUserId}
            onClick={() => handleSelect(participant.authorId)}
          />
        ))}
      </Box>
    </Grid>
  )
}
