import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import {
  DEFAULT_HEIGHT as DEFAULT_DRAWING_HEIGHT,
  DEFAULT_WIDTH as DEFAULT_DRAWING_WIDTH,
} from '../../../../dashboard/components/Whiteboard/FloatingDrawingBoard'
import { PadAnalyticsEvent } from '../../../constants'
import { selectDrawingModeOpen } from '../../../selectors'
import trackEvent from '../../../track_event'

export function useDrawingModeActions() {
  const dispatch = useDispatch()
  const { iioLayout, slug } = usePadConfigValues('iioLayout', 'slug')

  const drawWindowRef = useRef<Window | null>(null)

  const drawingModeOpen = useSelector(selectDrawingModeOpen)
  const [isNewWindow, setIsNewWindow] = useState(false)
  const showDrawingModal = drawingModeOpen && !isNewWindow

  const closeDrawingPanel = useCallback(() => {
    setIsNewWindow(false)
    dispatch({ type: 'drawing_mode_toggled', isOpen: false })
    trackEvent(PadAnalyticsEvent.DrawingModeModalClose)
  }, [dispatch])

  const handleOpenDrawingMode = useCallback(() => {
    if (drawWindowRef.current && !drawWindowRef.current.closed) {
      drawWindowRef.current.focus()
      trackEvent(PadAnalyticsEvent.DrawingModeWindowRefocus)
    } else if (drawingModeOpen) {
      closeDrawingPanel()
    } else {
      dispatch({ type: 'drawing_mode_toggled', isOpen: true })
      trackEvent(PadAnalyticsEvent.DrawingModeModalOpen)
    }
  }, [drawingModeOpen, closeDrawingPanel, dispatch])

  const openDrawingModeNewWindow = useCallback(
    (
      width = DEFAULT_DRAWING_WIDTH,
      height = DEFAULT_DRAWING_HEIGHT,
      zoom = 0,
      panX = 0,
      panY = 0
    ) => {
      setIsNewWindow(true)

      let url = '?drawing'
      if (zoom) {
        url += `&z=${zoom}`
      }
      if (panX && panY) {
        url += `&x=${panX}&y=${panY}`
      }
      if (iioLayout) {
        url += '&iio'
      }

      const openedWindow = window.open(
        url,
        `drawing_mode_${slug}`,
        `height=${height},width=${width},location=0`
      )
      if (openedWindow != null) {
        openedWindow.onload = () => {
          openedWindow.addEventListener('beforeunload', closeDrawingPanel)
          drawWindowRef.current = openedWindow
        }
      }

      trackEvent(PadAnalyticsEvent.DrawingModeWindowOpen)
    },
    [closeDrawingPanel, iioLayout, slug]
  )

  return {
    closeDrawingPanel,
    handleOpenDrawingMode,
    openDrawingModeNewWindow,
    showDrawingModal,
    drawingModeOpen,
  }
}
