import ErrorIcon from '@mui/icons-material/Error'
import { Box } from '@mui/material'
import React from 'react'

export const FailedToGenerateSummary: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box
      mt={10}
      textAlign="center"
      sx={(theme) => ({ color: theme.palette.programOutput.tabs.text })}
    >
      <ErrorIcon sx={(theme) => ({ fontSize: theme.spacing(8) })} />
      <Box mt={4}>Unable to generate Interview Summary</Box>
    </Box>
  )
}
