import firebase from 'firebase/compat/app'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { selectMyUserId } from '../../../selectors'
import SyncHandle from '../../../sync_handle'

export function useDenialNotifications(
  onlineUsersRef: React.RefObject<Record<string, any>>,
  isTranscribing: boolean,
  onDenial: (userId: string) => void
) {
  const selfUserId = useSelector(selectMyUserId)
  const isOwner = usePadConfigValue('isOwner')

  // We want to keep a record of users that have denied transcription. It is important to keep their deniedAt
  // timestamp so that if they join-and-decline twice, we can tell that there was a second denial and emit
  // a second event.
  const denialNotifications = useRef<Record<number, number>>({})
  // Watch for users denying transcription.
  useEffect(() => {
    let watcher: (snap: firebase.database.DataSnapshot) => void
    if (isOwner && isTranscribing) {
      watcher = SyncHandle().watch<Record<number, number>>(
        'usersDeniedTranscription',
        (userDenials) => {
          if (userDenials != null) {
            for (const [userId, deniedAt] of Object.entries(userDenials)) {
              if (
                deniedAt !== 0 && // Has denied transcirption/recording.
                String(userId) !== selfUserId && // Not the current user.
                onlineUsersRef.current?.[String(userId)] != null && // Is online in the pad.
                denialNotifications.current[+userId] !== deniedAt // Is a new denial.
              ) {
                onDenial(userId)
              }
              denialNotifications.current[+userId] = deniedAt
            }
          }
        }
      )
    }

    return () => {
      if (watcher) {
        SyncHandle().off('usersDeniedTranscription', watcher)
      }
      denialNotifications.current = {}
    }
  }, [isOwner, selfUserId, isTranscribing, onlineUsersRef, onDenial])
}
