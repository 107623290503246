import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ReactComponent as AddFileIcon } from '../../../../../../assets/images/projects/addFile.svg'
import { ReactComponent as AddFolderIcon } from '../../../../../../assets/images/projects/addFolder.svg'
import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { useGlobalEvents } from '../../../playback/GlobalEvents/useGlobalEvents'
import { ScrollView } from '../../../ScrollView/ScrollView'
import { useMonacoContext } from '../../MonacoContext'
import { useDragAndDrop } from '../DragAndDrop/DragAndDropContext'
import { FolderDropZone } from '../FolderDropZone'
import { ProgressOverlay } from '../ProgressOverlay'
import { DirectoryItem, DirectoryItemType, useDirectory } from '../useDirectory/useDirectory'
import { AddFile } from './AddFile'
import { AddFolder } from './AddFolder'
import { DropZoneMessaging } from './DropZoneMessaging'
import { FileLine } from './FileLine'
import { Folder } from './Folder'

export interface FileTreeProps {
  isHovering: boolean
}
export const FileTree: FC<React.PropsWithChildren<FileTreeProps>> = ({ isHovering }) => {
  const dispatch = useDispatch()
  const {
    activeFile,
    activateFile,
    environment,
    projectTemplate,
    deleteFile,
    deleteFolder,
  } = useActiveEnvironment()
  const { setActiveFile } = useMonacoContext()
  const activeFileFolder = activeFile == null ? '' : activeFile.path.replace(activeFile?.name, '')

  const [fileAddPath, setFileAddPath] = useState<string | null>(null)
  const [folderAddPath, setFolderAddPath] = useState<string | null>(null)

  const { isPlayback, hasEnvironments } = usePadConfigValues('isPlayback', 'hasEnvironments')
  const isLegacyHTML = !hasEnvironments
  const { isFileVisible } = useGlobalEvents()
  const { selectedFiles, setSelectedFiles, handleFileUpload, currentOperation } = useDragAndDrop()

  const onNewFileClick = useCallback(() => {
    setFileAddPath(activeFileFolder)
  }, [activeFileFolder])

  const onNewFolderClick = useCallback(() => {
    setFolderAddPath(activeFileFolder)
  }, [activeFileFolder])

  const renderDirectoryItem = useCallback(
    (item: DirectoryItem, depth: number) => {
      if (item.type === DirectoryItemType.fileInput) {
        const isInActiveFolder =
          activeFileFolder !== '' && fileAddPath != null && fileAddPath.startsWith(activeFileFolder)
        return (
          <AddFile
            key="add_file"
            depth={depth}
            folderPath={fileAddPath}
            onExit={() => setFileAddPath(null)}
            showHighlight={isHovering || isInActiveFolder}
            showSubHighlights={isHovering}
          />
        )
      } else if (item.type === DirectoryItemType.folderInput) {
        const isInActiveFolder =
          activeFileFolder !== '' &&
          folderAddPath != null &&
          folderAddPath.startsWith(activeFileFolder)
        return (
          <AddFolder
            key="add_folder"
            depth={depth}
            folderPath={folderAddPath}
            onExit={() => setFolderAddPath(null)}
            showHighlight={isHovering || isInActiveFolder}
            showSubHighlights={isHovering}
          />
        )
      } else if (item.type === DirectoryItemType.file) {
        const isInActiveFolder =
          activeFileFolder !== '' && item.file!.path.startsWith(activeFileFolder)
        return (
          <FileLine
            key={item.name}
            file={item.file!}
            depth={depth}
            onDeleteFile={deleteFile}
            showHighlight={isHovering || isInActiveFolder}
            showSubHighlights={isHovering}
            selected={selectedFiles.includes(item.file!.fileId)}
            onClick={() => {
              if (hasEnvironments) {
                activateFile(item.file!.fileId)
              } else {
                setActiveFile(item.file!)
              }
              setSelectedFiles([])
              dispatch({
                type: 'tracked_user_changed',
                userId: undefined,
              })
            }}
            onCommandClick={() => {
              if (activeFile?.id === item.file!.fileId) {
                return
              }
              setSelectedFiles((prev): string[] => {
                const index = prev.indexOf(item.file!.fileId)
                if (index === -1) {
                  return [...prev, item.file!.fileId]
                } else {
                  return prev.filter((id) => id !== item.file!.fileId)
                }
              })
            }}
          />
        )
      } else {
        const isInActiveFolder =
          activeFileFolder !== '' && (item.path + '/').startsWith(activeFileFolder)

        const folderSettings = projectTemplate?.settings?.folders
        const isFolderOpen =
          folderSettings?.folderOverrides?.[`${item.path}/`] ?? folderSettings?.defaultOpen ?? false

        const immediateChildren = item.children.filter((child) => {
          if (child.type === DirectoryItemType.file && isPlayback) {
            return child.file != null && isFileVisible(child.file.fileId)
          }
          return true
        })

        // Don't show empty folders in playback mode
        if (immediateChildren.length === 0 && isPlayback) {
          return null
        }

        return (
          <Folder
            key={item.name}
            name={item.name}
            path={item.path + '/'}
            defaultOpen={isPlayback || isLegacyHTML || isFolderOpen}
            depth={depth}
            onAddFileToFolder={setFileAddPath}
            onAddFolderToFolder={setFolderAddPath}
            onDeleteFolder={deleteFolder}
            showHighlight={isHovering || isInActiveFolder}
            showSubHighlights={isHovering}
            onFileUpload={handleFileUpload}
          >
            {immediateChildren.map((child) => {
              return renderDirectoryItem(child, depth + 1)
            })}
          </Folder>
        )
      }
    },
    [
      activeFileFolder,
      fileAddPath,
      isHovering,
      folderAddPath,
      isPlayback,
      isFileVisible,
      deleteFile,
      selectedFiles,
      activateFile,
      setSelectedFiles,
      activeFile?.id,
      projectTemplate?.settings?.folders,
      isLegacyHTML,
      deleteFolder,
      handleFileUpload,
      hasEnvironments,
    ]
  )

  const directory = useDirectory(fileAddPath, folderAddPath)

  return (
    <section>
      <div className="file-pane-header">
        <div className="text">Files</div>
        {environment?.projectTemplateSlug != null && (
          <div className="icons">
            <AddFolderIcon onClick={onNewFolderClick} />
            <AddFileIcon onClick={onNewFileClick} />
          </div>
        )}
      </div>
      <ScrollView className="file-pane-contents">
        <FolderDropZone path="" onFileUpload={handleFileUpload}>
          {directory.map((item) => {
            return renderDirectoryItem(item, 0)
          })}
        </FolderDropZone>
        <DropZoneMessaging isHovering={isHovering} onFileUpload={handleFileUpload} />
      </ScrollView>
      {currentOperation && (
        <ProgressOverlay operation={currentOperation.text} progress={currentOperation.progress} />
      )}
    </section>
  )
}
