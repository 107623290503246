import { makeStyles } from '@mui/styles'
import { Console as ConsoleFeed } from 'console-feed-optimized'
import { Message } from 'console-feed-optimized/lib/definitions/Component'
import { Methods } from 'console-feed-optimized/lib/definitions/Methods'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useProjectBrowser } from '../ProjectOutput/ProjectBrowserContext'
import { ScrollView } from '../ScrollView/ScrollView'

const useStyles = makeStyles((theme) => ({
  console: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    background: theme.palette.programOutput.console.background,
    color: 'white',
    fontFamily: 'Menlo, monospace',
    whiteSpace: 'pre-wrap',
    fontSize: '0.8125rem',
    border: `2px solid ${theme.palette.programOutput.console.border}`,
  },
  toolbar: {
    boxSizing: 'border-box',
    height: 40,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  clear: {
    color: theme.palette.programOutput.console.buttonColor,
    background: theme.palette.programOutput.console.buttonBackground,
    border: `1px solid ${theme.palette.programOutput.console.buttonBorder}`,
    borderRadius: 4,
    padding: '0 8px',
    marginRight: 8,
  },
  filterLevel: {
    color: theme.palette.programOutput.console.buttonColor,
    background: theme.palette.programOutput.console.buttonBackground,
    border: `1px solid ${theme.palette.programOutput.console.buttonBorder}`,
    borderRadius: 4,
    padding: '0 8px',
    margin: '0 0 0 auto',
  },
  feedContent: {
    flex: '1 1 auto',
    marginTop: 1, // Fixes the `margin-top: -1px` from console-feed does creates a scroll when not necessary
  },
}))

export interface IConsoleProps {
  maxLogCount?: number
}

export const Console: React.FC<React.PropsWithChildren<IConsoleProps>> = ({
  maxLogCount = 500,
}) => {
  const styles = useStyles()
  const [filterType, setFilterType] = useState<Methods | 'all'>('all')
  const darkColorScheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const [displayedLogs, setDisplayedLogs] = useState<Message[]>([])
  const { logs, clearLogs, hasOriginAccess } = useProjectBrowser()

  useEffect(() => {
    if (logs.length > maxLogCount) {
      setDisplayedLogs([
        {
          id: 'too-many-logs',
          method: 'log',
          data: [`%cToo many logs, displaying last ${maxLogCount} items`, `font-style: italic; `],
        },
        ...logs.slice(-maxLogCount),
      ])
    } else {
      setDisplayedLogs(logs)
    }
  }, [logs, maxLogCount])

  return (
    <div className={styles.console}>
      <div className={styles.toolbar}>
        <button onClick={clearLogs} className={styles.clear}>
          clear
        </button>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value as Methods)}
          className={styles.filterLevel}
        >
          <option value="all">all</option>
          <option value="log">log</option>
          <option value="error">error</option>
          <option value="warn">warn</option>
          <option value="info">info</option>
          <option value="debug">debug</option>
        </select>
      </div>
      <ScrollView
        display="flex"
        flexDirection="column-reverse"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        overflow="auto"
      >
        {/*
          This second div wrapper is useful to have a scroll that sticks to the bottom when
          new log appears except if the user is not already scrolled at the bottom
        */}
        <div className={styles.feedContent}>
          {hasOriginAccess ? (
            <ConsoleFeed
              logs={displayedLogs}
              filter={filterType === 'all' ? undefined : [filterType]}
              logGrouping={true}
              variant={darkColorScheme ? 'dark' : 'light'}
            />
          ) : (
            <div>Cannot access this website origin, go back to your environment website</div>
          )}
        </div>
      </ScrollView>
    </div>
  )
}
