import { Scalars } from 'graphql/types'

export const displayDate = (rubyDate: Scalars['ISO8601DateTime']) => {
  const d = new Date(rubyDate)
  return `${d.toLocaleDateString([], { month: 'short', day: 'numeric' })} ${d.toLocaleDateString(
    [],
    { year: 'numeric' }
  )} ${d.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    second: undefined,
    timeZoneName: 'shortGeneric',
  })}`
}

export const displayDuration = (durationMs: number) => {
  // Convert milliseconds to total seconds
  const totalSeconds = Math.floor(durationMs / 1000)

  // Calculate days, hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  if (hours >= 24) {
    const days = Math.floor(hours / 24)
    const remainderHours = hours % 24
    return `${days} days${remainderHours > 1 ? `, ${remainderHours} hours` : ''}`
  } else if (hours >= 2) {
    return `${hours} hours${minutes > 1 ? `, ${minutes} mins` : ''}`
  } else if (hours === 1) {
    return `1 hour${minutes > 1 ? `, ${minutes} mins` : ''}`
  } else if (minutes > 1) {
    return `${minutes} mins`
  } else if (minutes === 1) {
    return `1 min`
  } else if (seconds >= 1) {
    return `${seconds} second${seconds > 1 ? 's' : ''}`
  } else {
    return `${durationMs} ms`
  }
}

export const displayDurationBetweenTimes = (
  time1: Scalars['ISO8601DateTime'],
  time2: Scalars['ISO8601DateTime']
) => {
  const d1 = new Date(time1)
  const d2 = new Date(time2)
  const durationMs = d2.valueOf() - d1.valueOf()
  return displayDuration(durationMs)
}
