import { Participant } from './ZoomVideoCall/types'

export enum MessageRelayType {
  Log = 'zoomRoomLog',
  Error = 'zoomRoomError',

  Id = 'zoomRoomId',

  Connected = 'zoomRoomConnected',
  Disconnected = 'zoomRoomDisconnected',
  IsConnecting = 'zoomRoomIsConnecting',

  SelfJoined = 'zoomRoomSelfJoined',
  SelfLeft = 'zoomRoomSelfLeft',

  SelfAudioMuted = 'zoomRoomSelfAudioMuted',
  SelfAudioUnmuted = 'zoomRoomSelfAudioUnmuted',

  SelfVideoMuted = 'zoomRoomSelfVideoMuted',
  SelfVideoUnmuted = 'zoomRoomSelfVideoUnmuted',

  AudioInputDeviceChanged = 'zoomRoomAudioInputDeviceChanged',
  AudioInputDeviceUnavailable = 'zoomRoomAudioInputDeviceUnavailable',
  AudioOutputDeviceChanged = 'zoomRoomAudioOutputDeviceChanged',
  VideoInputDeviceChanged = 'zoomRoomVideoInputDeviceChanged',

  ParticipantsUpdated = 'zoomRoomParticipantsUpdated',

  Theme = 'zoomRoomThemeUpdated',

  SelfUsernameUpdated = 'zoomRoomSelfUsernameUpdated',

  SelfDisconnect = 'zoomRoomSelfDisconnect',
  SelfDisconnected = 'zoomRoomSelfDisconnected',

  RoomClosed = 'zoomRoomClosed',

  // iframe focus events, following the same pattern as the project iframe browser
  // the naming scheme doesn't match the zoomRoom messages, because this is integrating
  // into an existing messaging system that isn't scoped to zoom.
  IFrameFocused = 'iframeFocused',
  IFrameBlurred = 'iframeBlurred',
}

export type MessageRelayMessage =
  | {
      type:
        | MessageRelayType.SelfJoined
        | MessageRelayType.SelfLeft
        | MessageRelayType.SelfAudioMuted
        | MessageRelayType.SelfAudioUnmuted
        | MessageRelayType.SelfVideoMuted
        | MessageRelayType.SelfVideoUnmuted
        | MessageRelayType.SelfDisconnect
        | MessageRelayType.SelfDisconnected
        | MessageRelayType.RoomClosed
        | MessageRelayType.AudioInputDeviceUnavailable
        | MessageRelayType.IFrameFocused
        | MessageRelayType.IFrameBlurred
    }
  | {
      type: MessageRelayType.Error
      error: Error
    }
  | {
      type: MessageRelayType.Log
      message: string
    }
  | {
      type: MessageRelayType.Connected | MessageRelayType.Disconnected
      username: string
    }
  | {
      type: MessageRelayType.Id
      id: string
    }
  | {
      type: MessageRelayType.IsConnecting
      isConnecting: boolean
    }
  | {
      type: MessageRelayType.ParticipantsUpdated
      participants: Participant[]
    }
  | {
      type: MessageRelayType.Theme
      theme: string
    }
  | {
      type: MessageRelayType.SelfUsernameUpdated
      name: string
    }
  | {
      type:
        | MessageRelayType.AudioInputDeviceChanged
        | MessageRelayType.AudioOutputDeviceChanged
        | MessageRelayType.VideoInputDeviceChanged
      deviceId: string
    }
