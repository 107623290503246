import { KeyboardArrowLeft, KeyboardArrowRight, OpenInNew, Refresh } from '@mui/icons-material'
import { Box, IconButton, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { PadAnalyticsEvent } from '../constants'
import trackEvent from '../track_event'
import { useProjectBrowser } from './ProjectBrowserContext'
import { ServiceStatus, ServiceStatusProps } from './ServiceStatus'
import { UrlInput } from './UrlInput'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    height: '100%',
  },
  content: {
    border: 0,
    flexGrow: 1,
    background: theme.palette.editor?.sidebar.background,
  },
  button: {
    margin: '0 2px',
  },
}))

export const ProjectBrowser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const styles = useStyles()
  const serviceStatus = useSelector((state) => state.padSettings.serviceStatus) as string
  const [iframeReady, setIframeReady] = useState(false)
  const {
    currentUrl,
    iframeSrc,
    iframeRef,
    clearLogs,
    reload,
    navigateBack,
    navigateForward,
    setLocation,
    resetLocation,
  } = useProjectBrowser()

  const handleIframeRefresh = useCallback(() => {
    clearLogs()
    reload()
  }, [clearLogs, reload])

  const handleLoad = useCallback(() => {
    setIframeReady(true)
  }, [])

  const serviceReady = serviceStatus === 'ready'
  useEffect(() => {
    if (serviceReady) {
      setIframeReady(false)
    }
  }, [serviceReady, clearLogs])

  const statusProps = useMemo<ServiceStatusProps | null>(() => {
    switch (serviceStatus) {
      case 'ready':
        return !iframeReady ? { message: 'Loading page...', spinner: true } : null
      case 'starting':
      case 'unknown':
        return { message: 'Starting web server...', spinner: true }
      case 'stopped':
        return {
          message:
            'Server has stopped running.<br/>Click the server Restart button below to relaunch it.',
        }
      case 'none':
        return { message: 'Click the server Restart button to get started.' }
      default:
        return {}
    }
  }, [iframeReady, serviceStatus])

  return (
    <div className={styles.container}>
      <Box display="flex" alignItems="center" marginBottom={'4px'}>
        <Box margin="0 2px" flex="0 0 auto">
          <IconButton size="small" onClick={navigateBack} className={styles.button} title="Back">
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            size="small"
            onClick={navigateForward}
            className={styles.button}
            title="Forward"
          >
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleIframeRefresh}
            className={styles.button}
            title="Reload"
          >
            <Refresh />
          </IconButton>
        </Box>
        <UrlInput url={currentUrl} onSubmit={setLocation} onReset={resetLocation} />
        <Box margin="0 2px" flex="0 0 auto">
          <IconButton
            target="_blank"
            disabled={currentUrl == null}
            href={currentUrl ?? origin}
            size="small"
            title="Open in new tab"
            onClick={() => trackEvent(PadAnalyticsEvent.ProjectMiniBrowserOpenNewWindow)}
          >
            <OpenInNew />
          </IconButton>
        </Box>
      </Box>
      {serviceReady && (
        <iframe
          /* @ts-ignore: credentialless isn't fully supported yet */
          credentialless="true"
          className={styles.content}
          ref={iframeRef}
          src={iframeSrc}
          onLoad={handleLoad}
          hidden={!iframeReady}
          allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts allow-downloads allow-pointer-lock"
        />
      )}
      {statusProps != null && <ServiceStatus {...statusProps} />}
    </div>
  )
}
