import { dirname, join } from 'path-browserify'
import React, { useCallback } from 'react'

import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { DirectoryItemInput } from '../DirectoryItemInput'
import LanguageIcon from '../LanguageIcon'
import { WrappedToDepth } from '../utils/WrappedToDepth'

interface IRenameFileProps {
  depth: number
  filePath: string
  oldFileName: string
  onExit: () => void
  showHighlight: boolean
  showSubHighlights: boolean
}
export const RenameFile: React.FC<React.PropsWithChildren<IRenameFileProps>> = ({
  depth,
  filePath,
  oldFileName,
  onExit,
  showHighlight,
  showSubHighlights,
}) => {
  const { activeFile, renameFile } = useActiveEnvironment()

  const handleSubmit = useCallback(
    (fileName: string) => {
      renameFile(join(filePath, oldFileName), join(filePath, fileName))
      onExit()
    },
    [filePath, oldFileName, renameFile, onExit]
  )

  if (activeFile == null) {
    return null
  }

  const activeFilePath = dirname(activeFile.path)
  const highlightDepth = activeFilePath.split('/').length - 1

  return (
    <div className="directory-item-input">
      <WrappedToDepth
        depth={depth}
        highlightDepth={highlightDepth}
        showHighlight={showHighlight}
        showSubHighlights={showSubHighlights}
        opaque={true}
      >
        <DirectoryItemInput
          defaultValue={oldFileName}
          onExit={onExit}
          onSubmit={handleSubmit}
          icon={<LanguageIcon language={''} />}
        />
      </WrappedToDepth>
    </div>
  )
}
