import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { srcDoc } from 'srcdoc-polyfill'

import HTMLErrorBox from '../html_error_box'
import { useMiniBrowser } from './MiniBrowserProvider'

const DEFAULT_IFRAME_HTML =
  '<html><body style="color:#7f7f7f;background-color:rgba(29, 33, 38, 0)"><p>Click Run to render HTML.</p></body></html>'

export const IFrame: React.FC<React.PropsWithChildren<unknown>> = () => {
  const iframe = React.useRef<HTMLIFrameElement>(null)
  const { timestamp, processedHtml, errors } = useMiniBrowser()
  const dispatch = useDispatch<Dispatch>()

  const onDismissErrors = useCallback(() => {
    dispatch({ type: 'html_errors_cleared' })
  }, [dispatch])

  useEffect(() => {
    srcDoc.set(
      iframe.current!,
      processedHtml != null && processedHtml != '' ? processedHtml : DEFAULT_IFRAME_HTML,
      {
        force: false,
      }
    )
  }, [processedHtml, timestamp])

  return (
    <div className="HTMLOutput">
      <iframe
        /* @ts-ignore: credentialless isn't fully supported yet */
        credentialless
        className="HTMLOutput-content"
        ref={iframe}
        sandbox="allow-same-origin allow-forms allow-modals allow-popups allow-scripts"
      />
      <HTMLErrorBox errors={errors} onDismissErrors={onDismissErrors} />
    </div>
  )
}
