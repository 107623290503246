import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranscriberContext } from './TranscriberContext'

interface AudioSelectionProps {
  isOpen: boolean
  requestClose: () => void
}

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function AudioSelection({ isOpen, requestClose }: AudioSelectionProps) {
  const { audioDeviceId, setAudioDeviceId, startTranscription } = useTranscriberContext()

  const [audioInfo, setAudioInfo] = useState<{ devices: MediaDeviceInfo[]; error: string }>({
    devices: [],
    error: '',
  })

  useEffect(() => {
    // Only do this when the dialog is open so the browser permissions prompt doesn't show up until the user
    // wants to start transcribing.
    if (isOpen) {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            setAudioInfo({
              devices: devices.filter(
                (device) => device.kind === 'audioinput' && !/\(Virtual\)/.test(device.label)
              ),
              error: '',
            })
          })
        })
        .catch((err) => {
          setAudioInfo({
            devices: [],
            error:
              err instanceof Error
                ? err.message
                : 'An error occurred while obtaining audio device permissions',
          })
        })
    }
  }, [isOpen])

  const confirm = useCallback(() => {
    startTranscription()
    requestClose()
  }, [startTranscription, requestClose])

  const actions = useMemo(() => {
    return audioInfo.error
      ? [
          <Button
            key="dismiss-device-selection-error"
            onClick={requestClose}
            variant="contained"
            color="primary"
          >
            Got it
          </Button>,
        ]
      : [
          <Button
            key="cancel-device-selection"
            onClick={requestClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>,
          <Button key="select-device" onClick={confirm} variant="contained" color="primary">
            Continue
          </Button>,
        ]
  }, [audioInfo.error, requestClose, confirm])

  return (
    <Dialog
      open={isOpen}
      dialogTitle={audioInfo.error ? 'Audio Device Error' : 'Select Audio Input'}
      content={
        <div>
          <ContentBlock variant="body1">
            {audioInfo.error.length > 0
              ? 'An error occurred while obtaining permissions for your audio device. Please ensure that you have granted permissions to use your audio device.'
              : 'Select an audio input device to use for transcription.'}
          </ContentBlock>
          {audioInfo.error.length === 0 ? (
            <FormControl fullWidth>
              <InputLabel id="transcription-audio-device-selection-label">Device</InputLabel>
              <Select
                labelId="transcription-audio-device-selection-label"
                id="transcription-audio-device-selection"
                value={audioDeviceId}
                label="Device"
                onChange={(e) => {
                  setAudioDeviceId(e.target.value || '')
                }}
              >
                {audioInfo.devices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>
      }
      actions={actions}
    />
  )
}
