import { GridValidRowModel } from '@mui/x-data-grid'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { DataTable } from 'packs/main/components/DataTable/DataTable'
import React, { useCallback } from 'react'

import { useRequestClientConfiguration } from '../RequestClientConfigurationContext'

export const RequestClientParams: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isPlayback = usePadConfigValue('isPlayback')
  const { path, setPath, paramsData } = useRequestClientConfiguration()

  /**
   * Override the default onRowEdit to update the path with the new params.
   */
  const onRowEdit = useCallback(
    (newRow: GridValidRowModel) => {
      const params = paramsData.rows.reduce((acc, row) => {
        // If this is the row being edited, use the new value
        if (row.id === newRow.id) {
          acc[newRow.name] = newRow.value
        } else {
          acc[row.name] = row.value
        }
        return acc
      }, {} as Record<string, string>)
      const basePath = path.split('?')[0]
      const queryString = Object.entries(params)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      setPath(`${basePath}?${queryString}`, false)
      return paramsData.onRowEdit(newRow)
    },
    [paramsData, path, setPath]
  )

  const onDeleteRow = useCallback(
    (rowToDelete: GridValidRowModel) => {
      const params = paramsData.rows.reduce((acc, row) => {
        if (rowToDelete.id !== row.id) {
          acc[row.name] = row.value
        }
        return acc
      }, {} as Record<string, string>)
      const basePath = path.split('?')[0]
      const queryString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      setPath(queryString ? `${basePath}?${queryString}` : basePath, false)
      return paramsData.handleDeleteRow(rowToDelete)
    },
    [paramsData, path, setPath]
  )

  return (
    <DataTable
      rows={paramsData.rows}
      columns={paramsData.columns}
      onAddRow={paramsData.handleAddRow}
      onDeleteRow={onDeleteRow}
      onRowEdit={onRowEdit}
      readOnly={isPlayback}
    />
  )
}
