import { useEffect } from 'react'

export const BeamerCredentialless: React.FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined

    /**
     * Searches for the Beamer overlay and sets `credentialless="true"` on all iframes.
     * @returns {boolean} - Returns true if the overlay was found and modified, otherwise false.
     */
    const initializeBeamerIframes = (): boolean => {
      const beamerOverlay = document.getElementById('beamerOverlay')
      if (beamerOverlay) {
        const iframes = beamerOverlay.getElementsByTagName('iframe')
        for (let i = 0; i < iframes.length; i++) {
          iframes[i].setAttribute('credentialless', 'true')
          // force reload beamer to apply the credentialless attribute
          const beamerSrc = iframes[i].src
          iframes[i].src = ''
          iframes[i].src = beamerSrc
        }
        return true
      }
      return false
    }

    /**
     * Starts polling the DOM every 500ms to find the Beamer overlay.
     */
    const startPolling = () => {
      pollingInterval = setInterval(() => {
        if (initializeBeamerIframes()) {
          clearInterval(pollingInterval)
        }
      }, 500)
    }

    /**
     * Attempts to initialize via event listeners or starts polling if necessary.
     */
    const tryInitializeOnEvent = () => {
      if (!initializeBeamerIframes()) {
        startPolling()
      }
    }

    tryInitializeOnEvent()

    return () => {
      if (pollingInterval) clearInterval(pollingInterval)
    }
  }, [])

  return null
}
