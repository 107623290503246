import {
  Box,
  ButtonBase,
  css,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import LanguageIcon from 'packs/main/Monaco/FilePane/LanguageIcon'
import React, { MouseEventHandler, useMemo } from 'react'

import { ReactComponent as AddBtn } from '../../../../../assets/images/addBtn.svg'
import { ReactComponent as Drawing } from '../../../../../assets/images/drawing.svg'
import { ReactComponent as FocusTimeIcon } from '../../../../../assets/images/focus_time_icon.svg'
import { ReactComponent as Languages } from '../../../../../assets/images/Languages.svg'
import { ReactComponent as Questions } from '../../../../../assets/images/Questions.svg'

export const SELECTOR_ITEM_SIZE = 80

const iconBase = css({
  maxHeight: 24,
  maxWidth: 24,
})

const StyledLanguageIcon = styled(LanguageIcon)({
  ...iconBase,
})

interface ISelectorItemProps {
  iconName: string
  isActive?: boolean
  label?: string
  onClick?: () => void
  onMouseEnter?: MouseEventHandler<HTMLButtonElement> | undefined
  onMouseLeave?: MouseEventHandler<HTMLButtonElement> | undefined
  hint?: React.ReactNode | string
  pulse?: boolean
}

const ItemButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  height: SELECTOR_ITEM_SIZE,
  width: SELECTOR_ITEM_SIZE,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: active ? theme.palette.editor?.sidebar.active : 'transparent',
  overflow: 'hidden',
  paddingRight: 4,
  paddingLeft: 4,
  '&:hover': {
    backgroundColor: theme.palette.editor?.sidebar.hover,
  },
}))

const Hint = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3F434B',
    marginLeft: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3F434B',
    marginLeft: '7px !important',
    borderRadius: '4px',
  },
}))

const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: '0.75rem',
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
}))

export const SelectorItem: React.FC<React.PropsWithChildren<ISelectorItemProps>> = ({
  iconName,
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isActive,
  hint,
  pulse,
}) => {
  const commandIcon = useMemo(
    () =>
      ({
        add: <AddBtn />,
        drawing: <Drawing />,
        focus: <FocusTimeIcon />,
        questions: <Questions />,
        languages: <Languages />,
      }[iconName]),
    [iconName]
  )

  const itemBtn = (
    <ItemButton
      active={isActive}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={`selectoritem-${iconName}`}
      sx={{
        '& svg': pulse
          ? {
              '@keyframes pulsate': {
                '0%': {
                  transform: 'scale(1)',
                },
                '25%': {
                  transform: 'scale(1.2)',
                },
                '75%': {
                  transform: 'scale(1)',
                },
              },
              animation: 'pulsate 2s infinite',
            }
          : {},
      }}
    >
      {commandIcon ? (
        <Box
          sx={(theme) => ({
            '& *': { fill: 'currentColor' },
          })}
        >
          {commandIcon}
        </Box>
      ) : (
        <StyledLanguageIcon language={iconName} id={`environment-selector-${iconName}`} />
      )}
      {label && (
        <Box
          mt={0.75}
          data-testid="environmentselector-item"
          textOverflow="ellipsis"
          width="100%"
          overflow="hidden"
        >
          <Label>{label}</Label>
        </Box>
      )}
    </ItemButton>
  )

  return hint ? (
    <Hint title={<>{hint}</>} arrow placement="right">
      {itemBtn}
    </Hint>
  ) : (
    itemBtn
  )
}
