import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Button, styled, Typography } from '@mui/material'
import React, { useCallback } from 'react'

import { useTranscriberContext } from './TranscriberContext'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}))

export function AutoStopWarning({
  isOpen,
  requestClose,
}: {
  isOpen: boolean
  requestClose: () => void
}) {
  const { resetTranscriptionLimit } = useTranscriberContext()

  const reset = useCallback(() => {
    resetTranscriptionLimit()
    requestClose()
  }, [resetTranscriptionLimit, requestClose])

  return (
    <Dialog
      open={isOpen}
      dialogTitle="Need More Transcription?"
      content={
        <div>
          <ContentBlock variant="body1">
            Your transcription will automatically end in 5 minutes. Do you need more time?
          </ContentBlock>
        </div>
      }
      actions={[
        <Button key="expire-transcribing" onClick={requestClose} variant="outlined" color="inherit">
          I'm done
        </Button>,
        <Button key="extend-transcribing" onClick={reset} variant="contained" color="primary">
          Keep Transcribing
        </Button>,
      ]}
    />
  )
}
