import '@codingame/monaco-editor-wrapper/features/cssContribution'
import '@codingame/monaco-editor-wrapper/features/htmlContribution'
import '@codingame/monaco-editor-wrapper/features/jsonContribution'

import Monaco, { useThemeColor } from '@codingame/monaco-editor-react'
import { monaco } from '@codingame/monaco-editor-wrapper'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../dashboard/components/PadContext/PadContext'
import { addGlobalEvent } from '../playback/GlobalEvents/addGlobalEvent'
import { PastedContentGlobalEvent } from '../playback/GlobalEvents/GlobalEventTypes'
import SpinnerPulse from '../spinner_pulse'
import { useMonacoContext } from './MonacoContext'
import { LegacyToMonacoKeyBindingMode } from './MonacoKeybindings'

/**
 * It's an internal type of vscode/monaco which is not exported
 */
interface PastePayload {
  text: string
  pasteOnNewLine: boolean
  multicursorText: string[] | null
  mode: string | null
}

function isPasteAction(handlerId: string, payload: unknown): payload is PastePayload {
  return handlerId === 'paste'
}
/**
 * This hack is required because monaco keeps tabs in pasted content while codemirror always transform them to spaces
 * It produces desync in firepad
 * ** Remove me as soon as codemirror is removed **
 */
function hackEditorPaste(editor: monaco.editor.IStandaloneCodeEditor) {
  const originalTrigger = editor.trigger
  editor.trigger = function (source, handlerId, payload) {
    if (isPasteAction(handlerId, payload)) {
      const model = editor.getModel()
      if (model != null) {
        const tabSize = model.getOptions().tabSize
        payload.text = payload.text.replace(/\t/g, ' '.repeat(tabSize))
      }
    }
    return originalTrigger.call(editor, source, handlerId, payload)
  }
}

const EditorPane = () => {
  const [lastCopy, setLastCopy] = useState<string>()
  const { getEditor, setEditor, firepadReady } = useMonacoContext()
  const editorBackground = useThemeColor('editor.background')
  const editorForeground = useThemeColor('editor.foreground')
  const loadingOverlayStyle = useMemo(
    () => ({
      backgroundColor: editorBackground,
      color: editorForeground,
    }),
    [editorBackground, editorForeground]
  )

  const editorRef = (editor: monaco.editor.IStandaloneCodeEditor) => {
    window.editor = editor
    hackEditorPaste(editor)
    setEditor(editor)
  }

  const keyBindingsMode = useSelector(({ editorSettings }) => editorSettings.keymap)
  const { firebaseAuthorId, isOwner, takeHome, padOrg, isPlayback } = usePadConfigValues(
    'firebaseAuthorId',
    'isOwner',
    'takeHome',
    'padOrg',
    'isPlayback'
  )
  const { takeHomeOpened } = useSelector((state) => state.padSettings)

  const trackPaste = padOrg?.tsaPastePlaybackEnabled && !isPlayback

  const rememberLastCopy = () => {
    setLastCopy(document.getSelection()?.toString())
  }

  const detectExternalPaste = useCallback(
    (e: React.ClipboardEvent) => {
      if (!trackPaste) return
      // strip out all whitespace characters from all 3 strings before comparing
      const pastedContent = e.clipboardData.getData('text').replace(/\s/g, () => '')
      const lastCopyContent = lastCopy?.replace(/\s/g, () => '')
      const editor = getEditor()
      const editorText = editor
        ?.getModel()
        ?.getValue()
        ?.replace(/\s/g, () => '')
      if (pastedContent !== lastCopyContent && !editorText?.includes(pastedContent)) {
        addGlobalEvent<PastedContentGlobalEvent>({
          type: 'pasted-content',
          user: firebaseAuthorId,
          data: {
            content: pastedContent,
          },
        })
      }
    },
    [trackPaste, getEditor, lastCopy, firebaseAuthorId]
  )

  return (
    <div
      className="monaco-holder"
      onCopy={rememberLastCopy}
      onCut={rememberLastCopy}
      onPasteCapture={detectExternalPaste}
    >
      {!firepadReady && (
        <div className="monaco-loading-overlay" style={loadingOverlayStyle}>
          <SpinnerPulse />
        </div>
      )}
      {(isOwner || !takeHome || takeHomeOpened) && (
        <Monaco
          options={{ unusualLineTerminators: 'off' }}
          ref={editorRef}
          keyBindingsMode={LegacyToMonacoKeyBindingMode[keyBindingsMode] ?? 'classic'}
        />
      )}
    </div>
  )
}

export default EditorPane
