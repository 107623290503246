import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'

import * as queryStates from '../../../../graphql/queryStates'
import { PadAnalyticsEvent } from '../../constants/PadAnalyticsEvent'
import { EnvironmentSummary } from '../../Environments/EnvironmentsContext/types'
import trackEvent from '../../track_event'
import { useCreateQuestionFromEnvironment } from './actions'

interface CreateQuestionDialogProps {
  environment: EnvironmentSummary | null
  onRequestClose: () => void
  hasDrawingBoardId: boolean
}

export const CreateQuestionDialog: React.FC<React.PropsWithChildren<CreateQuestionDialogProps>> = ({
  environment,
  onRequestClose,
  hasDrawingBoardId,
}) => {
  const [saveDrawing, setSaveDrawing] = useState(true)
  const { createQuestion, status } = useCreateQuestionFromEnvironment(environment, saveDrawing)

  const isCreating = queryStates.isLoadingState(status)

  const isOpen = environment != null

  const handleCreate = useCallback(async () => {
    trackEvent(PadAnalyticsEvent.CreateQuestionFromTabAffirmation)

    const newQuestionId = await createQuestion()

    // Close the dialog if the question creation was successful.
    if (newQuestionId) {
      onRequestClose()
    }
  }, [createQuestion, onRequestClose])

  const handleClose = useCallback(() => {
    trackEvent(PadAnalyticsEvent.CreateQuestionFromTabCancel)
    onRequestClose()
  }, [onRequestClose])

  return (
    <Dialog open={isOpen}>
      {isOpen && (
        <>
          <DialogTitle variant="h2" component="div">
            Save as draft
          </DialogTitle>
          <DialogContent>
            <Collapse in={queryStates.isErrorState(status)}>
              <Alert severity="error" sx={{ mb: (theme) => theme.spacing(1) }}>
                {status[1] || 'Unexpected error creating question.'}
              </Alert>
            </Collapse>
            <Typography variant="body1">
              A snapshot of the code in this <strong>{environment?.nameDisplayable}</strong> pad
              will be saved as a draft question.
            </Typography>
            <Typography variant="body1" mt={2}>
              The draft editor will open in a new window. You can finish editing, add instructions
              and more, and finalize your question from the drafts tab.
            </Typography>
            {hasDrawingBoardId && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveDrawing}
                    onChange={() => {
                      setSaveDrawing(!saveDrawing)
                    }}
                  />
                }
                label="Include Drawing"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreate}
              disabled={isCreating}
              endIcon={isCreating ? <CircularProgress size={24} color="inherit" /> : null}
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
