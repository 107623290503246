import {
  ChevronDown,
  Lock,
  PersonApprove,
} from '@codinpad/shared-components/components/icons/index.js'
import {
  Box,
  Button as MuiButton,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { FC, useEffect, useMemo, useState } from 'react'

import SyncHandle from '../main/sync_handle'
import PadBanner from './pad_banner'

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '49px',
  borderBottom: `2px solid ${theme.palette.grey[700]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
}))

const Button = styled(MuiButton)(({ theme }) => {
  const isLight = theme.palette.mode === 'light' ? '' : '#fff'

  return {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
    height: '30px',
    marginLeft: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${isLight}`,
    },
  }
})

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  fontSize: '0.875rem',
  color: '#fff',
}))

const styleIcon = (component: FC<React.PropsWithChildren<unknown>>) => {
  return styled(component)(({ theme }) => ({
    width: 20,
    height: 20,
    fill: theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[700],
    marginRight: theme.spacing(1),
  }))
}

const LockIcon = styleIcon(Lock)
const PersonApproveIcon = styleIcon(PersonApprove)

interface PrivacyBannerProps {
  onMakePublic: () => void
  visible: boolean
}

interface WaitingRoomUsers {
  inWaitingRoom: boolean
}

export const PrivacyBanner = ({ onMakePublic, visible }: PrivacyBannerProps) => {
  const [candidates, setCandidates] = useState<string[]>([])

  useEffect(() => {
    SyncHandle().watch('waitingRoomUsers', (users: WaitingRoomUsers) => {
      if (users) {
        const candidatesInWaitingRoom = Object.values(users)
          .filter((user) => user.inWaitingRoom)
          .map((user) => user.name)

        setCandidates(candidatesInWaitingRoom)
      }
    })
  }, [])

  const status = useMemo(() => {
    const initialCandidateName = candidates[0]
    const fontSize = '0.875rem'

    /*
      When candidates are in the waiting room, regardless of light/dark mode:
      - background color should be blue
      - icon/text should be white
    */
    const commonStatusProps = {
      bgColor: candidates.length ? '#173CA5' : '',
      btnText: 'Admit',
      icon: <PersonApproveIcon sx={{ fill: '#fff' }} />,
      isWhite: candidates.length ? '#fff' : '',
    }

    // Multiple candidates in the waiting room
    if (candidates.length > 1) {
      const remainingCandidates = candidates.slice(1)

      return {
        ...commonStatusProps,
        description: (
          <Typography fontSize={fontSize}>
            {initialCandidateName} +{remainingCandidates.length} more
            <Tooltip
              title={
                <Stack spacing={2} p={1}>
                  {remainingCandidates.map((name, index) => (
                    <Item key={index} elevation={0}>
                      {name}
                    </Item>
                  ))}
                </Stack>
              }
              placement="bottom"
            >
              <IconButton disableRipple>
                <ChevronDown sx={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
            have joined the waiting room. To let the candidates join the pad:
          </Typography>
        ),
      }
    }

    // Single candidate in the waiting room
    if (candidates.length === 1) {
      return {
        ...commonStatusProps,
        description: (
          <Typography fontSize={fontSize}>
            {`${initialCandidateName} has joined the waiting room. To let them join the pad:`}
          </Typography>
        ),
      }
    }

    // No one in the waiting room
    return {
      ...commonStatusProps,
      icon: <LockIcon />,
      btnText: 'Open Pad to everyone',
      description: (
        <Typography fontSize={fontSize}>
          The waiting room is enabled. We will notify you when a candidate joins the waiting room.
        </Typography>
      ),
    }
  }, [candidates])

  return (
    <PadBanner delayAppearanceSeconds={0.1} visible={visible}>
      <ContentWrapper sx={{ backgroundColor: status.bgColor, color: status.isWhite }}>
        {status.icon}
        {status.description}
        <Tooltip
          title="The waiting room is where candidates wait before being admitted to the pad by the interviewer."
          placement="bottom"
          arrow
        >
          <Button
            variant="outlined"
            onClick={onMakePublic}
            sx={{
              color: status.isWhite,
              border: `1px solid ${status.isWhite}`,
            }}
          >
            {status.btnText}
          </Button>
        </Tooltip>
      </ContentWrapper>
    </PadBanner>
  )
}
