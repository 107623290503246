import MicIcon from '@mui/icons-material/Mic'
import React, { useEffect, useRef } from 'react'

import { useTranscriberContext } from '../../Transcriber/TranscriberContext/TranscriberContext'

export function SoundIndicatorIcon() {
  const { audioAnalyser } = useTranscriberContext()
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const svgElement = svgRef.current
    let animateFrameId: number
    if (svgElement != null && audioAnalyser != null) {
      const frequencyData = new Uint8Array(audioAnalyser.frequencyBinCount)

      const animate = () => {
        audioAnalyser.getByteFrequencyData(frequencyData)

        const averageFrequency =
          frequencyData.reduce((sum, value) => sum + value, 0) / frequencyData.length
        const colorValue = Math.min(255, Math.max(100, Math.floor(averageFrequency * 2.5)))

        svgElement.style.fill = `rgb(0, ${colorValue}, 0)`

        animateFrameId = requestAnimationFrame(animate)
      }

      animate()
    }

    return () => {
      cancelAnimationFrame(animateFrameId)
    }
  }, [audioAnalyser])

  return <MicIcon ref={svgRef} />
}
