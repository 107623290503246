import { Flag as SuspiciousBehaviorIcon } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Stack } from '@mui/material'
import { PadSummary, PadSummaryParticipant } from 'graphql/types'
import React, { FC } from 'react'
import { pluralize } from 'utils/i18n'

import { displayDuration } from '../utils'
import {
  SectionTitle,
  SummaryTableCell,
  SummaryTableCellEmphasized,
  SummaryTableRow,
} from './StyledComponents'

export const SuspiciousBehavior: FC<
  React.PropsWithChildren<{
    padSummary: PadSummary
    candidates: PadSummaryParticipant[]
  }>
> = ({ padSummary, candidates }) => {
  if (padSummary.drawingOnly) {
    return null
  }

  return (
    <>
      <SummaryTableRow>
        <SectionTitle>
          <Stack direction="row" spacing={1}>
            <Box pt={0.3}>
              <SuspiciousBehaviorIcon sx={{ width: 14, height: 14 }} />
            </Box>
            <Box>Suspicious Behavior</Box>
          </Stack>
        </SectionTitle>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>Candidate time outside pad:</SummaryTableCellEmphasized>
        <SummaryTableCell>
          {candidates.map((p, i) => (
            <Box key={i}>
              {candidates.length > 1 && (
                <>
                  <b>{p.name}</b> -{' '}
                </>
              )}
              {p.outsidePadEvents > 0 ? (
                <>
                  {p.outsidePadEvents.toLocaleString()} left pad{' '}
                  {pluralize('event', p.outsidePadEvents)}, total{' '}
                  {displayDuration(p.outsidePadTime!)} outside the pad
                </>
              ) : (
                <>Candidate did not leave the pad</>
              )}
            </Box>
          ))}
        </SummaryTableCell>
      </SummaryTableRow>
      <SummaryTableRow>
        <SummaryTableCellEmphasized>Code pasted from external source:</SummaryTableCellEmphasized>
        <SummaryTableCell>
          {candidates.map((p, i) => (
            <Box key={i}>
              {candidates.length > 1 && (
                <>
                  <b>{p.name}</b> -{' '}
                </>
              )}
              {p.externalPasteEvents > 0 ? (
                <>
                  {p.externalPasteEvents.toLocaleString()}{' '}
                  {pluralize('paste', p.externalPasteEvents)},{' '}
                  {(p.externalPasteChars ?? 0).toLocaleString()}{' '}
                  {pluralize('character', p.externalPasteChars ?? 0)} pasted
                </>
              ) : (
                <>No code was pasted from external sources</>
              )}
            </Box>
          ))}
        </SummaryTableCell>
      </SummaryTableRow>
    </>
  )
}
