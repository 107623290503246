import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'

import { CandidateInstructions } from '../../../../../graphql/types'
import { useActiveEnvironment } from '../../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import SyncHandle from '../../../sync_handle'
import { InstructionDetails } from './InstructionDetails'
import { InstructionsContainer } from './InstructionsContainer'
import { InstructionStepAccordion } from './InstructionStepAccordion'
import { useCandidateInstructions } from './useCandidateInstructions'

export const CandidateInstructionsInterviewer = ({
  hidden,
  candidateInstructions,
}: {
  hidden: boolean
  candidateInstructions: CandidateInstructions[]
}) => {
  const { candidateVisibilitySettings, expanded, handleExpand } = useCandidateInstructions(
    candidateInstructions
  )
  const { environment } = useActiveEnvironment()

  const handleVisibilitySwitchChange = useCallback(
    (checked: any, index: any) => {
      if (environment == null) {
        return
      }

      SyncHandle().set(`environmentCandidateInstructions/${environment.slug}/${index}`, checked)
    },
    [environment]
  )

  if (hidden) {
    return null
  }

  return (
    <InstructionsContainer>
      {candidateInstructions.map((step: CandidateInstructions, index: number) => {
        return (
          <InstructionStepAccordion
            key={index}
            expanded={expanded[index] || false}
            handleExpand={() => handleExpand(index)}
          >
            {candidateInstructions.length === 1 ? (
              <Box mt={2} />
            ) : (
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={(theme) => ({
                  flexDirection: 'row-reverse',
                  minHeight: theme.spacing(6),
                  '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                    marginLeft: theme.spacing(1),
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    marginLeft: theme.spacing(1),
                    marginTop: theme.spacing(1.5),
                    marginBottom: theme.spacing(1.5),
                  },
                  '&.MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: theme.spacing(6),
                  },
                })}
              >
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    lineHeight: '2em',
                    color: candidateVisibilitySettings[index]
                      ? theme.palette.accordion.active
                      : theme.palette.accordion.inactive,
                  })}
                >
                  Instructions Part {index + 1}
                </Typography>
                {index > 0 && (
                  <FormGroup>
                    <FormControlLabel
                      label={`${
                        candidateVisibilitySettings[index] ? 'Visible' : 'Not visible'
                      } to candidate`}
                      labelPlacement="start"
                      sx={(theme) => ({
                        marginBottom: 0,
                        color: candidateVisibilitySettings[index]
                          ? theme.palette.accordion.active
                          : theme.palette.accordion.inactive,
                      })}
                      control={
                        <Switch
                          checked={
                            candidateVisibilitySettings[index] == null
                              ? false
                              : candidateVisibilitySettings[index]
                          }
                          onChange={(e) => handleVisibilitySwitchChange(e.target.checked, index)}
                          size="small"
                          data-testid="instructions-visibility-switch"
                          sx={(theme) => ({
                            '& .MuiSwitch-switchBase': {
                              '&.Mui-checked': {
                                color: theme.palette.padSwitch?.thumb?.color,
                                '& + .MuiSwitch-track': {
                                  backgroundColor: theme.palette.padSwitch?.track?.color,
                                  opacity: 1,
                                },
                              },
                            },
                          })}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </AccordionSummary>
            )}

            <InstructionDetails hidden={hidden} instructions={step.instructions || ''} />
          </InstructionStepAccordion>
        )
      })}
    </InstructionsContainer>
  )
}
