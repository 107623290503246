import {
  LogoOutlined as InterviewDetailsIcon,
  User as CandidateIcon,
} from '@codinpad/shared-components/components/icons/index.js'
import { Box, Stack } from '@mui/material'
import { PadSummary } from 'graphql/types'
import React, { FC, useMemo } from 'react'

import { CodeAndQuestions } from './components/CodeAndQuestions'
import { OtherInfo } from './components/OtherInfo'
import {
  SectionTitle,
  SummaryTable,
  SummaryTableCell,
  SummaryTableCellEmphasized,
  SummaryTableContainer,
  SummaryTableRow,
} from './components/StyledComponents'
import { SuspiciousBehavior } from './components/SuspiciousBehavior'
import { displayDate, displayDurationBetweenTimes } from './utils'

interface TakeHomePadSummaryProps {
  padSummary: PadSummary
}

export const TakeHomePadSummary: FC<React.PropsWithChildren<TakeHomePadSummaryProps>> = ({
  padSummary,
}) => {
  const candidates = useMemo(() => {
    if (padSummary == null) {
      return []
    }
    return padSummary.participants.filter((p) => !p.isInterviewer)
  }, [padSummary])

  if (padSummary == null) {
    return null
  }

  return (
    <SummaryTableContainer>
      <SummaryTable size="small">
        <SummaryTableRow>
          <SectionTitle>
            <Stack direction="row" spacing={1}>
              <Box pt={0.3}>
                <InterviewDetailsIcon sx={{ width: 14, height: 14 }} />
              </Box>
              <Box>Take-home Details</Box>
            </Stack>
          </SectionTitle>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Take-home started:</SummaryTableCellEmphasized>
          <SummaryTableCell>{displayDate(padSummary.interviewStartedAt)}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Take-home ended:</SummaryTableCellEmphasized>
          <SummaryTableCell>{displayDate(padSummary.interviewEndedAt)}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Pad title:</SummaryTableCellEmphasized>
          <SummaryTableCell>{padSummary.padTitle}</SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Time spent in pad:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {displayDurationBetweenTimes(
              padSummary.interviewStartedAt,
              padSummary.interviewEndedAt
            )}
          </SummaryTableCell>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Code submitted:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {padSummary.takeHomeAutoSubmitted ? 'Timer ran out' : 'By candidate'}
          </SummaryTableCell>
        </SummaryTableRow>

        <SummaryTableRow>
          <SectionTitle>
            <Stack direction="row" spacing={1}>
              <Box pt={0.3}>
                <CandidateIcon />
              </Box>
              <Box>Candidate</Box>
            </Stack>
          </SectionTitle>
        </SummaryTableRow>
        <SummaryTableRow>
          <SummaryTableCellEmphasized>Candidate info:</SummaryTableCellEmphasized>
          <SummaryTableCell>
            {candidates.map((p, i) => (
              <Box key={i}>
                <b>{p.name}</b> joined {displayDate(p.joinedAt)}
              </Box>
            ))}
          </SummaryTableCell>
        </SummaryTableRow>

        <CodeAndQuestions padSummary={padSummary} />
        <SuspiciousBehavior padSummary={padSummary} candidates={candidates} />
        <OtherInfo padSummary={padSummary} />
      </SummaryTable>
    </SummaryTableContainer>
  )
}
