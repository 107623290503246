import { Box, List, ListItem, styled, SvgIcon, Typography } from '@mui/material'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { FC } from 'react'

import { ReactComponent as GptLogo } from '../../../../../../assets/images/gpt.svg'

const Wrapper = styled(ScrollView)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.mode === 'dark' ? '#fff' : '#34495e',
  opacity: 0.5,
  marginTop: theme.spacing(2),
}))

// This is kind of a hack to get the content to be vertically centered while still
// overflowing and scrolling correctly
const VerticalCenter = styled(Box)({
  margin: 'auto 0',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

const ListWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  width: '100%',
}))

export const EmptyState: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <VerticalCenter>
        <SvgIcon sx={{ width: '96px', height: '96px' }} inheritViewBox component={GptLogo} />
        <ListWrapper>
          <List dense>
            <ListItem>
              <Typography variant="h2" color="inherit">
                Ask ChatGPT...
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>What is the syntax for a while loop in Javascript?</Typography>
            </ListItem>
            <ListItem>
              <Typography>How do I write a goroutine in Go?</Typography>
            </ListItem>
            <ListItem>
              <Typography>How do I write a functional component in React?</Typography>
            </ListItem>
            <ListItem sx={{ marginTop: 2 }}>
              <Typography variant="h3" color="inherit">
                Limitations
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                May occasionally generate incorrect information
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Limited knowledge of world and events after 2023
              </Typography>
            </ListItem>
          </List>
        </ListWrapper>
      </VerticalCenter>
    </Wrapper>
  )
}

export default EmptyState
