import {
  ClosedCaption,
  ClosedCaptionOff,
  CloseFullscreen,
  Mic,
  MicOff,
  OpenInFull,
  Videocam,
  VideocamOff,
} from '@mui/icons-material'
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  IconButton as MuiIconButton,
  Paper,
  Stack,
  styled,
  useTheme,
} from '@mui/material'
import { clamp } from 'lodash'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import { PlaybackParticipants } from 'packs/main/playback/types'
import { selectCallMaximized, selectLocalUsername } from 'packs/main/selectors'
import SyncHandle from 'packs/main/sync_handle'
import { MessageRelayType } from 'packs/zoom_room/types'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranscriberContext } from '../../Transcriber/TranscriberContext/TranscriberContext'
import { TwilioCaller } from '../call_root'
import { useVideoActionListeners } from './useVideoActionListeners'
import { useZoomRoomRelay } from './ZoomRoomRelay'

interface DragPosition {
  left?: number
  right?: number
  top?: number
  bottom?: number
}

interface DynamicCallProps {
  endCall: () => void
  minimize: () => void
  maximize: () => void
  toggleCC: () => void
  ccEnabled: boolean
  callers: TwilioCaller[]
  muteVideo: () => void
  muteAudio: () => void
  unmuteVideo: () => void
  unmuteAudio: () => void
  videoMuted: boolean
  audioMuted: boolean
  networkQualityLevel: number
}

const ControlButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'maximized' })<{
  maximized?: boolean
}>(({ maximized, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:not(:last-child)': {
    marginRight: theme.spacing(0.5),
  },

  ...(maximized
    ? {
        height: 60,
        width: 60,
      }
    : {}),
}))

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1),
}))

const IFrame = styled('iframe')({
  flex: '1 1 auto',
  border: 'none',
  width: '100%',
  minHeight: 100,
})

const Wrapper = styled('div')<{ maximized?: boolean }>(({ theme, maximized }) => ({
  position: 'absolute',
  overflow: 'hidden',
  userSelect: 'none',
  zIndex: 100,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',

  ...(maximized
    ? {
        width: '80%',
        height: '80%',
        top: 'calc(50% - 40%)',
        left: 'calc(50% - 40%)',
      }
    : {}),
}))

const MINIMIZED_WIDTH = 280
const MINIMIZED_HEIGHT_PER_PARTICIPANT = MINIMIZED_WIDTH * (9 / 16)
const CONTROL_PADDING_MINIMIZED = 84
const CONTROL_PADDING_MAXIMIZED = 120

const DEFAULT_POSITION = {
  right: 15,
  bottom: 66,
}
const MIN_MARGIN = 10

export const DynamicCall: FC<React.PropsWithChildren<DynamicCallProps>> = ({
  endCall,
  minimize,
  maximize,
  toggleCC,
  ccEnabled,
  audioMuted,
  videoMuted,
}) => {
  const zoomRelay = useZoomRoomRelay()
  const maximized = useSelector(selectCallMaximized)
  const { slug, hasTranscriptions } = usePadConfigValues('hasTranscriptions', 'slug')
  const { audioDeviceId, videoDeviceId } = useSelector((state) => state.call)
  const username = useSelector(selectLocalUsername)
  const { isTranscribing } = useTranscriberContext()

  const themeMode = useTheme().palette.mode
  useEffect(() => {
    zoomRelay.sendMessage({ type: MessageRelayType.Theme, theme: themeMode })
  }, [themeMode, zoomRelay])
  const themeModeRef = useRef(themeMode)

  const {
    isConnected,
    handleAudioMute,
    handleVideoMute,
    isUpdatingAudioMuteStatus,
    isUpdatingVideoMuteStatus,
  } = useVideoActionListeners()

  const dispatch = useDispatch()

  const CONTROL_PADDING = maximized ? CONTROL_PADDING_MAXIMIZED : CONTROL_PADDING_MINIMIZED

  const [participants, setParticipants] = useState<PlaybackParticipants[]>([])

  useEffect(() => {
    const unsubParticipantsUpdated = zoomRelay.onMessage(
      MessageRelayType.ParticipantsUpdated,
      (message) => {
        setParticipants(message.data.participants ?? [])
        dispatch({ type: 'zoom_participants_updated', participants: message.data.participants })
      }
    )
    const unsubDisconnected = zoomRelay.onMessage(MessageRelayType.SelfDisconnected, () => {
      endCall()
    })

    const unsubRoomClosed = zoomRelay.onMessage(MessageRelayType.RoomClosed, () => {
      SyncHandle().set('zoomRoomId', null)
    })

    return () => {
      unsubParticipantsUpdated()
      unsubDisconnected()
      unsubRoomClosed()
    }
  }, [dispatch, zoomRelay, endCall])

  // Send updated user name to the zoom iframe.
  useEffect(() => {
    zoomRelay.sendMessage({ type: MessageRelayType.SelfUsernameUpdated, name: username })
  }, [username, zoomRelay])
  // Use a ref for this to avoid changing the iframe url params when the username changes. Otherwise the change in url
  // params causes the iframe to reload.
  const usernameRef = useRef(username)

  const handleEndCall = useCallback(() => {
    zoomRelay.sendMessage({ type: MessageRelayType.SelfDisconnect })
  }, [zoomRelay])

  /**
   * Calculates the height based on the number of participants.
   * We know the width, and we know the aspect ratio of the video feed.
   */
  const height = useMemo(() => {
    return (
      Math.ceil(MINIMIZED_HEIGHT_PER_PARTICIPANT * Math.min(participants.length || 1, 4)) +
      CONTROL_PADDING
    )
  }, [CONTROL_PADDING, participants.length])

  // listen for a message from the iframe to know when when the zoom room id is ready
  // the zoom room is unable to communicate directly with firebase due to cross-origin restrictions
  useEffect(() => {
    const listener = (message: MessageEvent) => {
      if (message.data.id) {
        SyncHandle().set('zoomRoomId', message.data.id)
      }
    }

    const unsubId = zoomRelay.onMessage(MessageRelayType.Id, listener)

    return () => {
      unsubId()
    }
  }, [zoomRelay])

  useEffect(() => {
    const unsubAudioInputDevice = zoomRelay.onMessage(
      MessageRelayType.AudioInputDeviceUnavailable,
      () => {
        dispatch({
          type: 'call_maximized',
        })
      }
    )

    return () => {
      unsubAudioInputDevice()
    }
  }, [dispatch, zoomRelay])

  const [position, _setPosition] = useState<DragPosition>(DEFAULT_POSITION)
  const [dragging, setDragging] = useState(false)
  const rootEl = useRef<HTMLDivElement>(null)
  const positionRef = useRef(position)

  const setPosition = (position: DragPosition) => {
    positionRef.current = position
    _setPosition(position)
  }

  let _dragStartPos: DragPosition = {}
  let _dragStartPageX: number = 0
  let _dragStartPageY: number = 0
  let _dragRangeX: number = 0
  let _dragRangeY: number = 0

  const handleDragStart = (evt: React.MouseEvent) => {
    if (!rootEl.current) {
      return
    }

    // Don't trigger if the mousedown bubbled up from the buttons
    // (end call, minimize, mute, hide video)
    const target = evt.target as HTMLElement
    if (target && (target.tagName === 'BUTTON' || target.parentElement?.tagName === 'BUTTON')) {
      return
    }

    document.addEventListener('mousemove', handleDrag, {})
    document.addEventListener('mouseup', handleDragEnd, {})

    setDragging(true)

    _dragStartPos = positionRef.current
    _dragStartPageX = evt.pageX
    _dragStartPageY = evt.pageY

    // This controls the range of allowed values so you can't create scrollbars
    // by dragging the call off the edge of the screen.
    // Client rects are expensive so just measure this once at drag start
    _dragRangeX = window.innerWidth - rootEl.current.clientWidth
    _dragRangeY = window.innerHeight - rootEl.current.clientHeight
    document.body.classList.add('pad--dragging')
  }

  const handleDrag = useCallback(
    (evt: MouseEvent) => {
      const offsetX = evt.pageX - _dragStartPageX
      const offsetY = evt.pageY - _dragStartPageY
      const pos = { ..._dragStartPos }

      if (pos.left !== undefined)
        pos.left = clamp(pos.left + offsetX, MIN_MARGIN, _dragRangeX - MIN_MARGIN)
      else if (pos.right !== undefined)
        pos.right = clamp(pos.right - offsetX, MIN_MARGIN, _dragRangeX - MIN_MARGIN)
      if (pos.top !== undefined)
        pos.top = clamp(pos.top + offsetY, MIN_MARGIN, _dragRangeY - MIN_MARGIN)
      else if (pos.bottom !== undefined)
        pos.bottom = clamp(pos.bottom - offsetY, MIN_MARGIN, _dragRangeY - MIN_MARGIN)

      setPosition(pos)
    },
    [_dragRangeX, _dragRangeY, _dragStartPageX, _dragStartPageY, _dragStartPos]
  )

  const handleDragEnd = useCallback(() => {
    if (!rootEl.current) {
      return
    }

    document.body.classList.remove('pad--dragging')
    document.removeEventListener('mousemove', handleDrag, {})
    document.removeEventListener('mouseup', handleDragEnd, {})

    const elWidth = rootEl.current.clientWidth
    const elHeight = rootEl.current.clientHeight
    const winWidth = window.innerWidth
    const winHeight = window.innerHeight

    const position = positionRef.current

    // Get the left and top, even if we were specifying position as right and bottom
    // let left = position.left !== undefined ? position.left : winWidth - ((position.right ?? 0) + elWidth)
    let left = position.left ?? winWidth - ((position.right ?? 0) + elWidth)
    let top = position.top ?? winHeight - ((position.bottom ?? 0) + elHeight)

    // Re-compute drag range and clamp the positions again, to avoid the video being
    // partially off-screen in the edge case where someone joined the call while
    // the user was dragging.
    const dragRangeX = winWidth - elWidth
    const dragRangeY = winHeight - elHeight
    left = clamp(left, MIN_MARGIN, dragRangeX - MIN_MARGIN)
    top = clamp(top, MIN_MARGIN, dragRangeY - MIN_MARGIN)

    // Compute right and bottom so we have the full set of positions
    const right = winWidth - (left + elWidth)
    const bottom = winHeight - (top + elHeight)

    // Pin the video call to whichever side is closer (top or bottom, left or right).
    //
    // This way if the video call is in one corner of the screen, and you resize
    // your window, it stays in that corner.
    //
    // Also, when the call expands vertically because someone joined, it'll expand
    // in the right direction (downwards if at the top, upwards if at the bottom).
    const pinnedPosition: DragPosition = {}
    if (left < right) pinnedPosition.left = left
    else pinnedPosition.right = right
    if (top < bottom) pinnedPosition.top = top
    else pinnedPosition.bottom = bottom

    setPosition(pinnedPosition)
    setDragging(false)
  }, [handleDrag])

  useEffect(() => {
    return () => {
      if (dragging) {
        document.removeEventListener('mousemove', handleDrag, {})
        document.removeEventListener('mouseup', handleDragEnd, {})
        document.body.classList.remove('pad--dragging')
      }
    }
  }, [dragging, handleDrag, handleDragEnd])

  const styles = {
    width: MINIMIZED_WIDTH,
    height,
    ...position,
    transition: 'height 0.1s, width 0.1s',
  }

  return (
    <>
      <Wrapper
        maximized={maximized}
        ref={rootEl}
        style={maximized ? {} : styles}
        onMouseDown={maximized ? undefined : handleDragStart}
        onMouseUp={maximized ? undefined : handleDragEnd}
      >
        <Paper sx={{ height: '100%', width: '100%' }}>
          <Stack direction="column" height="100%" width="100%">
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.tokens.surface.neutral1,
                display: 'flex',
                justifyContent: 'space-between',
                flex: 'none',
                padding: (theme) =>
                  maximized ? `${theme.spacing(1)} ${theme.spacing(2)}` : theme.spacing(1),
              }}
            >
              <Box>Video Call</Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={maximized ? minimize : maximize}
                  title={maximized ? 'Minimize call window' : 'Maximize call window'}
                  sx={{ margin: 0 }}
                  aria-label={maximized ? 'Minimize call window' : 'Maximize call window'}
                >
                  {maximized ? (
                    <CloseFullscreen fontSize="small" />
                  ) : (
                    <OpenInFull fontSize="small" />
                  )}
                </IconButton>
              </Box>
            </Box>
            <IFrame
              data-testid="zoom-room-iframe"
              key="zoom-room"
              ref={zoomRelay.iframe}
              src={`/${slug}/zoom_room?username=${encodeURIComponent(
                usernameRef.current
              )}&audioInputDeviceId=${encodeURIComponent(
                audioDeviceId ?? 'default'
              )}&videoDeviceId=${encodeURIComponent(
                videoDeviceId ?? 'default'
              )}&audioOutputDeviceId=default&theme=${themeModeRef.current}`}
              allow="camera; microphone; fullscreen"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.tokens.surface.neutral1,
                flex: 'none',
                position: 'relative',
                display: 'flex',
                justifyContent: maximized ? 'center' : 'space-between',
                padding: 1,
                minHeight: maximized ? 60 : undefined,
              }}
            >
              <Box display="flex" justifyContent="center">
                {isConnected && audioDeviceId != null && (
                  <ControlButton
                    onClick={() => handleAudioMute(!audioMuted)}
                    disabled={isUpdatingAudioMuteStatus}
                    maximized={maximized}
                    aria-label={audioMuted ? 'Unmute audio' : 'Mute audio'}
                  >
                    {audioMuted ? <MicOff /> : <Mic />}
                    {maximized && <span>Audio</span>}
                  </ControlButton>
                )}
                {isConnected && videoDeviceId != null && videoDeviceId !== '____no_video' && (
                  <ControlButton
                    onClick={() => handleVideoMute(!videoMuted)}
                    disabled={isUpdatingVideoMuteStatus}
                    maximized={maximized}
                    aria-label={videoMuted ? 'Show video' : 'Hide video'}
                  >
                    {videoMuted ? <VideocamOff /> : <Videocam />}
                    {maximized && <span>Video</span>}
                  </ControlButton>
                )}
                {hasTranscriptions && isTranscribing && (
                  <ControlButton
                    onClick={toggleCC}
                    maximized={maximized}
                    aria-label={ccEnabled ? 'Turn off closed captions' : 'Turn on closed captions'}
                  >
                    {ccEnabled ? <ClosedCaption /> : <ClosedCaptionOff />}
                    {maximized && <span>Captions</span>}
                  </ControlButton>
                )}
              </Box>
              <Box
                flex="none"
                display="flex"
                height="100%"
                alignItems="center"
                top={0}
                sx={
                  maximized
                    ? { position: 'absolute', right: (theme) => theme.spacing(maximized ? 2 : 1) }
                    : {}
                }
              >
                <Button
                  variant="contained"
                  onClick={handleEndCall}
                  className="end-call"
                  color="error"
                  size={maximized ? 'medium' : 'small'}
                >
                  Leave Call
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Wrapper>
      <Backdrop open={maximized} style={{ zIndex: 99 }} onClick={minimize} />
    </>
  )
}
