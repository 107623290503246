import { monaco } from '@codingame/monaco-editor-react'
import { IFile } from 'packs/main/Environments/EnvironmentsContext/types'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LanguageConfiguration } from 'utils'

import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { selectLanguagesAvailable } from '../../selectors'
import { MonacoFile, PadUser } from '../FilePane/utils/types'
import { MonacoProviderContract } from '../MonacoContext'

const emptyObject = {}

function fileToMonacoFile(
  file: IFile,
  firebaseUsers: Record<string, PadUser[]>,
  fileLanguageMeta: LanguageConfiguration
): MonacoFile {
  return {
    name: file.name,
    path: file.path,
    // Included to keep the MonacoFile type definition happy, but not really used in environments.
    isGlobalFile: false,
    fileId: file.id,
    users: firebaseUsers[file.id] ?? [],
    monacoLanguage: fileLanguageMeta.monaco_language,
    isLocked: file.isLocked,
    isImmutable: file.isImmutable || file.path === '.cpad',
  }
}

/**
 * This custom hook is the secret sauce to being able to connect the existing Monaco components to a pad environment.
 * It gets file information from the active environment context and combines that with some information passed in from
 * the environments monaco context provider to build a context value compatible with the contract defined for a
 * "MonacoContext".
 */
export function useMonacoContextValueAdapter(
  firepadReady: boolean,
  firebaseUsers: Record<string, PadUser[]>,
  updateFileUser: (fileId: string, user: PadUser) => void,
  setEditor: (editor: monaco.editor.IStandaloneCodeEditor) => void,
  editor: monaco.editor.IStandaloneCodeEditor | null
): MonacoProviderContract {
  const { activeFile, environment, files, activateFile } = useActiveEnvironment()

  const availableLanguages = useSelector(selectLanguagesAvailable) ?? emptyObject

  const activeLanguageSettings = availableLanguages[environment?.language ?? ''] ?? emptyObject

  // Augment each file with its list of users and monaco_language.
  const monacoFiles = useMemo(() => {
    return files.map((file) => fileToMonacoFile(file, firebaseUsers, activeLanguageSettings))
  }, [files, activeLanguageSettings, firebaseUsers])

  // Augment the active file with its list of users and monaco_language.
  const monacoActiveFile = useMemo(() => {
    return activeFile != null
      ? fileToMonacoFile(activeFile, firebaseUsers, activeLanguageSettings)
      : undefined
  }, [activeFile, activeLanguageSettings, firebaseUsers])

  // The Monaco components pass a whole file to activate. We just need to find the id of the file to use with the
  // environment context.
  const monacoSetActiveFile = useCallback(
    (monacoFile: any) => {
      activateFile(monacoFile.fileId)
    },
    [activateFile]
  )

  const getEditor = useCallback(() => {
    return editor
  }, [editor])

  return {
    files: monacoFiles,
    activeFile: monacoActiveFile,
    activeLanguage: environment?.language ?? '',
    activeLanguageSettings,
    setEditor,
    getEditor,
    createFile: () => null,
    setActiveFile: monacoSetActiveFile,
    // TODO not a thing until we implement questions.
    setSingleFilePadLanguage: () => null,
    updateFileUser,
    firepadReady,
    questionId: environment?.questionId ?? 0,
  }
}
