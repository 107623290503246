import { Box, CircularProgress } from '@mui/material'
import { useEnvironments } from 'packs/main/Environments/EnvironmentsContext/EnvironmentsContext'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useCurrentUser } from '../../../../graphql/hooks'
import { usePadFork } from '../../../../graphql/hooks/pad/usePadFork/usePadFork'
import { isLoadingState } from '../../../../graphql/queryStates'
import { useAnalytics } from '../../../../utils'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { PadAnalyticsEvent, PadTranslation } from '../../constants'
import PadButton from '../../pad_button'
import { enqueueNotif } from '../../reducers/notifications'

export type CreatePadFromSandboxButtonProps = Record<string, never>

export const CreatePadFromSandboxButton: React.FC<
  React.PropsWithChildren<CreatePadFromSandboxButtonProps>
> = () => {
  const { track } = useAnalytics()
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const { isSandbox, sandboxEnvironmentPreviewSlug, slug } = usePadConfigValues(
    'isSandbox',
    'sandboxEnvironmentPreviewSlug',
    'slug'
  )
  const { padFork, status: padForkStatus } = usePadFork()
  const { environments } = useEnvironments()

  const handleClick = useCallback(async () => {
    if (isLoadingState(padForkStatus)) return

    try {
      const { message, pad } = await padFork({
        slug: `${slug}`,
        title: PadTranslation.sandboxCreatePadFromSandboxButtonForkTitle,
      })

      if (!pad?.slug) {
        dispatch(
          enqueueNotif({
            message: message || PadTranslation.sandboxCreatePadFromSandboxButtonForkError,
            key: 'duplication-error',
            variant: 'error',
            autoDismissMs: 5000,
          })
        )
        return
      }

      track(PadAnalyticsEvent.SandboxCreatePadFromSandboxButtonClicked, {
        forkedPadSlug: pad.slug,
      })

      const newWindow = window.open(`/${pad.slug}`)
      if (newWindow) {
        newWindow.openedFromFork = true
      }
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, padFork, padForkStatus, slug, track])

  if (!currentUser || !isSandbox || !!sandboxEnvironmentPreviewSlug || environments.length === 0)
    return null

  return (
    <Box sx={{ display: 'inline', position: 'relative' }}>
      <PadButton
        disabled={isLoadingState(padForkStatus)}
        onClick={handleClick}
        tooltip={{ title: PadTranslation.sandboxCreatePadFromSandboxButtonTooltipText }}
      >
        <Box sx={{ visibility: isLoadingState(padForkStatus) ? 'hidden' : undefined }}>
          {PadTranslation.sandboxCreatePadFromSandboxButtonLabel}
        </Box>
        {isLoadingState(padForkStatus) && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'inline-flex',
              justifyContent: 'center',
              position: 'absolute',
            }}
          >
            <CircularProgress
              color="inherit"
              size={12}
              sx={{ marginTop: -2.25, position: 'absolute' }}
            />
          </Box>
        )}
      </PadButton>
    </Box>
  )
}

export default CreatePadFromSandboxButton
