import { FormControl, Menu, MenuItem, Select } from '@mui/material'
import classNames from 'classnames'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { ChangeEventHandler, useCallback, useMemo, useRef, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { useSelector } from 'react-redux'
import { useFetch } from 'utils/fetch/useFetch'

import { useActiveEnvironment } from './Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import padConfig from './pad_config'
import trackEvent from './track_event'

interface FeedbackSelectOption {
  value: string
  label: string
}

enum CategoryChoiceType {
  Category,
  SubCategories,
  Select,
}

interface FeedbackCategory {
  id: string
  label: string
  type: CategoryChoiceType
  subCategories?: string[]
  selectOptions?: FeedbackSelectOption[]
}

interface PadFeedbackProps {
  onHide: () => void
  anchorEl: null | HTMLElement
}

export default function PadFeedback({ onHide, anchorEl }: PadFeedbackProps) {
  const [submitted, setSubmitted] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<FeedbackCategory | null>(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null)
  const [selectedOption, setSelectedOption] = useState<FeedbackSelectOption | null>(null)
  const [additionalDetails, setAdditionalDetails] = useState('')
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  const keymap = useSelector((state) => state.editorSettings.keymap)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { hasAiChat } = usePadConfigValues('hasAiChat')
  const { environment } = useActiveEnvironment()
  const fetch = useFetch()

  const feedbackCategories: FeedbackCategory[] = useMemo(() => {
    const categories = [
      {
        id: 'video_chat',
        label: 'Issue with video chat',
        type: CategoryChoiceType.SubCategories,
        subCategories: [
          'Can’t establish a connection with the other person at all',
          'Video/audio cuts out or lags more than 1 second',
          'Video chat crashes browser',
        ],
      },
      {
        id: 'code_editing',
        label: 'Issue with writing code/sync',
        type: CategoryChoiceType.SubCategories,
        subCategories: [
          'Page loads, but I can’t type anything in the editor',
          'The code editor seems to be getting out of sync between participants',
          'Connection lost/I stopped seeing the other person’s changes',
        ],
      },
      {
        id: 'code_running',
        label: 'Issue with running my code',
        type: CategoryChoiceType.SubCategories,
        subCategories: [
          'Code does not run when someone clicks “run” button',
          'I see the “Something went wrong” error message when I try to run code',
        ],
      },
      {
        id: 'playback',
        label: 'Issue with playback',
        type: CategoryChoiceType.SubCategories,
        subCategories: [
          'No code is visible in playback mode',
          'Interviewer notes don’t appear with playback',
          'Playback button doesn’t work',
        ],
      },
      {
        id: 'drawing_mode',
        label: 'Issue with drawing mode',
        type: CategoryChoiceType.SubCategories,
        subCategories: [
          'I can’t draw in the drawing pane',
          'Drawing tools are not working correctly',
          'I can’t see other participants drawing',
        ],
      },
      {
        id: 'language',
        label: 'Issue specific to a programming language',
        type: CategoryChoiceType.Select,
        selectOptions: Object.entries(window.CoderPad.LANGUAGES).map(([lang, metadata]) => ({
          value: lang,
          label: metadata.display,
        })),
      },
      {
        id: '2_0_room',
        label: 'Issue with multi-file framework',
        type: CategoryChoiceType.Category,
      },
      {
        id: 'intellisense',
        label: 'Issue with autocomplete/intellisense',
        type: CategoryChoiceType.Category,
      },
    ]

    if (hasAiChat) {
      categories.push({
        id: 'ai_chat',
        label: 'Issue with AI chat',
        type: CategoryChoiceType.Category,
      })
    }

    categories.push({
      id: 'other',
      label: 'Something else',
      type: CategoryChoiceType.Category,
    })

    return categories
  }, [hasAiChat])

  const feedbackCategoryById = useMemo(() => {
    return Object.fromEntries(feedbackCategories.map((category) => [category.id, category]))
  }, [feedbackCategories])

  const isChoiceComplete =
    selectedCategory?.type === CategoryChoiceType.Category ||
    (selectedCategory?.type === CategoryChoiceType.SubCategories && selectedSubCategory != null) ||
    (selectedCategory?.type === CategoryChoiceType.Select && selectedOption != null)

  const onCategoryChange = useCallback(
    (expandedItemIds: string[]) => {
      const categoryId = expandedItemIds[0]
      setSelectedCategory(categoryId != null ? feedbackCategoryById[categoryId] : null)
      setSelectedSubCategory(null)
      setSelectedOption(null)
    },
    [feedbackCategoryById]
  )

  const onSelectOptionChange = useCallback((option: FeedbackSelectOption) => {
    setSelectedOption(option ?? null)
  }, [])

  const onAdditionalDetailsChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => setAdditionalDetails(event.target.value),
    []
  )

  const onEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setEmail(event.target.value)
  }, [])

  const setError = useCallback((status: string) => {
    setStatus(status)
    setImmediate(() => scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight))
  }, [])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!isChoiceComplete || additionalDetails === '') {
      setError("Error: Can't submit an empty form. Please select an option and write a message.")
      return
    }

    const params = {
      browser_info: `; Screen space: ${window.screen.width} x ${window.screen.height}; Screen space, Available: ${window.screen.availWidth} x ${window.screen.availHeight}; Browser window size: ${window.innerWidth} x ${window.innerHeight}; Device pixel ratio ${window.devicePixelRatio}`,
      category: selectedCategory!.label,
      option: selectedSubCategory ?? selectedOption?.label ?? '',
      text: additionalDetails,
      key_bindings: {
        sublime: 'Standard',
        vim: 'Vim',
        emacs: 'Emacs',
      }[keymap],
      email,
      environment: environment?.slug ?? 'unknown',
    }

    const res = await fetch(`/${padConfig.isSandbox ? 'sandbox' : padConfig.slug}/feedback`, {
      method: 'post',
      body: new URLSearchParams(params),
    })
    if (res.ok) {
      setSubmitted(true)
      setStatus('')
      trackEvent('Pad Feedback Sent')
    } else {
      setError(`Unexpected error: ${res.statusText}`)
    }
  }

  const open = Boolean(anchorEl)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onHide}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      MenuListProps={{
        sx: { paddingY: 0 },
      }}
    >
      <form className="PadFeedback" onSubmit={onSubmit}>
        <div className="header">
          Send Feedback <span>(only to CoderPad staff)</span>
        </div>
        {submitted ? (
          <p>Feedback submitted. Thanks for helping us improve CoderPad!</p>
        ) : (
          <div className="body" ref={scrollRef}>
            <Accordion allowZeroExpanded={true} onChange={onCategoryChange}>
              {feedbackCategories.map(({ id, label, type, subCategories, selectOptions }) => (
                <AccordionItem key={id} uuid={id}>
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={classNames('accordion__button', {
                        active: type === CategoryChoiceType.Category && selectedCategory?.id === id,
                      })}
                    >
                      {label}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  {subCategories?.map((subCategory, idx) => (
                    <AccordionItemPanel
                      key={idx}
                      className={classNames('accordion__panel', {
                        active: selectedSubCategory === subCategory,
                      })}
                      onClick={() => setSelectedSubCategory(subCategory)}
                    >
                      {subCategory}
                    </AccordionItemPanel>
                  ))}
                  {selectOptions != null && (
                    <AccordionItemPanel className="accordion__panel">
                      <FormControl variant="standard" fullWidth>
                        <Select
                          value={selectedOption?.value ?? selectOptions[0].value}
                          onChange={(e) =>
                            onSelectOptionChange({
                              label:
                                selectOptions?.find((o) => o.value === e.target.value)?.label ??
                                'language',
                              value: e.target.value,
                            })
                          }
                        >
                          {selectOptions?.map(({ label: optionLabel, value: optionValue }) => (
                            <MenuItem key={optionValue} value={optionValue}>
                              {optionLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </AccordionItemPanel>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
            {isChoiceComplete && (
              <>
                <label htmlFor="feedback-text">Additional details:</label>
                <textarea
                  id="feedback-text"
                  name="feedback-text"
                  value={additionalDetails}
                  onChange={onAdditionalDetailsChange}
                />
              </>
            )}
            {!padConfig.isLoggedIn && (
              <>
                <label htmlFor="feedback-email">Email (optional):</label>
                <input
                  type="email"
                  id="feedback-email"
                  name="feedback-email"
                  value={email}
                  onChange={onEmailChange}
                />
              </>
            )}
            <p>{status}</p>
          </div>
        )}

        <div className="footer">
          {!submitted ? (
            <>
              <a href="#" className="btn" onClick={onHide}>
                Cancel
              </a>
              <input className="btn pad-outline-button" type="submit" value="Send" />
            </>
          ) : (
            <>
              <p>{status}</p>
              <a href="#" className="btn pad-outline-button" onClick={onHide}>
                Dismiss
              </a>
            </>
          )}
        </div>
      </form>
    </Menu>
  )
}
