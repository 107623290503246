import { useQuery } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'

import * as queryStates from '../../queryStates'
import { PadSummary } from '../../types'
import PAD_SUMMARY_QUERY from './PadSummaryQuery.gql'

export interface IUsePadSummaryData {
  padSummary: PadSummary
}

export function usePadSummary(padSlug: string) {
  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery<IUsePadSummaryData>(
    PAD_SUMMARY_QUERY,
    {
      variables: {
        padSlug,
      },
      context: {
        source: 'usePadSummary.ts',
      },
      skip: padSlug == null,
    }
  )

  const pollingTimeout = useRef<NodeJS.Timeout>()
  const [shouldPoll, setShouldPoll] = useState(false)
  const [status, setStatus] = useState(queryStates.initial())

  const handlePolling = useCallback(() => {
    if (startPolling == null || stopPolling == null) {
      return
    }
    if (pollingTimeout.current != null) {
      return
    }

    startPolling(3000)

    // allow 30 seconds of polling before giving up
    pollingTimeout.current = setTimeout(() => {
      stopPolling()
      setStatus(queryStates.error('There was an error while fetching the pad summary.'))
    }, 30000)
  }, [startPolling, stopPolling])

  const clearPollingTimeout = useCallback(() => {
    if (pollingTimeout.current != null) {
      clearTimeout(pollingTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (shouldPoll) {
      handlePolling()
    }

    return () => {
      clearPollingTimeout()
    }
  }, [clearPollingTimeout, handlePolling, shouldPoll])

  useEffect(() => {
    if (loading) {
      setStatus(queryStates.loading())
    } else if (error) {
      setStatus(queryStates.error('There was an error while fetching the pad summary.', error))
    } else if (data?.padSummary == null) {
      setStatus(queryStates.loading())
      setShouldPoll(true)
    } else {
      stopPolling()
      clearPollingTimeout()
      setStatus(queryStates.success())
    }
  }, [clearPollingTimeout, data?.padSummary, error, loading, stopPolling])

  return {
    padSummary: data?.padSummary,
    status,
    refetch,
  }
}
